/** @format */

import styled from 'styled-components';

export const ClientFormWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 27px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;

export const ClientFormContentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap: wrap;
    min-height: calc(58vh - 50px);
`;

export const ClientFormBoxWrapper = styled.div`
    height:251px;
    width:251px;
    display:flex;
    margin:20px 1vw;
    align-items:center;
    justify-content:center;
    background: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid #F2E4D8;
    border-radius: 27px;
    border-bottom:none;
    cursor:pointer;
`;

export const ClientFormBox = styled.div`
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  transition: margin-bottom 0.5s;

  &:hover {
    margin-top:20px;
}
`;

export const ClientFormCircle = styled.div`
  
  img {
    height:98px;
    width:102px;
    margin-top:55px;
  }
`;

export const ClientFormButton = styled.div`
  width:251px;
  height:53px;
  background: #E6E9F4;
  border-radius: 0 0 20px 20px;
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight: 500;
  font-size: 20px;
  text-align:center;
  color: ${({ theme }) => theme.colors.od_extra_color};
  cursor: pointer;
`;
