/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import {
  ReviewWrapper,
  ShowReportsButtonWrapper
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import detailIcon from '../../assets/logo/detail.svg';
import homeIcon from '../../assets/logo/home.svg';
import SelectContainer from '../../components/SelectContainer';
import {
  getHandoverList,
  getHouseList,
  getUsersList
} from '../../services/result.services';
import NoData from '../../components/NoData';
import { HandoverHeader, HandoversWrapper, IconContainer } from '../Handover/Handovers/index.style';
import {
  StyledTable, TableContainer, TableWrapper, Td, Th
} from '../../styledComponents/common/table.style';
import { checkForSpecificShift, getUserDetails } from '../../helpers/utils';
import Handover from '../Handover/Handover';

const AdminHandoverSummary = () => {
  const tableBodyRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [listHouses, setListHouses] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [formData, setFormData] = useState({
    houseCode: ''
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showHandoverSummary, setShowHandoverSummary] = useState(false);
  const [selectedHandover, setSelectedHandover] = useState(null);
  const [handoverSummaryList, setHandoverSummaryList] = useState([]);
  const [showHandoverSummaryList, setShowHandoverSummaryList] = useState(false);

  const isDisabled = true;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelectChange = (e) => {
    const { name, value } = e;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClearSelection = () => {
    setFormData((prevState) => ({
      ...prevState,
      houseCode: ''
    }));
    setStartDate('');
    setEndDate('');
  };

  const getHousesHandler = async () => {
    setLoading(true);
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const housesList = filteredHouses && filteredHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`,
        name: 'houseCode'
      }));
      setListHouses(housesList);
      setLoading(false);
    } else {
      setListHouses([]);
      setLoading(false);
    }
  };

  const getHandoverSummaryList = async (payload) => {
    setLoading(true);
    const resp = await getHandoverList(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredNotDeletedSummary = resp.data.filter((ele) => !ele.deleted);
      setHandoverSummaryList(filteredNotDeletedSummary);
    } else {
      setLoading(false);
    }
  };

  const handleShowReports = () => {
    setShowHandoverSummaryList(true);
  };

  const getUsersHandler = async () => {
    const resp = await getUsersList();
    if (!resp) return;
    if (resp.status === 200) {
      const userList = resp.data && resp.data.map((ele) => (
        {
          label: `${ele.firstName} ${ele.lastName}`,
          value: `${ele.firstName} ${ele.lastName}`,
          id: ele.id,
          name: 'handoverTo'
        }
      ));
      setStaffList(userList);
    }
  };

  const handleShowHandoverDetails = () => {
    setShowHandoverSummary((prevState) => !prevState);
  };

  const getHandoverSummaryListWithParams = () => {
    getHandoverSummaryList({
      start: startDate && moment(startDate).format('YYYY-MM-DD'),
      end: endDate && moment(endDate).format('YYYY-MM-DD'),
      pageNumber: 0,
      pageSize: 1000,
      houseCode: formData.houseCode
    });
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    getHousesHandler();
    getUsersHandler();
  }, []);

  useEffect(() => {
    setStartDate('');
    setEndDate('');
  }, [formData.houseCode]);

  useEffect(() => {
    setHandoverSummaryList([]);
    if (startDate && endDate) {
      getHandoverSummaryListWithParams();
    }
  }, [startDate]);

  useEffect(() => {
    setHandoverSummaryList([]);
    if (endDate) {
      getHandoverSummaryListWithParams();
    }
  }, [endDate]);

  useEffect(() => {
    if (tableBodyRef.current) {
      checkScrollbar();
    }
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Handover Summary </span>
          </li>
        </ul>
      </BreadCrumb>
      {
        showHandoverSummary && selectedHandover ? (
          <Handover
            handleShowHandoverDetails={handleShowHandoverDetails}
            handoverDetails={selectedHandover}
            isDisabled={isDisabled}
            staffList={staffList}
            noPlaceHolder
          />
        ) : (
          <HandoversWrapper>
            <HandoverHeader>
              <h2>HANDOVERS</h2>
            </HandoverHeader>
            <div>
              <ReviewWrapper>
                <div className="review-row">
                  <div className="w-33 mr">
                    <p className="label">
                      Select House Code
                    </p>
                    <SelectContainer
                      options={listHouses.length > 0 && listHouses}
                      placeholderText="House Code"
                      className="select-container"
                      isMultiple={false}
                      onChange={handleSelectChange}
                      defaultValue={{
                        value: formData.houseCode,
                        label: formData.houseCode
                      }}
                      disabled={!listHouses.length}
                    />
                  </div>
                  <div className="w-33 date-picker-wrapper">
                    <p className="label">
                      Select Start Date
                    </p>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      disabled={!formData.houseCode}
                    />
                  </div>
                  <div className="w-33 date-picker-wrapper">
                    <p className="label">
                      Select End Date
                    </p>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End date"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      disabled={!startDate}
                      minDate={startDate && startDate}
                    />
                  </div>
                </div>
              </ReviewWrapper>
              <ShowReportsButtonWrapper>
                <button
                  type="button"
                  className="submit-btn"
                  onClick={handleClearSelection}
                >
                  Clear Selection
                </button>
                <button
                  type="button"
                  className="submit-btn"
                  disabled={!endDate}
                  onClick={handleShowReports}
                >
                  Show Handovers
                </button>
              </ShowReportsButtonWrapper>
            </div>
            {
              showHandoverSummaryList && startDate && endDate
                ? (
                  <TableContainer>
                    <TableWrapper>
                      <StyledTable>
                        <thead
                          className={`${
                            isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                          }`}
                        >
                          {
                            handoverSummaryList.length !== 0
                          && (
                          <tr>
                            <Th width="60px">No.</Th>
                            <Th width="140px">Handover By</Th>
                            <Th width="140px">Handover To</Th>
                            <Th width="140px">Handover For</Th>
                            <Th width="100px">Shift</Th>
                            <Th width="140px">Handover Time</Th>
                            <Th width="100px" align="center"> </Th>
                          </tr>
                          )
                          }
                        </thead>
                        <tbody ref={tableBodyRef}>
                          {handoverSummaryList.length > 0
                            && handoverSummaryList.map((handover, index) => (
                              <tr key={handover.id}>
                                <Td width="60px">{index + 1}</Td>
                                <Td width="140px">{`${handover.handoverBy.firstName} ${handover.handoverBy.lastName}`}</Td>
                                <Td width="140px">{`${handover.handoverTo.firstName} ${handover.handoverTo.lastName}`}</Td>
                                <Td width="140px">{moment(handover.handoverDate).format('DD/MM/YYYY')}</Td>
                                <Td width="100px">{checkForSpecificShift(handover.handoverShift)}</Td>
                                <Td width="140px">{moment(handover.handoverTime).format('DD/MM/YYYY HH:mm:ss')}</Td>
                                <Td width="100px" align="center">
                                  <IconContainer
                                    onClick={() => {
                                      setSelectedHandover({
                                        ...handover,
                                        handoverTime: new Date(handover.handoverTime),
                                        handoverDate: new Date(handover.handoverDate)
                                      });
                                      setShowHandoverSummary(true);
                                    }}
                                  >
                                    <img src={detailIcon} alt="detail icon" />
                                  </IconContainer>
                                </Td>
                              </tr>
                            ))}
                          {
                              handoverSummaryList.length === 0 && <NoData />
                          }
                        </tbody>
                      </StyledTable>
                    </TableWrapper>
                  </TableContainer>
                ) : null
            }
          </HandoversWrapper>
        )
      }
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminHandoverSummary;
