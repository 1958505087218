/** @format */
import _axios from 'axios';
import envConfig from '../config';

const axios = _axios.create({
  baseURL: envConfig.servicePath
});
axios.interceptors.request.use((config) => {
  config.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem(
    'token'
  ))}`;
  return config;
});
axios.interceptors.response.use(

  (response) => (response),

  (error) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.status === 400) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }

);

export default axios;
