import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const DropdownToggle = styled(Dropdown.Toggle)`
  background: ${({ theme }) => theme.colors.od_bg_secondary};
  color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 6px;
  
  &:hover, &:focus {
    box-shadow: none;
    background: ${({ theme }) => theme.colors.od_bg_secondary};
    color: ${({ theme }) => theme.colors.od_secondary};
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  clear: both;
  font-size: 18px;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  & > button{
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.od_secondary};
    width: 100%;
    text-align: left;
    padding: 5px 10px;
  }
  & > button.disabled-btn{
    opacity: 0.6;
  }
  & > button:hover{
    background-color: ${({ theme }) => theme.colors.od_secondary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }
  &:hover{
    background-color: ${({ theme }) => theme.colors.od_secondary};
    button {
      color: ${({ theme }) => theme.colors.od_neutral};
    }
  }
`;
