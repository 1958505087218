/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IncidentDetailsWrapper, IncidentManagementHeader } from './index.style';
import { IncidentDetailBoxContainer } from '../../styledComponents/common/incidentDetails.style';

const StaffIncidentDetails = ({ incidentDetails, handleShowIncidentDetails }) => (
  <IncidentDetailsWrapper>
    <IncidentManagementHeader>
      <h2>INCIDENT DETAILS</h2>
      <button type="button" onClick={handleShowIncidentDetails}>Back</button>
    </IncidentManagementHeader>
    <IncidentDetailBoxContainer>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Incident No.</p>
        <p className="detail">{incidentDetails.id}</p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Date/Time of occurrence</p>
        <p className="detail">
          {moment(incidentDetails.dateOccurred).format('DD/MM/YYYY, hh:mm:ss')}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Affected Person</p>
        <p className="detail">
          {incidentDetails.injuredGivenName}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Type</p>
        <p className="detail">{incidentDetails.type.name}</p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Category</p>
        <p className="detail">
          {incidentDetails.category.name}
        </p>
      </div>

      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Classification</p>
        <p className="detail">
          {incidentDetails.classification.name}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Witness Name</p>
        <p className="detail">
          {incidentDetails.witnessName}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Witness Designation</p>
        <p className="detail">
          {incidentDetails.witnessDesignation}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Police Report</p>
        <p className="detail">
          {incidentDetails.policeReport}
        </p>
      </div>
      {incidentDetails.policeReport === 'Yes' ? (
        <>
          <div className="flex w-33">
            <span className="dot"> </span>
            <p className="label">Police Name</p>
            <p className="detail">
              {incidentDetails.policeName}
            </p>
          </div>
          <div className="flex w-33">
            <span className="dot"> </span>
            <p className="label">Police Number</p>
            <p className="detail">
              {incidentDetails.policeNumber}
            </p>
          </div>
          <div className="flex w-33">
            <span className="dot"> </span>
            <p className="label">Police Station</p>
            <p className="detail">
              {incidentDetails.policeStation}
            </p>
          </div>
        </>
      ) : null}
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Before Incidents</p>
        <p className="detail">
          {incidentDetails.beforeIncident}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Description</p>
        <p className="detail">
          {incidentDetails.description}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Immediate Action</p>
        <p className="detail">
          {incidentDetails.immediateAction}
        </p>
      </div>

      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Reporting Date</p>
        <p className="detail">
          {moment(incidentDetails.createdAt).format('DD/MM/YYYY')}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Location</p>
        <p className="detail">{incidentDetails.location}</p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Exact Location</p>
        <p className="detail">
          {incidentDetails.exactLocation}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Reporting Person</p>
        <p className="detail">
          {`${incidentDetails.reportedBy.firstName} ${incidentDetails.reportedBy.lastName}`}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Reportable To NDIS </p>
        <p className="detail">
          {incidentDetails.reportableToNDIS}
        </p>
      </div>
      <div className="flex w-33">
        <span className="dot"> </span>
        <p className="label">Reportable To Worksafe</p>
        <p className="detail">
          {incidentDetails.reportableToWorksafe}
        </p>
      </div>

    </IncidentDetailBoxContainer>
  </IncidentDetailsWrapper>
);

StaffIncidentDetails.propTypes = {
  handleShowIncidentDetails: PropTypes.func.isRequired,
  incidentDetails: PropTypes.objectOf(PropTypes.any).isRequired
};

export default StaffIncidentDetails;
