/** @format */

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getIncident } from '../../services/result.services';
import { IncidentDetailBoxContainer } from '../../styledComponents/common/incidentDetails.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';

const ClosedIncidentDetails = ({ incidentId, incidentType }) => {
  const [incidentDetails, setIncidentDetails] = useState({
    category: { name: '' },
    type: { name: '' },
    classification: { name: '' },
    client: { name: '' },
    description: '',
    dateOccurred: '',
    reportingDate: '',
    location: '',
    exactLocation: '',
    reportedBy: {},
    injuredPerson: ''
  });

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getIncidentDetails = async (id) => {
    setLoading(true);
    const resp = await getIncident(id);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const incidentDup = resp.data;
      const date = new Date(
        incidentDup.dateOccurred
      );
      const modifiedIncident = {
        ...incidentDup,
        dateOccurred: date
      };
      setIncidentDetails(modifiedIncident);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIncidentDetails(incidentId);
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <IncidentDetailBoxContainer>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Incident No.</p>
          <p className="detail">{incidentDetails.id}</p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Category</p>
          <p className="detail">
            {incidentDetails.category.name}
          </p>
        </div>
        {
          incidentType === 'client'
          && (
            <div className="flex w-33">
              <span className="dot"> </span>
              <p className="label">Client</p>
              <p className="detail">
                {incidentDetails?.client?.name}
              </p>
            </div>
          )
        }
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Type</p>
          <p className="detail">{incidentDetails.type.name}</p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Classification</p>
          <p className="detail">
            {incidentDetails.classification.name}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Description</p>
          <p className="detail">
            {incidentDetails.description}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Date/Time of occurrence</p>
          <p className="detail">
            {moment(incidentDetails.dateOccurred).format('DD/MM/YYYY, hh:mm:ss')}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Reporting Date</p>
          <p className="detail">
            {moment(incidentDetails.createdAt).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Location</p>
          <p className="detail">{incidentDetails.location}</p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Exact Location</p>
          <p className="detail">
            {incidentDetails.exactLocation}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Reporting Person</p>
          <p className="detail">
            {`${incidentDetails.reportedBy.firstName} ${incidentDetails.reportedBy.lastName}`}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Affected Person</p>
          <p className="detail">
            {incidentDetails.injuredGivenName}
          </p>
        </div>
        <div className="flex w-33">
          <span className="dot"> </span>
          <p className="label">Escalated To</p>
          <p className="detail">
            {incidentDetails.escalatedTo ? incidentDetails.escalatedTo.email : 'Not Escalated'}
          </p>
        </div>
      </IncidentDetailBoxContainer>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

ClosedIncidentDetails.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incidentType: PropTypes.string.isRequired
};

export default ClosedIncidentDetails;
