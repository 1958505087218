/** @format */
import styled from 'styled-components';

export const MiscellaneousNotesWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height:calc(75vh - 40px);

  img {
      width: 12px !important;
      height: auto;
    }
`;

export const SelectionWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  flex-wrap: wrap;
  margin-top:25px;

  .hr{
    height: 2px;
    width: 99%;
    margin-right: 1rem;
    color: rgba(116, 188, 169, 1);
  }
  .ml-sm{
	margin-left: 0.25rem;
  }
  .review-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .date-picker-wrapper .react-datepicker-wrapper {
      width: 100%;
    }
    .date-picker-wrapper input {
      width: 100%;
      min-height: 38px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #f2e4d8;
    }
  }
  .review-row .input-like {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .mr {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .what-happend {
    margin-right: 0;
    margin-left: 1%;
  }
  .label {
    font-size: 12px;
    margin: 5px 7px;
  }
  .input,
  .input-like {
    padding: 0 8px;
    min-height: 38px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid #f2e4d8;
  }

  .input-like {
    padding: 4px 8px;
    margin-bottom: 0;
  }
  .flex {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    .incident_num {
      color: ${({ theme }) => theme.colors.od_secondary};
      padding: 0;
      margin: 0 7px;
    }
  }
  .w-100 {
    flex: 0 0 100%;
  }
  .w-25 {
    flex: 0 0 24%;
  }
  .w-33 {
	flex: 0 0 32%;
  }
  .w-50 {
    flex: 0 0 49.5%;
  }
  .date-picker-wrapper {
    margin-right:9px;
  }
`;

export const ShowNotesButtonWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top:22px;

  button {
    margin:12px;
    border:none;
    font-weight: bold;
    font-size: 16px;
    cursor:pointer;
  }

  button:first-child {
    background: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }

  .submit-btn {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    padding:12px;
    border-radius:5px;
    border:none;
  }
  .submit-btn:disabled {
    opacity:0.6;
  }
`;

export const SearchInputWrapper = styled.div`
  position:relative;
  margin: 1rem 0.4rem;
  svg {
    position: absolute;
    left: 0.7rem;
    top: 0.8rem;
    color: ${({ theme }) => theme.colors.od_bg_secondary};
  }

  .search-field {
    width:325px;
    border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
    height:45px;
    padding-left:38px;
    border-radius:5px;
    font-size:${({ theme }) => theme.fonts.font_size.normal_font};
    &::placeholder {
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
  }
  input:focus {
    outline: none;
  }
`;

export const NotesListWrapper = styled.div`
  width:80%;
  margin:auto;
  padding:10px;
`;

export const AddNote = styled.button`
  position: absolute;
  top: 210px;
  right: 30px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;
  img {
    width:100% !important;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.input-with-full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 0.5rem;
      margin-bottom: 3px;
    }
    input,
    select,
    textarea {
      width: 100%;
      padding: 0.5rem 0.3rem;
      border-radius: 5px;
      border: none;
    }

    textarea:focus {
      outline:none;
    }
  }

  div.input-with-half-width, div.input-with-one-third-width {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 0.5rem;
      margin-bottom: 3px;
    }
    input,
    select,
    textarea {
      width: 100%;
      padding: 0.4rem;
      border-radius: 5px;
      border: none;
    }

    textarea:focus {
      outline:none;
    }
  }

  div.input-with-one-third-width{
	  width: 32%;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  select:invalid {
    color: gray;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  input[disabled]{
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
`;
