import React, { useEffect, useState } from 'react';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import { getNoticeList } from '../../services/result.services';
import { getUserDetails, getLocalTime } from '../../helpers/utils';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  BreadCrumb, NoticeWrapper, NoticeHeader, NoticeContainer,
  NoticeBox
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import NoData from '../NoData';

const HomeNoticeBoard = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getNoticeHandler = async () => {
    setLoading(true);
    const resp = await getNoticeList({
      houseCode: getUserDetails().houseCode,
      status: 'ACTIVE',
      pageSize: 10000,
      pageNumber: 0
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setNoticeList([...resp.data]);
    } else {
      setLoading(false);
      setNoticeList([]);
    }
  };

  useEffect(() => {
    getNoticeHandler();
  }, [getUserDetails().houseCode]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Houses </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Notices </span>
          </li>
        </ul>
      </BreadCrumb>
      <NoticeWrapper>
        <NoticeHeader>
          <h2>NOTICE</h2>
        </NoticeHeader>
        <NoticeContainer>
          {noticeList
              && noticeList.map((notice) => (
                <NoticeBox key={notice.id}>
                  <p className="notice" data-testid="notice">{notice.notice}</p>
                  <p className="date">{getLocalTime(notice.createdAt).split(' ')[0]}</p>
                </NoticeBox>
              ))}
          {
            noticeList.length === 0 && (

              <NoData />
            )
          }
        </NoticeContainer>
      </NoticeWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="grow" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>

  );
};

export default HomeNoticeBoard;
