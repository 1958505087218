/** @format */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { getUserDetails } from '../helpers/utils';
import InterRoutes from './InterRoutes';

const PrivateRoutes = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const [houseChanged, setHouseChanged] = useState(false);
  const [houseListChanged, setHouseListChanged] = useState(false);
  const userDetails = getUserDetails();
  const isAuthenticated = token;
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <Container fluid>
        <Header houseChanged={houseChanged} houseListChanged={houseListChanged} />
        {userDetails.roles.some((role) => ['HOUSE ADMIN', 'ADMIN', 'USER'].indexOf(role) > -1)
          ? <InterRoutes setHouseListChanged={setHouseListChanged} setHouseChanged={setHouseChanged} /> : <Redirect to="/login" /> }
      </Container>
      <Footer />
    </>
  );
};

export default PrivateRoutes;
