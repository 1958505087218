/** @format */
import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const EmergencyPlanWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  .plans-img {
      width: 95%;
      height: auto;
      cursor:pointer;
    }

  .upload-img {
      width:25px;
      background: #F8F1EB;
      border-radius: 7px;
      cursor:pointer;
      color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
`;

export const EmergencyPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const EmergencyContentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    min-height: calc(72vh - 50px);
`;

export const EmergencyBoxWrapper = styled.div`
    display:flex;
    justify-content:center;
`;

export const PlansBoxWrapper = styled.div`
    display:flex;
    flex-direction:column;
    padding:20px;
    margin:10px;
`;

export const EmergencyBoxHeadingPlan = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.od_extra_color};
    display:flex;
    justify-content:space-between;
    margin: 10px 0;
`;

export const EmergencyBoxHeadingHandout = styled(EmergencyBoxHeadingPlan)`
    color: ${({ theme }) => theme.colors.od_secondary};
`;

export const EmergencyBoxImage = styled.div`
    border: 1px solid #DDDDDD;
    border-radius: 36px;
    width:349px;
    padding:15px;
    cursor:pointer;
`;
