import styled from 'styled-components';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
    }
    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }
    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const IncidentsWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  img {
      width: 12px !important;
      height: auto;
    }
`;

export const IncidentManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;
  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  a{
	color: ${({ theme }) => theme.colors.od_secondary};
	margin-right: 1rem;
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;
    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;
  font-size: 16px;
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.od_primary_transparent};
  height: 70px;

  .green-line{
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  .review-num{
    background-color: ${({ theme }) => theme.colors.od_neutral};
    padding: 5px;
    border-radius: 5px;
    margin: 0 5px;
  }
`;

StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  padding: 0 1rem;
  cursor: pointer;
;
  &.is-selected {
    color: ${({ theme }) => theme.colors.od_primary_shade1};
  }
`;

StyledTab.tabsRole = 'Tab';

export const StyledTabPanel = styled(TabPanel)`
  display: none;
  min-height: 50vh;
  &.is-selected {
    display: block;
  }
`;
StyledTabPanel.tabsRole = 'TabPanel';
