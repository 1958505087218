/** @format */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { NotesTable, TableBody, TableHead } from './index.style';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows
  } = useTable({
    columns,
    data
  });
  const tableBodyRef = useRef('');

  useEffect(() => {
    const lastRow = document.querySelector('tbody > tr:last-child > td > div');
    if (lastRow && !lastRow.textContent) {
      lastRow.scrollIntoView();
    }
  });
  return (
    <>
      <NotesTable
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr key="header" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={`th_${columnIndex}`} {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </TableHead>

        <TableBody
          {...getTableBodyProps()}
          ref={tableBodyRef}
        >
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr key={`tr_${rowIndex}`} {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </TableBody>
      </NotesTable>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired
};

export default Table;
