import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { Spinner } from 'react-bootstrap';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import plusIcon from '../../assets/logo/plus.svg';
import contactIcon from '../../assets/logo/contact.svg';
import addressIcon from '../../assets/logo/location.svg';
import noticeIcon from '../../assets/logo/notice.svg';
import editIcon from '../../assets/logo/edit.svg';
import emailIcon from '../../assets/logo/email_logo.svg';
import userIcon from '../../assets/logo/user.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import { capitalize, getUserDetails } from '../../helpers/utils';
import {
  ContactManagementHeader, ContactsWrapper,
  ContactList, ContactCard, FormWrapper
} from './index.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import CustomModal from '../../components/CustomModal';
import { TableContainer } from '../../styledComponents/common/table.style';
import { clientContact as clientContactObj } from '../../lookups/lookups';
import { createClientContact, getClientContacts, updateClientContact } from '../../services/result.services';
import NoData from '../../components/NoData';

const AdminClientContacts = () => {
  const location = useLocation();
  const clientName = location.pathname.split('/')[2];
  const clientId = location.pathname.split('/')[3];
  const [contactList, setContactList] = useState([]);
  const [contactInfo, setContactInfo] = useState(clientContactObj);
  const [showCustomModalAddContact, setShowCustomModalAddContact] = useState(false);
  const [showCustomModalUpdateContact, setShowCustomModalUpdateContact] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [contactInfoUnchanged, setContactInfoUnchanged] = useState(clientContactObj);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    if (validationMsg) {
      setValidationMsg('');
    }
    const { name, value } = event.target;
    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const clearInput = () => {
    setContactInfo(clientContactObj);
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-contact') {
      setShowCustomModalAddContact(false);
    } else if (operation === 'update-contact') {
      setShowCustomModalUpdateContact(false);
    }
  };

  const checkValidation = () => {
    if (contactInfo.firstName === '') {
      setValidationMsg('Please enter first name');
      return false;
    }
    if (contactInfo.email === '') {
      setValidationMsg('Please enter email');
      return false;
    }
    if (contactInfo.email !== '') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(contactInfo.email).toLowerCase())) {
        setValidationMsg('Please enter a valid email');
        return false;
      }
    }
    if (contactInfo.relation === '') {
      setValidationMsg('Please enter relation');
      return false;
    }
    if (contactInfo.address1 === '') {
      setValidationMsg('Please add address 1');
      return false;
    }
    if (contactInfo.city === '') {
      setValidationMsg('Please enter city');
      return false;
    }
    if (contactInfo.state === '') {
      setValidationMsg('Please enter state');
      return false;
    }
    if (contactInfo.postCode === '') {
      setValidationMsg('Please enter PostCode');
      return false;
    }
    if (contactInfo.postCode !== '') {
      const re = /^[0-9]{4}$/;
      if (!re.test(String(contactInfo.postCode))) {
        setValidationMsg('Enter a valid PostCode');
        return false;
      }
    }
    if (contactInfo.phone1 === '') {
      setValidationMsg('Please enter phone 1');
      return false;
    }
    if (contactInfo.phone1 !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(contactInfo.phone1))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    return true;
  };

  const compareContact = () => (
    JSON.stringify(contactInfo) === JSON.stringify(contactInfoUnchanged)
  );

  const resetContactInfo = () => {
    setContactInfo(clientContactObj);
  };

  const handleAddContact = () => {
    clearInput();
    setValidationMsg('');
    setShowCustomModalAddContact(true);
  };

  const handleEditContact = (id) => {
    const contactToBeUpdated = contactList.find((contact) => contact.id === id);
    const modifiedContact = {
      ...contactToBeUpdated
    };
    setSelectedContact(id);
    setContactInfo(modifiedContact);
    setContactInfoUnchanged(modifiedContact);
    setValidationMsg('');
    setShowCustomModalUpdateContact(true);
  };

  const getClientContactsHandler = async (status) => {
    setLoading(true);
    const resp = await getClientContacts(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setContactList([...resp.data.filter((contact) => contact.status === status)
        .sort((a, b) => a.id - b.id)]);
    } else {
      setLoading(false);
    }
  };

  const addContact = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await createClientContact(
      { clientId, ...contactInfo, lastUpdatedBy: getUserDetails().firstName }
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Contact created successfully!',
        icon: 'success',
        timer: 3000
      });
      getClientContactsHandler('ACTIVE');
      setValidationMsg('');
      handleCloseCustomModal('add-contact');
      resetContactInfo();
    } else {
      setLoading(false);
      swal({
        title: 'Contact creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      handleCloseCustomModal('add-contact');
      resetContactInfo();
    }
  };

  const handleDeleteContact = async (contact) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateClientContact(contact.id,
        {
          ...contact,
          status: 'INACTIVE',
          lastUpdatedBy: getUserDetails().firstName
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Contact deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getClientContactsHandler('ACTIVE');
      } else {
        setLoading(false);
        swal({
          title: 'Contact deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const editContact = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await updateClientContact(selectedContact,
      {
        ...contactInfo,
        lastUpdatedBy: getUserDetails().firstName
      });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Contact updated successfully!',
        icon: 'success',
        timer: 3000
      });
      getClientContactsHandler('ACTIVE');
      setValidationMsg('');
      handleCloseCustomModal('update-contact');
      resetContactInfo();
    } else {
      setLoading(false);
      swal({
        title: 'Contact updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      handleCloseCustomModal('update-contact');
      resetContactInfo();
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getClientContactsHandler('ACTIVE');
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Client Management </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              {clientName}
            </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Contacts </span>
          </li>
        </ul>
      </BreadCrumb>
      <ContactsWrapper>
        <ContactManagementHeader>
          <h2>CLIENT CONTACT MANAGEMENT</h2>
          <div>
            <button type="button" onClick={() => handleAddContact()}>
              <img src={plusIcon} alt="plus icon" />
              Add Contact
            </button>
            <Link to="/admin/clients">Back</Link>
          </div>
        </ContactManagementHeader>
        <TableContainer>
          {
            contactList.length === 0 && (
              <NoData />
            )
          }
          <ContactList>
            {contactList
              && contactList.map((contact) => (
                <ContactCard key={contact.id}>
                  <div className="contact-header">
                    <p>
                      {capitalize(contact.relation)}
                    </p>
                    <div>
                      <button
                        type="button"
                        onClick={() => handleEditContact(contact.id)}
                      >
                        <img src={editIcon} alt="edit icon" />
                      </button>
                      <button
                        type="button"
                        className="delete-btn"
                        onClick={() => handleDeleteContact(contact)}
                      >
                        <img src={deleteIcon} alt="delete icon" />
                      </button>
                    </div>
                  </div>
                  <div className="contact-body">
                    <div className="user-home-row">
                      <div>
                        <img src={userIcon} alt="contact person" />
                        <div>
                          {`${contact.firstName} ${contact.lastName}`}
                        </div>
                      </div>
                    </div>
                    <div className="designation-row">{contact.designation && capitalize(contact.designation)}</div>
                    <div>
                      <img src={emailIcon} alt="email icon" />
                      <div data-testid="email">
                        {contact.email}
                      </div>
                    </div>
                    <div>
                      <img src={addressIcon} className="address-icon" alt="address icon" />
                      <div>
                        {`${contact.address1}`}
                        { contact.address2 && `, ${contact.address2}`}
                        {`, ${contact.city}`}
                        {`, ${contact.state}`}
                        {`, ${contact.postCode}`}
                      </div>
                    </div>
                    <div>
                      <img src={contactIcon} alt="contact icon" />
                      <div>
                        {`${contact.phone1} ${contact.phone2 && `, ${contact.phone2}`}`}
                      </div>
                    </div>
                    {
                        contact.notes ? (
                          <div>
                            <img className="note-icon" src={noticeIcon} alt="note icon" />
                            <div className="note">
                              {contact.notes}
                            </div>
                          </div>
                        ) : (
                          null
                        )
                      }
                  </div>
                </ContactCard>
              ))}
          </ContactList>
        </TableContainer>
      </ContactsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <LoadingModal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </LoadingModal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddContact}
        setClose={() => {
          handleCloseCustomModal('add-contact');
        }}
        onSubmit={addContact}
        title="Add Client Contact"
        width="850px"
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="fname">First Name</label>
                <input
                  id="fname"
                  required
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={contactInfo.firstName}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="lname">Last Name</label>
                <input
                  id="lname"
                  required
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={contactInfo.lastName}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                required
                value={contactInfo.email}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="relation">Relation</label>
                <input
                  id="relation"
                  required
                  type="text"
                  placeholder="Relation"
                  name="relation"
                  onChange={handleChange}
                  value={contactInfo.relation}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="designation">Designation</label>
                <input
                  id="designation"
                  required
                  type="text"
                  placeholder="Designation"
                  name="designation"
                  onChange={handleChange}
                  value={contactInfo.designation}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="note">Note</label>
              <input
                type="text"
                id="note"
                required
                value={contactInfo.notes}
                name="notes"
                placeholder="Note"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                id="address1"
                value={contactInfo.address1}
                name="address1"
                placeholder="Address 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="houses">Address 2</label>
              <input
                id="address2"
                type="text"
                value={contactInfo.address2}
                name="address2"
                placeholder="Address 2"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={contactInfo.city}
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={contactInfo.state}
                  name="state"
                  placeholder="State"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="postCode">Postcode</label>
              <input
                type="text"
                id="postCode"
                value={contactInfo.postCode}
                name="postCode"
                placeholder="Postcode"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone1">Phone 1</label>
              <input
                type="text"
                id="phone1"
                value={contactInfo.phone1}
                name="phone1"
                placeholder="Phone 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone2">Phone 2</label>
              <input
                type="text"
                id="phone2"
                value={contactInfo.phone2}
                name="phone2"
                placeholder="Phone 2"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateContact}
        setClose={() => {
          handleCloseCustomModal('update-contact');
        }}
        isDisabled={compareContact()}
        onSubmit={editContact}
        title="Edit Client Contact"
        width="850px"
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="fname">First Name</label>
                <input
                  id="fname"
                  required
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={contactInfo.firstName}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="lname">Last Name</label>
                <input
                  id="lname"
                  required
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={contactInfo.lastName}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                required
                value={contactInfo.email}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="relation">Relation</label>
                <input
                  id="relation"
                  required
                  type="text"
                  placeholder="Relation"
                  name="relation"
                  onChange={handleChange}
                  value={contactInfo.relation}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="designation">Designation</label>
                <input
                  id="designation"
                  required
                  type="text"
                  placeholder="Designation"
                  name="designation"
                  onChange={handleChange}
                  value={contactInfo.designation}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="note">Note</label>
              <input
                type="text"
                id="note"
                required
                value={contactInfo.note}
                name="notes"
                placeholder="Note"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                id="address1"
                value={contactInfo.address1}
                name="address1"
                placeholder="Address 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="houses">Address 2</label>
              <input
                id="address2"
                type="text"
                value={contactInfo.address2}
                name="address2"
                placeholder="Address 2"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={contactInfo.city}
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={contactInfo.state}
                  name="state"
                  placeholder="State"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="postCode">Postcode</label>
              <input
                type="text"
                id="postCode"
                value={contactInfo.postCode}
                name="postCode"
                placeholder="Postcode"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone1">Phone 1</label>
              <input
                type="text"
                id="phone1"
                value={contactInfo.phone1}
                name="phone1"
                placeholder="Phone 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone2">Phone 2</label>
              <input
                type="text"
                id="phone2"
                value={contactInfo.phone2}
                name="phone2"
                placeholder="Phone 2"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
    </>
  );
};

export default AdminClientContacts;
