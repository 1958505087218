/** @format */
import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const AdminEmergencyPlanWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;

export const AdminEmergencyPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const AdminEmergencyContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(72vh - 50px);
`;

export const AdminEmergencyBoxWrapper = styled.div`
  height: 321px;
  width: 321px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.od_light_extra};
  border-radius: 36px;
`;

export const AdminEmergencyBoxHandoutWrapper = styled(AdminEmergencyBoxWrapper)`
  background: ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const AdminEmergencyBoxPlan = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AdminEmergencyPlanCircle = styled.div`
  background: ${({ theme }) => theme.colors.od_neutral};
  height: 135px;
  width: 135px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
`;

export const AdminEmergencyPlanButton = styled.div`
  width: 275px;
  height: 53px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 20px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.od_extra_color};
  cursor: pointer;
`;

export const AdminEmergencyHandoutButton = styled(AdminEmergencyPlanButton)`
  color: ${({ theme }) => theme.colors.od_secondary};
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }

  select:invalid {
    color: gray;
  }

  select:focus {
    outline: none;
  }
`;
