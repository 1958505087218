/** @format */

import React, { useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import {
  Wrapper,
  TabComponentWrapper,
  StyledTabs,
  StyledTabList,
  StyledTab,
  StyledTabPanel
} from '../Client/index.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import homeIcon from '../../assets/logo/home.svg';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import AdminMiscellaneousNotes from '../AdminMiscellaneousNotes/AdminMiscellaneousNotes';
import AdminCaseNotes from '../AdminCaseNotes/AdminCaseNotes';

const AdminNotes = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Notes </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              { selectedIndex === 0 && 'Miscellaneous Notes'}
              { selectedIndex === 1 && 'Case Notes'}
            </span>
          </li>
        </ul>
      </BreadCrumb>
      <Wrapper>
        <StyledTabs
          fs="20px"
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <StyledTabList>
            <StyledTab data-testid="miscellaneousNote">Miscellaneous Notes</StyledTab>
            <StyledTab>Case Notes</StyledTab>
          </StyledTabList>
          <StyledTabPanel>
            <TabComponentWrapper>
              <AdminMiscellaneousNotes />
            </TabComponentWrapper>
          </StyledTabPanel>
          <StyledTabPanel>
            <TabComponentWrapper>
              <AdminCaseNotes />
            </TabComponentWrapper>
          </StyledTabPanel>
        </StyledTabs>
      </Wrapper>
    </>

  );
};

export default AdminNotes;
