/** @format */

import React, { useEffect, useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import swal from 'sweetalert';
import { Modal, Spinner } from 'react-bootstrap';
import {
  AdminEmergencyPlanWrapper,
  AdminEmergencyPlanHeader,
  AdminEmergencyContentWrapper,
  AdminEmergencyBoxWrapper,
  AdminEmergencyBoxPlan,
  AdminEmergencyPlanCircle,
  AdminEmergencyPlanButton,
  AdminEmergencyBoxHandoutWrapper,
  AdminEmergencyHandoutButton,
  FormWrapper
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import planIcon from '../../assets/logo/planIcon.svg';
import handoutIcon from '../../assets/logo/handoutIcon.svg';
import CustomModal from '../../components/CustomModal';
import { uploadPlan, getHouseList } from '../../services/result.services';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import { getUserDetails } from '../../helpers/utils';

const AdminEmergencyPlan = () => {
  const [showCustomModalUploadPlan, setShowCustomModalUploadPlan] = useState(false);
  const [labelUploadFileName, setLabelUploadFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [showCustomModalUploadHandout, setShowCustomModalUploadHandout] = useState(false);
  const [houseList, setHouseList] = useState([]);
  const [selectedHouseCode, setSelectedHouseCode] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearInput = () => {
    setUploadFile(null);
    setSelectedHouseCode(null);
    setLabelUploadFileName('');
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'upload-plan') {
      setShowCustomModalUploadPlan(false);
    } else if (operation === 'upload-handout') {
      setShowCustomModalUploadHandout(false);
    }
  };

  const handleUploadPlanModal = () => {
    setShowCustomModalUploadPlan(true);
  };

  const handleUploadHandoutModal = () => {
    setShowCustomModalUploadHandout(true);
  };

  const handleUpdateHouseChange = (e) => {
    const { value } = e.target;
    setSelectedHouseCode(value);
  };

  const handleFileChange = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };

  const handleUpload = async (fileType) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('houseCode', selectedHouseCode);
    formData.append('fileType', fileType);
    const resp = await uploadPlan(formData);
    if (fileType === 'PLAN') {
      handleCloseCustomModal('upload-plan');
    } else {
      handleCloseCustomModal('upload-handout');
    }
    if (!resp) {
      clearInput();
      setLoading(false);
    } else if (resp.status === 200) {
      clearInput();
      setLoading(false);
      swal({
        title: `${fileType === 'PLAN' ? 'Plan uploaded successfully!' : 'Handout uploaded successfully!'}`,
        icon: 'success',
        timer: 3000
      });
    } else {
      clearInput();
      swal({
        title: `${fileType === 'PLAN' ? 'Plan failed to upload!' : 'Handout failed to upload!'}`,
        icon: 'error',
        timer: 3000
      });
    }
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses && filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      setHouseList(filteredHouses);
    } else {
      setHouseList([]);
    }
  };

  useEffect(() => {
    getHousesHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Emergency Plan </span>
          </li>
        </ul>
      </BreadCrumb>
      <AdminEmergencyPlanWrapper>
        <AdminEmergencyPlanHeader>
          <h2>EMERGENCY PLAN</h2>
        </AdminEmergencyPlanHeader>
        <AdminEmergencyContentWrapper>
          <AdminEmergencyBoxWrapper>
            <AdminEmergencyBoxPlan onClick={() => handleUploadPlanModal()}>
              <AdminEmergencyPlanCircle>
                <img src={planIcon} alt="plan icon" />
              </AdminEmergencyPlanCircle>
              <AdminEmergencyPlanButton>Upload Plan</AdminEmergencyPlanButton>
            </AdminEmergencyBoxPlan>
          </AdminEmergencyBoxWrapper>
          <AdminEmergencyBoxHandoutWrapper>
            <AdminEmergencyBoxPlan onClick={() => handleUploadHandoutModal()}>
              <AdminEmergencyPlanCircle>
                <img src={handoutIcon} alt="plan icon" />
              </AdminEmergencyPlanCircle>
              <AdminEmergencyHandoutButton>
                Upload Handout
              </AdminEmergencyHandoutButton>
            </AdminEmergencyBoxPlan>
          </AdminEmergencyBoxHandoutWrapper>
        </AdminEmergencyContentWrapper>
      </AdminEmergencyPlanWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalUploadPlan}
        setClose={() => {
          handleCloseCustomModal('upload-plan');
          clearInput();
        }}
        title="Upload Emergency Plan"
        width="680px"
        onSubmit={() => handleUpload('PLAN')}
        operationName="Upload"
        isDisabled={!selectedHouseCode || !uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <p className="label">Select File</p>
              <div className="icon-name-wrapper">
                <label htmlFor="uploadEP">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadEP"
                    name="uploadEP"
                    accept="application/pdf"
                    hidden
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
            <div className="input-label-wrapper">
              <label htmlFor="house" className="label">House Code</label>
              <select
                onChange={(event) => handleUpdateHouseChange(event)}
                id="house"
              >
                <option disabled selected value="">
                  Select House
                </option>
                {houseList
                  && houseList.map((house) => (
                    <option key={house.id} value={house.houseCode}>
                      {house.houseCode}
                    </option>
                  ))}
              </select>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUploadHandout}
        setClose={() => {
          handleCloseCustomModal('upload-handout');
          clearInput();
        }}
        title="Upload Emergency Handout"
        width="680px"
        onSubmit={() => handleUpload('HANDOUT')}
        operationName="Upload"
        isDisabled={!selectedHouseCode || !uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <p className="label">Select File</p>
              <div className="icon-name-wrapper">
                <label htmlFor="uploadEH">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadEH"
                    name="uploadEH"
                    hidden
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
            <div className="input-label-wrapper">
              <label htmlFor="house">House Code</label>
              <select
                onChange={(event) => handleUpdateHouseChange(event)}
                id="house"
              >
                <option disabled selected value="">
                  Select House
                </option>
                {houseList
                  && houseList.map((house) => (
                    <option key={house.id} value={house.houseCode}>
                      {house.houseCode}
                    </option>
                  ))}
              </select>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
    </>
  );
};

export default AdminEmergencyPlan;
