/** @format */

import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getHouseImageUrl } from '../services/result.services';
import houseImg from '../assets/images/house_pic.png';

const ImageContainer = ({ houseCode }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const getHouseImg = async () => {
    const resp = await getHouseImageUrl(houseCode);
    if (!resp) {
      return;
    }
    if (resp.status === 200) {
      setImgUrl(resp.data.imageUrl);
    }
  };

  useEffect(() => {
    getHouseImg();
  }, [houseCode]);

  return (
    <div className="img-container">
      <img src={imgUrl || houseImg} alt="house" />
    </div>
  );
};

ImageContainer.propTypes = {
  houseCode: PropTypes.string.isRequired
};

export default memo(ImageContainer);
