import type1Icon from '../assets/logo/type1.svg';
import type2Icon from '../assets/logo/type2.svg';
import type3Icon from '../assets/logo/type3.svg';
import type4Icon from '../assets/logo/type4.svg';
import type5Icon from '../assets/logo/type5.svg';
import type6Icon from '../assets/logo/type6.svg';
import type7Icon from '../assets/logo/type7.svg';

export const initialBowelMovementRecord = {
  startDate: '',
  recordTime: '',
  size: '',
  type1: false,
  type2: false,
  type3: false,
  type4: false,
  type5: false,
  type6: false,
  type7: false
};

export const bowelSize = [
  { name: 'bowelSize', label: 'Small', value: 'Small' },
  { name: 'bowelSize', label: 'Medium', value: 'Medium' },
  { name: 'bowelSize', label: 'Large', value: 'Large' }
];

export const bowelHeaders = [
  { name: 'Type 1', src: type2Icon, value: 'type1' },
  { name: 'Type 2', src: type3Icon, value: 'type2' },
  { name: 'Type 3', src: type4Icon, value: 'type3' },
  { name: 'Type 4', src: type1Icon, value: 'type4' },
  { name: 'Type 5', src: type5Icon, value: 'type5' },
  { name: 'Type 6', src: type6Icon, value: 'type6' },
  { name: 'Type 7', src: type7Icon, value: 'type7' }
];
