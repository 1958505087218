/** @format */

const development = {
  servicePath: 'https://cass.edstem.com',
  currentEnv: 'development'
};

const stage = {
  servicePath: 'https://cass.edstem.com',
  currentEnv: 'stage'
};

const production = {
  servicePath: 'https://onedoorway-spring-cloud-np-ody-service-dev.azuremicroservices.io',
  currentEnv: 'production'
};

const test = {
  servicePath: 'http://localhost:3000',
  currentEnv: 'test'
};

const local = {
  servicePath: 'http://localhost:8090',
  currentEnv: 'local'
};

let config = development;
switch (process.env.REACT_APP_ENV) {
  case 'stage': {
    config = stage;
    break;
  }
  case 'production': {
    config = production;
    break;
  }
  case 'test': {
    config = test;
    break;
  }
  case 'local': {
    config = local;
    break;
  }
  default: {
    config = development;
    break;
  }
}

export default {
  ...config
};
