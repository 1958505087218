/** @format */

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import logoPic from '../../assets/logo/logo.png';
import homeIcon from '../../assets/logo/home-white.svg';
import adminIcon from '../../assets/logo/admin.svg';
import emergencyIcon from '../../assets/logo/emergency.svg';
import noticeIcon from '../../assets/logo/notice.svg';
import clientIcon from '../../assets/logo/client.svg';
import impContactIcon from '../../assets/logo/imp-contact.svg';
import {
  getUserDetails,
  compare,
  checkIfAdminOnly,
  checkIfHouseAdminOnly
} from '../../helpers/utils';
import {
  DropdownToggle, HeaderRow, DropdownMenu, DropdownHouseMenu, DropdownHeader
} from './index.style';
import { getClientListByHouse, getHouseList } from '../../services/result.services';

const Header = ({ houseChanged, houseListChanged }) => {
  const history = useHistory();
  const [clientList, setClientList] = useState([]);
  const [houseList, setHouseList] = useState([...getUserDetails().houseList]);

  const handleLogout = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('user-details', null);
    history.push('/login');
  };
  const getUserHouseCode = () => {
    const userDetails = getUserDetails();
    return userDetails.houseCode;
  };
  const getUserFullName = () => {
    const userDetails = getUserDetails();
    const { firstName } = userDetails;
    const { lastName } = userDetails;
    return `${firstName} ${lastName}`;
  };
  const getUserTitle = () => {
    const userDetails = getUserDetails();
    const firstNameFirstLetter = userDetails.firstName && userDetails.firstName.substring(0, 1);
    const lastNameFirstLetter = userDetails.lastName && userDetails.lastName.substring(0, 1);
    return `${firstNameFirstLetter} ${lastNameFirstLetter}`;
  };
  const getUserRoles = () => {
    const userDetails = getUserDetails();
    return userDetails.roles;
  };

  const getClientsByHouse = async (isHouseChanged) => {
    const houseCode = getUserHouseCode();
    if (!houseCode) return;
    const resp = await getClientListByHouse(houseCode);
    if (!resp) return;
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      setClientList(filteredResp);
      if (isHouseChanged) {
        history.push('/home');
      }
    }
  };

  const changeHouse = (houseCode) => {
    const userDetails = getUserDetails();
    if (houseCode === 'home') {
      localStorage.setItem('user-details', JSON.stringify({
        ...userDetails,
        houseCode: ''
      }));
      setClientList([]);
      history.push('/home');
      return;
    }
    if (houseCode === userDetails.houseCode) return;
    localStorage.setItem('user-details', JSON.stringify({
      ...userDetails,
      houseCode
    }));
    getClientsByHouse(true);
  };

  const goToHome = () => {
    history.push('/home');
  };

  const goToEmergencyPage = () => {
    history.push('/emergencyplan');
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const houseCodeList = filteredActiveHouses.sort(compare).map((house) => house.houseCode);
      localStorage.setItem('user-details', JSON.stringify({
        ...getUserDetails(),
        houseList: houseCodeList
      }));
      setHouseList(houseCodeList);
    }
  };

  useEffect(() => {
    getClientsByHouse(false);
  }, [houseChanged]);

  useEffect(() => {
    if (checkIfAdminOnly()) {
      getHousesHandler();
    }
  }, [houseListChanged]);

  return (
    <HeaderRow>
      <div>
        <span>
          <button type="button" className="button" onClick={() => changeHouse('home')}>
            <img className="logo" alt="logo" src={logoPic} />
          </button>
        </span>
      </div>
      <div className="links-section">
        <div className={(checkIfAdminOnly() || checkIfHouseAdminOnly()) ? 'admin-left' : 'left'}>
          <Dropdown>
            <DropdownToggle
              id="dropdown-basic"
              variant="borderless-dark"
            >
              <span className="house-icon"><img alt="home icon" src={homeIcon} /></span>
              { getUserHouseCode()
                ? <span className="select-house-title"> </span>
                : <span className="select-house-title">Select House</span>}
              <span className="house-num">
                { getUserHouseCode() }
              </span>
            </DropdownToggle>
            <DropdownHouseMenu>
              {
                houseList.map((houseCode) => (
                  <Dropdown.Item
                    as="div"
                    key={houseCode}
                  >
                    <button type="button" className="nav-link" onClick={() => changeHouse(houseCode)}>
                      <span className="house-num">{houseCode}</span>
                    </button>
                  </Dropdown.Item>
                ))
                }
            </DropdownHouseMenu>
          </Dropdown>
        </div>
        <div className="right">
          <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
            <img src={noticeIcon} alt="notice icon" />
            <button type="button" disabled={!getUserHouseCode()} className="button" onClick={goToHome}>Notice Board</button>
          </div>
          <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
            <img src={emergencyIcon} alt="emergency icon" />
            <button type="button" disabled={!getUserHouseCode()} className="button" onClick={goToEmergencyPage}>Emergency Plan</button>
          </div>
          <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
            <img src={impContactIcon} alt="important contacts icon" />
            <button type="button" disabled={!getUserHouseCode()} className="button" onClick={() => history.push('/importantcontact')}>Important Contacts</button>
          </div>
          <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
            <img src={noticeIcon} alt="notice icon" />
            <Dropdown>
              <DropdownToggle
                id="dropdown-basic"
                variant="borderless-dark"
                disabled={!getUserHouseCode()}
              >
                Forms
              </DropdownToggle>
              <DropdownMenu>
                <Dropdown.Item
                  as="div"
                >
                  <Link
                    className="nav-link"
                    to={{
                      pathname: '/handover'
                    }}
                  >
                    Handover
                  </Link>
                </Dropdown.Item>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
            <img src={clientIcon} alt="client icon" />
            <Dropdown>
              <DropdownToggle
                id="dropdown-basic"
                variant="borderless-dark"
                disabled={!getUserHouseCode()}
              >
                Clients
              </DropdownToggle>
              <DropdownMenu>
                {clientList.map((client) => (
                  <Dropdown.Item
                    key={client.id}
                    as="div"
                  >
                    <Link
                      className="nav-link"
                      to={{
                        pathname: `/client/${client.id}/${client.name}`
                      }}
                    >
                      {client.name}
                    </Link>
                  </Dropdown.Item>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="icon-wrapper">
            {getUserRoles().some(
              (role) => ['HOUSE ADMIN', 'ADMIN'].indexOf(role) > -1
            ) ? (
              <>
                <img src={adminIcon} alt="admin icon" />
                <Dropdown>
                  <DropdownToggle
                    id="dropdown-basic"
                    variant="borderless-dark"
                  >
                    Admin
                  </DropdownToggle>
                  <DropdownMenu>
                    <Dropdown.Item
                      as="div"
                      eventKey="houses"
                    >
                      <Link className="nav-link" to="/admin/houses">
                        Houses
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="clients"
                    >
                      <Link className="nav-link" to="/admin/clients">
                        Clients
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="users"
                    >
                      <Link className="nav-link" to="/admin/users">
                        Users
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="incidents"
                    >
                      <Link className="nav-link" to="/admin/incidents">
                        Incidents
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="notices"
                    >
                      <Link className="nav-link" to="/admin/notices">
                        Notices
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="emergency-plan"
                    >
                      <Link className="nav-link" to="/admin/clientreports">
                        Client Reports
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="emergency-plan"
                    >
                      <Link className="nav-link" to="/admin/emergencyplan">
                        Emergency Plan
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="important-contact"
                    >
                      <Link className="nav-link" to="/admin/importantcontact">
                        Important Contacts
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="important-contact"
                    >
                      <Link className="nav-link" to="/admin/handovers">
                        Handover Summary
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      eventKey="notes"
                    >
                      <Link className="nav-link" to="/admin/notes">
                        Notes
                      </Link>
                    </Dropdown.Item>
                  </DropdownMenu>
                </Dropdown>
              </>
              ) : null}
          </div>
        </div>
      </div>
      <div className="section-3">
        <Dropdown>
          <DropdownToggle
            borderradius="50%"
            width="55px"
            bgcolor="od_secondary"
            color="od_neutral"
            height="55px"
            id="dropdown-basic"
            variant="borderless-dark"
            className="dropdown-toggle-logout"
          >
            {getUserTitle().toUpperCase()}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownHeader
              header
            >
              {getUserFullName()}
            </DropdownHeader>
            <Dropdown.Item
              as="div"
            >
              <Link
                className="nav-link"
                to="/changePassword"
              >
                Change Password
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as="div"
            >
              <button
                type="button"
                className="nav-link"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Dropdown.Item>
          </DropdownMenu>
        </Dropdown>
      </div>
    </HeaderRow>
  );
};

Header.propTypes = {
  houseChanged: PropTypes.bool.isRequired,
  houseListChanged: PropTypes.bool.isRequired
};

export default Header;
