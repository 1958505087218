/** @format */

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import SelectContainer from '../../SelectContainer';
import {
  getUsersList,
  addReview,
  closeIncident,
  editReview
} from '../../../services/result.services';
import { checkIfHouseAdminOnly, getUserDetails } from '../../../helpers/utils';
import {
  riskOptions,
  consequenceOptions,
  likelihoodOptions
} from '../../../lookups/lookups';
import { ButtonWrapper, ReviewWrapper } from './index.style';

const IncidentReviewThird = ({
  incidentId,
  editReviewData,
  handleChange,
  handleSelectChange,
  reviewData,
  handleSelectDate,
  checkReviewValidation,
  reviewValidationMsg,
  handleChangeEditReview,
  handleSelectDateEdit,
  handleSelectChangeEdit,
  incident,
  editReviewGeneral
}) => {
  const history = useHistory();
  const [allocationList, setAllocationList] = useState([]);
  const isHouseAdmin = checkIfHouseAdminOnly();
  const getUsersHandler = async () => {
    const resp = await getUsersList();
    const filteredResp = resp.data.filter((user) => !user.deleted);
    if (!resp) return;
    if (resp.status === 200) {
      // eslint-disable-next-line
      const userList = filteredResp && filteredResp.map((ele) => {
        return {
          label: `${ele.firstName} ${ele.lastName}`,
          value: `${ele.firstName} ${ele.lastName}`,
          name: 'allocatedTo'
        };
      });
      setAllocationList(userList);
    }
  };

  const handleSubmitReview = async () => {
    if (!checkReviewValidation(reviewData)) return;
    const resp = await addReview({
      ...reviewData,
      incidentId,
      dueDate: moment(reviewData.dueDate).format('YYYY-MM-DD'),
      reviewedBy: getUserDetails().firstName
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Review Created successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Review Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };
  const handleEditReview = async () => {
    if (!checkReviewValidation(editReviewData)) return;
    const resp = await editReview(editReviewData.id, {
      ...editReviewData,
      incidentId: editReviewData.incidentId,
      dueDate: moment(editReviewData.dueDate).format('YYYY-MM-DD'),
      reviewedBy: getUserDetails().firstName
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Review Updated successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Review Updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };
  const handleCloseIncident = async () => {
    const resp = await closeIncident(incidentId, getUserDetails().firstName);
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Incident closed successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Incident close failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    getUsersHandler();
  }, []);

  return (
    <>
      <ReviewWrapper>
        <div className="review-row">
          <div className="w-25 mr">
            <p className="label">
              Risk Rating
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={riskOptions}
                  isMultiple={false}
                  placeholderText="Risk Rating"
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.riskRating,
                    label: reviewData.riskRating
                  }}
                />
              ) : (
                <SelectContainer
                  options={riskOptions}
                  placeholderText="Risk Rating"
                  isMultiple={false}
                  defaultValue={{
                    value: editReviewData.riskRating,
                    label: editReviewData.riskRating
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Consequences
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={consequenceOptions}
                  placeholderText="Consequences"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.consequences,
                    label: reviewData.consequences === '' ? reviewData.consequences : reviewData.consequences[0].toUpperCase()
                    + reviewData.consequences.substring(1)
                  }}
                />
              ) : (
                <SelectContainer
                  options={consequenceOptions}
                  isMultiple={false}
                  placeholderText="Consequences"
                  defaultValue={{
                    value: editReviewData.consequences,
                    label: editReviewData.consequences[0].toUpperCase()
                    + editReviewData.consequences.substring(1)
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Likelihood
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={likelihoodOptions}
                  placeholderText="Likelihood"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.likelihood,
                    label: reviewData.likelihood === '' ? reviewData.likelihood : reviewData.likelihood[0].toUpperCase()
                    + reviewData.likelihood.substring(1)
                  }}
                />
              ) : (
                <SelectContainer
                  options={likelihoodOptions}
                  placeholderText="Likelihood"
                  isMultiple={false}
                  defaultValue={{
                    label: editReviewData.likelihood[0].toUpperCase()
                    + editReviewData.likelihood.substring(1),
                    value: editReviewData.likelihood
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Corrective Action
            </p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <input
                    className="w-100 input-like"
                    type="text"
                    placeholder="Corrective Action"
                    name="correctiveAction"
                    onChange={handleChange}
                    value={reviewData.correctiveAction}
                  />
                ) : (
                  <input
                    className="w-100 input-like"
                    type="text"
                    placeholder="Corrective Action"
                    value={editReviewData.correctiveAction}
                    name="correctiveAction"
                    onChange={handleChangeEditReview}
                  />
                )
          }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Due Date
            </p>
            <div className="w-100 date-picker-wrapper">
              {
                !Object.keys(editReviewData).length > 0 ? (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Due date"
                    selected={reviewData.dueDate}
                    onChange={(date) => handleSelectDate(date)}
                  />
                ) : (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Due date"
                    selected={editReviewData.dueDate}
                    onChange={(date) => handleSelectDateEdit(date)}
                  />
                )
              }
            </div>
          </div>
          <div className="w-25 mr">
            <p className="label">
              Allocated to
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={allocationList}
                  isMultiple={false}
                  onChange={handleSelectChange}
                  placeholderText="Allocated to"
                  defaultValue={{
                    label: reviewData.allocatedTo,
                    value: reviewData.allocatedTo
                  }}
                />
              ) : (
                <SelectContainer
                  options={allocationList}
                  isMultiple={false}
                  placeholderText="Allocated to"
                  defaultValue={{
                    label: editReviewData.allocatedTo,
                    value: editReviewData.allocatedTo
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
        </div>
        <div className="review-row">
          <div className="w-50 flex">
            <p className="label">Description (Actions to be implemented) :</p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <textarea
                    className="input-like"
                    rows="4"
                    placeholder="Description"
                    name="actionsToImplement"
                    onChange={handleChange}
                    value={reviewData.actionsToImplement}
                  />
                )
                : (
                  <textarea
                    className="input-like"
                    placeholder="Description"
                    rows="4"
                    value={editReviewData.actionsToImplement}
                    name="actionsToImplement"
                    onChange={handleChangeEditReview}
                  />
                )
            }
          </div>
        </div>
        <div className="row-review">
          <h4 className="mx-3 text-danger">
            { reviewValidationMsg && `${reviewValidationMsg}`}
          </h4>
        </div>
      </ReviewWrapper>
      <ButtonWrapper>
        {
          !Object.keys(editReviewData).length > 0 ? (
            <button
              type="button"
              className="submit-btn"
              onClick={handleSubmitReview}
            >
              Submit Review
            </button>
          )
            : (
              <button
                type="button"
                className="modify-review-btn"
                onClick={handleEditReview}
                disabled={!!isEqual(editReviewData, editReviewGeneral)}
              >
                Modify Review
              </button>
            )
        }
        {
        !isHouseAdmin
          ? (
            <button
              type="button"
              className="close-incident-btn"
              onClick={handleCloseIncident}
              disabled={incident && incident.status !== 'REVIEWED'}
            >
              Close Incident
            </button>
          )
          : ''
      }
      </ButtonWrapper>
    </>
  );
};
IncidentReviewThird.propTypes = {
  incidentId: PropTypes.shape.isRequired,
  editReviewData: PropTypes.shape.isRequired,
  reviewData: PropTypes.shape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSelectChangeEdit: PropTypes.func.isRequired,
  handleSelectDate: PropTypes.func.isRequired,
  handleSelectDateEdit: PropTypes.func.isRequired,
  checkReviewValidation: PropTypes.func.isRequired,
  handleChangeEditReview: PropTypes.func.isRequired,
  reviewValidationMsg: PropTypes.string.isRequired,
  incident: PropTypes.shape.isRequired,
  editReviewGeneral: PropTypes.shape.isRequired
};

export default IncidentReviewThird;
