import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import editIcon from '../../assets/logo/edit.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import { CaseNoteRecordWrapper, FirstRow, SectionBox } from './index.style';

function MiscellaneousNote(props) {
  const {
    noteDetails: {
      date,
      house,
      client,
      subject,
      content,
      user,
      lastUploadedBy
    },
    handleDeleteNote,
    handleUpdateModal
  } = props;
  return (
    <CaseNoteRecordWrapper>
      <div className="contact-header" data-testid="contact">
        <div>
          <button
            type="button"
            onClick={() => handleUpdateModal(props.noteDetails)}
          >
            <img src={editIcon} alt="edit icon" />
          </button>
          <button
            type="button"
            className="delete-btn"
            onClick={() => handleDeleteNote(props.noteDetails)}
          >
            <img src={deleteIcon} alt="delete icon" />
          </button>
        </div>
      </div>
      <FirstRow>
        <SectionBox>
          <div className="small-box">
            Date
            <span>:</span>
          </div>
          <span className="value">
            {moment(date).format('DD MMM YYYY')}
          </span>
        </SectionBox>
        <SectionBox>
          {
            house ? (
              <>
                <div className="small-box w--60">
                  House
                  <span>:</span>
                </div>
                <span className="value">
                  {house}
                </span>
              </>
            ) : null
          }
          {
            client ? (
              <>
                <div className="small-box w--60">
                  Client
                  <span>:</span>
                </div>
                <span className="value">
                  {client}
                </span>
              </>
            ) : null
          }
          {
            user ? (
              <>
                <div className="small-box w--50">
                  User
                  <span>:</span>
                </div>
                <span className="value">
                  {user}
                </span>
              </>
            ) : null
          }
        </SectionBox>
      </FirstRow>
      <SectionBox>
        <div className="small-box">
          Subject
          <span>:</span>
        </div>
        <span className="value">
          {subject}
        </span>
      </SectionBox>
      <SectionBox>
        <div className="small-box content">
          Content
          <span>:</span>
        </div>
        <span className="value"><pre>{content}</pre></span>
      </SectionBox>
      <SectionBox>
        <div className="small-box w--140">
          Last Uploaded By
          <span>:</span>
        </div>
        <span className="value">
          {lastUploadedBy}
        </span>
      </SectionBox>
    </CaseNoteRecordWrapper>
  );
}

MiscellaneousNote.propTypes = {
  noteDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  handleUpdateModal: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired
};

export default MiscellaneousNote;
