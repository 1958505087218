import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import { bowelHeaders, bowelSize, initialBowelMovementRecord } from '../../../lookups/bowelLookups';
import { checkIfAdminOnly, getUserDetails } from '../../../helpers/utils';
import {
  BowelMovementHeader, Title, Button, BowelMovementChart, TypeBox,
  HeaderRow, BowelRecordRow, ColumnOne, ColumnTwo, ColumnThree,
  AddRecord, FormWrapper, NameBox
} from './index.style';
import CustomModal from '../../CustomModal';
import userIcon from '../../../assets/logo/userIcon.svg';
import addIcon from '../../../assets/logo/add_icon.svg';
import { createBowelMovementRecord, updateBowelMovementRecord, listBowelMovementRecords } from '../../../services/result.services';
import SelectContainer from '../../SelectContainer';
import NoData from '../../NoData';

const BowelMovementRecord = ({ setSelectedTracker }) => {
  const bowelRecordRequestObject = {
    start: moment().subtract(14, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    pageSize: 1000,
    pageNumber: 0
  };
  const location = useLocation();
  const clientId = location.pathname.split('/')[2];
  const [bowelMovementRecord, setBowelMovementRecord] = useState(initialBowelMovementRecord);
  const [bowelMovementRecordList, setBowelMovementRecordList] = useState([]);
  const [validationMsg, setValidationMsg] = useState('');
  const [showCustomModalAddRecord, setShowCustomModalAddRecord] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (event, recordId) => {
    const { checked, name } = event.target;
    const recordToBeChanged = bowelMovementRecordList.find((record) => record.id === recordId);
    if (recordToBeChanged) {
      recordToBeChanged[name] = checked;
      const updatedRecordList = bowelMovementRecordList.map((record) => {
        if (record.id === recordId) {
          return recordToBeChanged;
        }
        return record;
      });
      setBowelMovementRecordList(updatedRecordList);
    }
  };

  const handleUpdateRecordTime = (date, recordId) => {
    const recordToBeChanged = bowelMovementRecordList.find((record) => record.id === recordId);
    if (recordToBeChanged) {
      recordToBeChanged.recordTime = date;
      const updatedRecordList = bowelMovementRecordList.map((record) => {
        if (record.id === recordId) {
          return recordToBeChanged;
        }
        return record;
      });
      setBowelMovementRecordList(updatedRecordList);
    }
  };

  const handleUpdateBowelSize = (selectedSize, recordId) => {
    const recordToBeChanged = bowelMovementRecordList.find((record) => record.id === recordId);
    if (recordToBeChanged) {
      recordToBeChanged.size = selectedSize?.value;
      const updatedRecordList = bowelMovementRecordList.map((record) => {
        if (record.id === recordId) {
          return recordToBeChanged;
        }
        return record;
      });
      setBowelMovementRecordList(updatedRecordList);
    }
  };

  const handleSelectDate = (date, name) => {
    setBowelMovementRecord((prevDetails) => ({
      ...prevDetails,
      [name]: date
    }));
  };

  const handleSelectBowelSize = (selectedSize) => {
    const { value } = selectedSize;
    setBowelMovementRecord((prevDetails) => ({
      ...prevDetails,
      size: value
    }));
  };

  const handleCheckBowelTypes = (event) => {
    const { checked, name } = event.target;
    setBowelMovementRecord((prevDetails) => ({
      ...prevDetails,
      [name]: checked
    }));
  };

  const checkIfRecordAlreadyCreated = () => {
    if (bowelMovementRecordList.length > 0) {
      return true;
    }
    return false;
  };

  const resetBowelMovementRecord = () => { setBowelMovementRecord(initialBowelMovementRecord); };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkValidation = (record) => {
    if (validationMsg) {
      setValidationMsg('');
    }
    if (!checkIfRecordAlreadyCreated() && !record.startDate) {
      setValidationMsg('Please select start date');
      return false;
    }
    if (record.recordTime === '') {
      setValidationMsg('Please select record date/time');
      return false;
    }
    if (record.size === '') {
      setValidationMsg('Please select size');
      return false;
    }
    if (!record.type1
      && !record.type2
      && !record.type3
      && !record.type4
      && !record.type5
      && !record.type6
      && !record.type7) {
      setValidationMsg('Please select a bowel type');
      return false;
    }
    return true;
  };

  const getBowelMovementRecords = async (requestObject) => {
    setLoading(true);
    const resp = await listBowelMovementRecords(requestObject);
    if (!resp) {
      setLoading(false);
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredResp = resp.data
        .filter((record) => !record.deleted)
        .sort((a, b) => a.id - b.id);
      setBowelMovementRecordList(filteredResp);
    } else {
      setLoading(false);
      setBowelMovementRecordList([]);
    }
  };

  const createBowelMovementRecordHandler = async () => {
    if (!checkValidation(bowelMovementRecord)) return;
    setLoading(true);
    const { recordTime, startDate, ...restDetails } = bowelMovementRecord;
    const resp = await createBowelMovementRecord({
      ...restDetails,
      clientId,
      lastUploadedBy: getUserDetails().firstName,
      recordTime: moment(recordTime).format('YYYY-MM-DD HH:mm:ss'),
      startDate: checkIfRecordAlreadyCreated()
        ? bowelMovementRecordList[0].startDate
        : moment(startDate).format('YYYY-MM-DD')
    });
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Record Created Successfully!',
        icon: 'success',
        timer: 3000
      });
      setValidationMsg('');
      setShowCustomModalAddRecord(false);
      getBowelMovementRecords({ ...bowelRecordRequestObject, clientId });
    } else {
      setLoading(false);
      swal({
        title: 'Record Creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      setShowCustomModalAddRecord(false);
    }
  };

  const updateBowelMovementRecordHandler = async (payload) => {
    setLoading(true);
    const {
      id, recordTime, lastUpdatedAt, ...restDetails
    } = payload;
    const resp = await updateBowelMovementRecord(id, {
      ...restDetails,
      lastUploadedBy: getUserDetails().firstName,
      currentLastUpdatedAt: lastUpdatedAt,
      recordTime: moment(recordTime).format('YYYY-MM-DD HH:mm:ss')
    });
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Record Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getBowelMovementRecords({ ...bowelRecordRequestObject, clientId });
    } else {
      setLoading(false);
      swal({
        title: 'Record Updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getBowelMovementRecords({ ...bowelRecordRequestObject, clientId });
  }, [clientId]);

  return (
    <>
      <BowelMovementHeader>
        <Title>Bowel Movement Tracker</Title>
        <Button onClick={() => setSelectedTracker(0)}>Back</Button>
      </BowelMovementHeader>
      <BowelMovementChart>
        <AddRecord
          disabled={checkIfAdminOnly()}
          onClick={() => {
            resetBowelMovementRecord();
            setShowCustomModalAddRecord(true);
          }}
        >
          <img src={addIcon} alt="add icon" />
        </AddRecord>
        {
          !checkIfRecordAlreadyCreated() && <NoData />
        }
        {
          checkIfRecordAlreadyCreated() && (
            <>
              <HeaderRow>
                <ColumnOne>
                  <div className="client_details">
                    <p>{bowelMovementRecordList[0].clientName}</p>
                    <div className="dob_track_from">
                      <p className="dob">
                        DOB : &nbsp;
                        {moment(bowelMovementRecordList[0].clientDOB).format('DD MMM YYYY')}
                      </p>
                      <p className="track_from">
                        Track From : &nbsp;
                        {moment(bowelMovementRecordList[0].startDate).format('DD MMM YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="first_three_header">
                    <p className="s_num">S.No.</p>
                    <p className="date_time">Date and Time</p>
                    <p className="size">Size</p>
                  </div>
                </ColumnOne>
                <ColumnTwo>
                  { bowelHeaders.map((header) => (
                    <TypeBox key={header.name}>
                      <img className="bowel_img" src={header.src} alt="bowel type icons" />
                      <div className="name_box">
                        <p className="type_name">{header.name}</p>
                      </div>
                    </TypeBox>
                  ))}
                </ColumnTwo>
                <ColumnThree>
                  <p className="staff_initials">Staff Initials</p>
                </ColumnThree>
              </HeaderRow>
              {
                bowelMovementRecordList.map((record, index) => (
                  <BowelRecordRow key={record.id}>
                    <ColumnOne>
                      <div className="first_three_header first_three_field">
                        <p className="s_num_data">{index + 1}</p>
                        <p className="date_time_data">
                          <DatePicker
                            timeFormat="HH:mm"
                            showTimeSelect
                            className="input w-100"
                            dateFormat="dd/MM/yyyy, HH:mm:ss"
                            placeholderText="Select Record Time"
                            maxDate={new Date(moment(record.startDate).add(14, 'days'))}
                            minDate={new Date(moment(record.startDate))}
                            selected={new Date(record.recordTime)}
                            disabled={checkIfAdminOnly()}
                            onChange={
                              (selectedDate) => handleUpdateRecordTime(selectedDate, record.id)
                            }
                          />
                        </p>
                        <p className="size_data">
                          <SelectContainer
                            id="bowelSize"
                            options={bowelSize.length > 0 && bowelSize}
                            placeholderText="Select Size"
                            className="select-container"
                            isMultiple={false}
                            onChange={
                              (selectedSize) => handleUpdateBowelSize(selectedSize, record.id)
                            }
                            defaultValue={{
                              label: record.size,
                              value: record.size
                            }}
                            disabled={!bowelSize.length > 0 || checkIfAdminOnly()}
                          />
                        </p>
                      </div>
                    </ColumnOne>
                    <ColumnTwo>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type1${record.id}`}
                        >
                          <input
                            name="type1"
                            type="checkbox"
                            id={`type1${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type1}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type2${record.id}`}
                        >
                          <input
                            name="type2"
                            type="checkbox"
                            id={`type2${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type2}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type3${record.id}`}
                        >
                          <input
                            name="type3"
                            type="checkbox"
                            id={`type3${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type3}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type4${record.id}`}
                        >
                          <input
                            name="type4"
                            type="checkbox"
                            id={`type4${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type4}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type5${record.id}`}
                        >
                          <input
                            name="type5"
                            type="checkbox"
                            id={`type5${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type5}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type6${record.id}`}
                        >
                          <input
                            name="type6"
                            type="checkbox"
                            id={`type6${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type6}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                          htmlFor={`type7${record.id}`}
                        >
                          <input
                            name="type7"
                            type="checkbox"
                            id={`type7${record.id}`}
                            onChange={(event) => handleChange(event, record.id)}
                            disabled={checkIfAdminOnly()}
                            checked={record.type7}
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                    </ColumnTwo>
                    <ColumnThree>
                      <Button
                        width="65px"
                        margintb="0"
                        height="25px"
                        disabled={checkIfAdminOnly()}
                        onClick={() => updateBowelMovementRecordHandler(record)}
                      >
                        Save
                      </Button>
                      <NameBox>
                        <div className="icon-name-wrapper">
                          <img className="icon" src={userIcon} alt="user icon" />
                        </div>
                        <span>
                          { record.lastUploadedBy }
                        </span>
                      </NameBox>
                    </ColumnThree>
                  </BowelRecordRow>
                ))
              }
            </>
          )
        }
        <CustomModal
          open={showCustomModalAddRecord}
          setClose={() => {
            setShowCustomModalAddRecord(false);
          }}
          title="Add Bowel Movement Note"
          width="580px"
          operationName="Add"
          onSubmit={createBowelMovementRecordHandler}
        >
          <div>
            <FormWrapper>
              <div className="input-with-half-width">
                <label htmlFor="selectContainer">
                  Select Start Date
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  maxDate={new Date()}
                  minDate={new Date(moment().subtract(1, 'days'))}
                  selected={checkIfRecordAlreadyCreated()
                    ? new Date(bowelMovementRecordList[0].startDate)
                    : bowelMovementRecord.startDate}
                  onChange={(date) => handleSelectDate(date, 'startDate')}
                  disabled={checkIfRecordAlreadyCreated()}
                />
              </div>
              <div className="input-with-half-width">
                <label htmlFor="selectContainer">
                  Select Record Date/Time
                </label>
                <DatePicker
                  timeFormat="HH:mm"
                  showTimeSelect
                  className="input w-100"
                  dateFormat="dd/MM/yyyy, HH:mm:ss"
                  placeholderText="Select Record Date/Time"
                  maxDate={checkIfRecordAlreadyCreated()
                    ? new Date(moment(bowelMovementRecordList[0].startDate).add(14, 'days'))
                    : new Date()}
                  minDate={checkIfRecordAlreadyCreated()
                    ? new Date(bowelMovementRecordList[0].startDate)
                    : new Date(moment().subtract(1, 'days'))}
                  selected={bowelMovementRecord.recordTime}
                  onChange={(selectedDate) => handleSelectDate(selectedDate, 'recordTime')}
                />
              </div>
              <div className="input-with-half-width">
                <label htmlFor="bowelSizeModal">
                  Select Bowel Size
                </label>
                <SelectContainer
                  id="bowelSizeModal"
                  options={bowelSize.length > 0 && bowelSize}
                  placeholderText="Select Size"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectBowelSize}
                  defaultValue={{
                    label: bowelMovementRecord.size,
                    value: bowelMovementRecord.size
                  }}
                  disabled={!bowelSize.length > 0}
                />
              </div>
              <div className="input-with-full-width">
                <div className="select-type">Select Type</div>
                {
                  bowelHeaders.map((type, index) => (
                    <label
                      className="container"
                      key={type.name}
                      htmlFor={type.name + index}
                    >
                      <img className="type_img" src={type.src} alt={type.name} />
                      <input
                        name={type.value}
                        type="checkbox"
                        id={type.name + index}
                        onChange={handleCheckBowelTypes}
                        checked={bowelMovementRecord[type.value]}
                      />
                      <span className="checkmark" />
                    </label>
                  ))
                }
              </div>
            </FormWrapper>
            { validationMsg !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsg}</h6>}
          </div>
        </CustomModal>
      </BowelMovementChart>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

BowelMovementRecord.propTypes = {
  setSelectedTracker: PropTypes.func.isRequired
};

export default BowelMovementRecord;
