/** @format */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import DailyFoodDrinkTracker from './DailyFoodDrinkTracker/DailyFoodDrinkTracker';
import SleepTracker from './SleepTracker/SleepTracker';
import BowelMovementRecord from './BowelMovementRecord/BowelMovementRecord';
import NightChecklist from './NightChecklist/NightChecklist';
import {
  ClientFormWrapper,
  ClientFormContentWrapper,
  ClientFormBoxWrapper,
  ClientFormBox,
  ClientFormCircle,
  ClientFormButton
} from './index.style';
import nightChecklistIcon from '../../assets/logo/nightChecklistIcon.svg';
import foodDrinksIcon from '../../assets/logo/foodDrinksIcon.svg';
import sleepTrackerIcon from '../../assets/logo/sleepTrackerIcon.svg';
import bowelMovementIcon from '../../assets/logo/bowel_movement.svg';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { getFormsForClient } from '../../services/result.services';
import NoData from '../NoData';

function ClientReports() {
  const location = useLocation();
  const [selectedTracker, setSelectedTracker] = useState(0);
  const [clientFormsData, setClientFormsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getClientFormsData = async (id) => {
    setLoading(true);
    const resp = await getFormsForClient(id);
    if (!resp) return;
    if (resp.status === 200) {
      setClientFormsData(resp.data);
      setLoading(false);
    } else {
      setClientFormsData([]);
      setLoading(false);
    }
  };

  const setTracker = (index) => {
    setSelectedTracker(index);
  };
  const clientId = location.pathname.split('/')[2];

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getClientFormsData(clientId);
  }, [clientId]);

  return (
    <div>
      {selectedTracker === 0 && (
      <ClientFormWrapper>
        <ClientFormContentWrapper>
          {
            clientFormsData.length > 0 && clientFormsData.find((client) => client.lookupId === 77)
          && (
          <ClientFormBoxWrapper>
            <ClientFormBox onClick={() => setTracker(1)}>
              <ClientFormCircle>
                <img src={nightChecklistIcon} alt="night checklist icon" />
              </ClientFormCircle>
              <ClientFormButton>
                Nightly Tasks Checklist
              </ClientFormButton>
            </ClientFormBox>
          </ClientFormBoxWrapper>
          )
          }

          {
            clientFormsData.length > 0 && clientFormsData.find((client) => client.lookupId === 75)
          && (
          <ClientFormBoxWrapper>
            <ClientFormBox onClick={() => setTracker(2)}>
              <ClientFormCircle>
                <img src={foodDrinksIcon} alt="food drink icon" />
              </ClientFormCircle>
              <ClientFormButton>
                Food and Drinks Diary
              </ClientFormButton>
            </ClientFormBox>
          </ClientFormBoxWrapper>
          )
          }

          {
            clientFormsData.length > 0 && clientFormsData.find((client) => client.lookupId === 76)
          && (
          <ClientFormBoxWrapper>
            <ClientFormBox onClick={() => setTracker(3)}>
              <ClientFormCircle>
                <img src={sleepTrackerIcon} alt="sleep tracker icon" />
              </ClientFormCircle>
              <ClientFormButton>
                Sleep Tracker
              </ClientFormButton>
            </ClientFormBox>
          </ClientFormBoxWrapper>
          )
          }
          {
            clientFormsData.length > 0 && clientFormsData.find((client) => client.lookupId === 104)
          && (
          <ClientFormBoxWrapper>
            <ClientFormBox onClick={() => setTracker(4)}>
              <ClientFormCircle>
                <img src={bowelMovementIcon} alt="plan icon" />
              </ClientFormCircle>
              <ClientFormButton>
                Bowel Movement Tracker
              </ClientFormButton>
            </ClientFormBox>
          </ClientFormBoxWrapper>
          )
          }

          {
            clientFormsData.length === 0 && <NoData />
          }

        </ClientFormContentWrapper>

      </ClientFormWrapper>
      )}

      {selectedTracker === 1 && <NightChecklist setSelectedTracker={setSelectedTracker} />}
      {selectedTracker === 2 && <DailyFoodDrinkTracker setSelectedTracker={setSelectedTracker} />}
      {selectedTracker === 3 && <SleepTracker setSelectedTracker={setSelectedTracker} />}
      {selectedTracker === 4 && <BowelMovementRecord setSelectedTracker={setSelectedTracker} />}

      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </div>
  );
}

export default ClientReports;
