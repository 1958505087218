/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { environmentFactors } from '../../../lookups/lookups';
import { ButtonWrapper, ReviewWrapper } from './index.style';

const IncidentReviewSecond = ({
  handleSelectedIndex, editReviewData,
  handleChange, reviewData, handleChangeEditReview
}) => (
  <>
    <ReviewWrapper>
      <p className="w-100">Choose one or more Environment factors :</p>
      <div className="review-row">
        {environmentFactors.map((environmentFactor) => (
          <div className="w-25 input-like" key={environmentFactor.name}>
            <label
              className="container"
              htmlFor={environmentFactor.name}
            >
              <span>{environmentFactor.label}</span>
              {
                !Object.keys(editReviewData).length > 0
                  ? (
                    <input
                      type="checkbox"
                      id={environmentFactor.name}
                      onChange={handleChange}
                      name={environmentFactor.name}
                      checked={reviewData[environmentFactor.name]}
                    />
                  )
                  : (
                    <input
                      type="checkbox"
                      id={environmentFactor.name}
                      checked={editReviewData[environmentFactor.name]}
                      onChange={handleChangeEditReview}
                      name={environmentFactor.name}
                    />
                  )
              }
              <span className="checkmark" />
            </label>
          </div>
        ))}
      </div>
      <div className="review-row">
        <div className="w-50 flex">
          <p className="label">Description :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like"
                  rows="4"
                  placeholder="Description"
                  name="factorEnvDescription"
                  onChange={handleChange}
                  value={reviewData.factorEnvDescription}
                />
              )
              : (
                <textarea
                  className="input-like"
                  placeholder="Description"
                  rows="4"
                  value={editReviewData.factorEnvDescription}
                  name="factorEnvDescription"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
        <div className="w-50 flex">
          <p className="label">What happened :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  rows="4"
                  name="factorEnvWhatHappened"
                  onChange={handleChange}
                  value={reviewData.factorEnvWhatHappened}
                />
              )
              : (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  as="textarea"
                  rows="4"
                  value={editReviewData.factorEnvWhatHappened}
                  name="factorEnvWhatHappened"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
      </div>
      <hr className="hr" />
      <p className="w-100">
        Specify Work Contribution :
      </p>
      <div className="review-row">
        <div className="w-50 flex">
          <p className="label">Description :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like"
                  rows="4"
                  placeholder="Description"
                  name="factorWorkDescription"
                  onChange={handleChange}
                  value={reviewData.factorWorkDescription}
                />
              )
              : (
                <textarea
                  className="input-like"
                  placeholder="Description"
                  rows="4"
                  value={editReviewData.factorWorkDescription}
                  name="factorWorkDescription"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
        <div className="w-50 flex">
          <p className="label">What happened :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  rows="4"
                  name="factorWorkWhatHappened"
                  onChange={handleChange}
                  value={reviewData.factorWorkWhatHappened}
                />
              )
              : (
                <textarea
                  className="input-like what-happend"
                  rows="4"
                  placeholder="What happened"
                  value={editReviewData.factorWorkWhatHappened}
                  name="factorWorkWhatHappened"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
      </div>
    </ReviewWrapper>
    <ButtonWrapper>
      <button
        type="button"
        className="next-btn"
        onClick={() => handleSelectedIndex(3)}
      >
        Next
      </button>
    </ButtonWrapper>
  </>

);
IncidentReviewSecond.propTypes = {
  handleSelectedIndex: PropTypes.func.isRequired,
  editReviewData: PropTypes.shape.isRequired,
  handleChange: PropTypes.func.isRequired,
  reviewData: PropTypes.shape.isRequired,
  handleChangeEditReview: PropTypes.func.isRequired
};

export default IncidentReviewSecond;
