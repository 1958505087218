/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import { nightChecklistData } from '../../../lookups/lookups';
import {
  TableWrapper,
  TableHeadingFirstBox,
  TableHeadingsWrapper,
  TableRowWrapper,
  ColumnOneBox,
  ColumnTwoBox,
  NameBox
} from '../../../components/ClientReports/NightChecklist/index.style';
import userIcon from '../../../assets/logo/userIcon.svg';
import { getNightReportParticular } from '../../../services/result.services';
import { getTodayDate } from '../../../helpers/utils';

const NightChecklist = ({ clientId, reportDate }) => {
  const [checklistData, setChecklistData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getParticularNightReportHandler = async (payload) => {
    setLoading(true);
    const resp = await getNightReportParticular(payload);
    if (!resp) return;
    if (resp.status === 200) {
      setLoading(false);
      setChecklistData(resp.data);
    } else if (resp.data && !resp.data.status) {
      setChecklistData([]);
      setLoading(false);
    } else {
      setChecklistData([]);
      setLoading(false);
      swal({
        title: 'Failed to get NightCheckList!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  function handleShowUserName(reported) {
    return checklistData[`${reported}By`];
  }

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getParticularNightReportHandler({
      clientId,
      reportDate: getTodayDate(moment(reportDate).format('YYYY-MM-DD'), 'forApi')
      && getTodayDate(moment(reportDate).format('YYYY-MM-DD'), 'forApi')
    });
  }, [clientId, reportDate]);

  return (
    <>
      <div>
        <TableWrapper>
          <TableHeadingsWrapper>
            <TableHeadingFirstBox>
              Tasks
            </TableHeadingFirstBox>
          </TableHeadingsWrapper>

          {
            nightChecklistData.map((task) => (
              <TableRowWrapper key={task.id}>
                <ColumnOneBox>
                  {task.taskName}
                </ColumnOneBox>
                <ColumnTwoBox>
                  <label
                    className="container disabled"
                    htmlFor={task.id}
                  >
                    <NameBox>
                      <div className="icon-name-wrapper">
                        <img className="icon" src={userIcon} alt="user icon" />
                      </div>
                      <span>
                        {handleShowUserName(task.name)}
                      </span>
                    </NameBox>
                    <input
                      name={task.name}
                      type="checkbox"
                      id={task.id}
                      disabled
                      checked={checklistData[task.name]}
                    />
                    <span className="checkmark" />
                  </label>
                </ColumnTwoBox>
              </TableRowWrapper>
            ))
          }
        </TableWrapper>
        <LoadingModal
          show={show}
          onHide={handleClose}
          animation
          centered
          width="4%"
        >
          <Modal.Body className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Modal.Body>
        </LoadingModal>
      </div>
    </>
  );
};

NightChecklist.propTypes = {
  clientId: PropTypes.string.isRequired,
  reportDate: PropTypes.shape.isRequired
};

export default NightChecklist;
