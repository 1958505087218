/** @format */

import React, { useState, useEffect } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  ClientReportsWrapper,
  ReviewWrapper,
  ShowReportsButtonWrapper,
  CaseNoteRecordsWrapper,
  FormWrapper,
  AddFolder
} from './index.style';
import SelectContainer from '../../components/SelectContainer';
import AddIcon from '../../assets/logo/add_icon.svg';
import {
  getHouseList,
  getClientListByHouse,
  getListCaseNotes,
  createCaseNote,
  updateCaseNote
} from '../../services/result.services';
import CaseNoteRecord from '../../components/CaseNoteRecord/CaseNoteRecord';
import CustomModal from '../../components/CustomModal';
import NoData from '../../components/NoData';

const AdminClients = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [listHouses, setListHouses] = useState([]);
  const [formData, setFormData] = useState({
    houseCode: '',
    clientName: ''
  });
  const [addCaseNoteFormData, setAddCaseNoteFormData] = useState({
    houseCode: '',
    clientName: ''
  });
  const [clientList, setClientList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [listCaseNotes, setListCaseNotes] = useState([]);
  const [showCustomModalAddCaseNotes, setShowCustomModalAddCaseNotes] = useState(false);
  const [showCustomModalUpdateCaseNotes, setShowCustomModalUpdateCaseNotes] = useState(false);
  const [caseNoteObj, setCaseNoteObj] = useState({
    clientId: '',
    noteDate: '',
    startTime: '',
    endTime: '',
    subject: '',
    content: ''
  });
  const [validationMsgAddCaseNote, setValidationMsgAddCaseNote] = useState('');
  const [caseNoteEditObj, setCaseNoteEditObj] = useState({});
  const [validationMsgUpdateCaseNote, setValidationMsgUpdateCaseNote] = useState('');
  const [showCaseNotes, setShowCaseNotes] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-case-notes') {
      setShowCustomModalAddCaseNotes(false);
    }
    if (operation === 'update-case-notes') {
      setShowCustomModalUpdateCaseNotes(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCaseNoteObj((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setCaseNoteEditObj((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdate = (obj) => {
    setShowCustomModalUpdateCaseNotes(true);
    setCaseNoteEditObj(obj);
  };

  const resetAddCaseNote = () => {
    setCaseNoteObj({
      clientId: clientList.length > 0
      && clientList.find((c) => c.label === formData.clientName
      && formData.clientName)
      && clientList.find((c) => c.label === formData.clientName).id,
      noteDate: '',
      startTime: '',
      endTime: '',
      subject: '',
      content: ''
    });
    setAddCaseNoteFormData({
      houseCode: '',
      clientName: ''
    });
  };

  const checkAddCaseNotevalidation = () => {
    if (!addCaseNoteFormData.houseCode) {
      setValidationMsgAddCaseNote('Please select House Code');
      return false;
    }
    if (!addCaseNoteFormData.clientName) {
      setValidationMsgAddCaseNote('Please select Client Name');
      return false;
    }
    if (!caseNoteObj.noteDate) {
      setValidationMsgAddCaseNote('Please select Note Date');
      return false;
    }
    if (!caseNoteObj.startTime) {
      setValidationMsgAddCaseNote('Please select start time');
      return false;
    }
    if (!caseNoteObj.endTime) {
      setValidationMsgAddCaseNote('Please select end time');
      return false;
    }
    if (Number(caseNoteObj.startTime.split(':').join('')) === Number(caseNoteObj.endTime.split(':').join(''))) {
      setValidationMsgAddCaseNote('Start time must be earlier than end time');
      return false;
    }
    if (!caseNoteObj.subject) {
      setValidationMsgAddCaseNote('Please enter subject');
      return false;
    }
    if (!caseNoteObj.content) {
      setValidationMsgAddCaseNote('Please enter content');
      return false;
    }
    return true;
  };

  const checkUpdateCaseNotevalidation = () => {
    if (caseNoteEditObj && !caseNoteEditObj.noteDate) {
      setValidationMsgUpdateCaseNote('Please select Note Date');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.startTime) {
      setValidationMsgUpdateCaseNote('Please select start time');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.endTime) {
      setValidationMsgUpdateCaseNote('Please select end time');
      return false;
    }
    if (caseNoteEditObj && Number(caseNoteEditObj.startTime.split(':').join('')) === Number(caseNoteEditObj.endTime.split(':').join(''))) {
      setValidationMsgUpdateCaseNote('Start time must be earlier than end time');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.subject) {
      setValidationMsgUpdateCaseNote('Please enter subject');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.content) {
      setValidationMsgUpdateCaseNote('Please enter content');
      return false;
    }
    return true;
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectAddCaseCaseNoteChange = (e) => {
    const { name, value } = e;
    setAddCaseNoteFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClearSelection = () => {
    setFormData((prevState) => ({
      ...prevState,
      houseCode: '',
      clientName: ''
    }));
    setStartDate('');
    setEndDate('');
  };

  const getHousesHandler = async () => {
    setLoading(true);
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const housesList = filteredActiveHouses && filteredActiveHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`,
        name: 'houseCode'
      }));
      setListHouses(housesList);
      setLoading(false);
    } else {
      setListHouses([]);
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setShowCustomModalAddCaseNotes(true);
  };

  const getClientsByHouse = async (houseCode) => {
    setLoading(true);
    if (!formData.houseCode) {
      setLoading(false);
      return;
    }
    const resp = await getClientListByHouse(houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientName',
        id: `${ele.id}`
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getClientsByHouseForAddCaseNote = async (houseCode) => {
    setLoading(true);
    if (!addCaseNoteFormData.houseCode) {
      setLoading(false);
      return;
    }
    const resp = await getClientListByHouse(houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientName',
        id: `${ele.id}`
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getListCaseNotesHandler = async (payload) => {
    setLoading(true);
    const resp = await getListCaseNotes(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filterNotDeletedCaseNotes = resp.data.filter((ele) => !ele.deleted);
      setListCaseNotes(filterNotDeletedCaseNotes);
    } else {
      setLoading(false);
    }
  };

  const handleShowReports = () => {
    setShowCaseNotes(true);
  };

  const getCaseNotesWithParameters = () => {
    getListCaseNotesHandler({
      start: startDate && moment(startDate).format('YYYY-MM-DD'),
      end: endDate && moment(endDate).format('YYYY-MM-DD'),
      pageNumber: 0,
      pageSize: 1000,
      clientId: clientList.length > 0
      && clientList.find((c) => c.label === formData.clientName
      && formData.clientName)
      && clientList.find((c) => c.label === formData.clientName).id
    });
  };

  const handleAddCaseNoteHandler = async () => {
    if (!checkAddCaseNotevalidation()) return;
    setLoading(true);
    const resp = await createCaseNote({
      ...caseNoteObj,
      startTime: `${caseNoteObj.noteDate} ${caseNoteObj.startTime}:00`,
      endTime: `${caseNoteObj.noteDate} ${caseNoteObj.endTime}:00`
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Case Note created successfully!',
        icon: 'success',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalAddCaseNotes(false);
      resetAddCaseNote();
      getListCaseNotesHandler({
        start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        pageNumber: 0,
        pageSize: 1000,
        clientId: clientList.length > 0
        && clientList.find((c) => c.label === addCaseNoteFormData.clientName
        && addCaseNoteFormData.clientName)
        && clientList.find((c) => c.label === addCaseNoteFormData.clientName).id
      });
    } else {
      setLoading(false);
      swal({
        title: 'Case Note creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalAddCaseNotes(false);
      resetAddCaseNote();
    }
  };

  const handleUpdateCaseNoteHandler = async () => {
    if (!checkUpdateCaseNotevalidation()) return;
    setLoading(true);
    const resp = await updateCaseNote(caseNoteEditObj && caseNoteEditObj.id, {
      ...caseNoteEditObj,
      startTime: caseNoteEditObj
      && caseNoteEditObj.startTime
      && caseNoteEditObj.startTime.split(' ').length > 1
        ? `${caseNoteEditObj.startTime}`
        : `${caseNoteEditObj.noteDate} ${caseNoteEditObj.startTime}:00`,
      endTime: caseNoteEditObj
        && caseNoteEditObj.endTime
        && caseNoteEditObj.endTime.split(' ').length > 1
        ? `${caseNoteEditObj.endTime}`
        : `${caseNoteEditObj.noteDate} ${caseNoteEditObj.endTime}:00`
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Case Note updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalUpdateCaseNotes(false);
      resetAddCaseNote();
      getListCaseNotesHandler({
        start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        pageNumber: 0,
        pageSize: 1000,
        clientId: clientList.length > 0
        && clientList.find((c) => c.label === formData.clientName
        && formData.clientName)
        && clientList.find((c) => c.label === formData.clientName).id
      });
    } else {
      setLoading(false);
      swal({
        title: 'Case Note updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalUpdateCaseNotes(false);
      resetAddCaseNote();
    }
  };

  const handleDeleteCaseNote = async (obj) => {
    setCaseNoteEditObj(obj);
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateCaseNote(obj && obj.id, {
        ...obj,
        deleted: true
      });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Case Note deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getListCaseNotesHandler({
          start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
          end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
          pageNumber: 0,
          pageSize: 1000,
          clientId: clientList.length > 0
          && clientList.find((c) => c.label === formData.clientName
          && formData.clientName)
          && clientList.find((c) => c.label === formData.clientName).id
        });
      } else {
        setLoading(false);
        swal({
          title: 'Case Note deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    getHousesHandler();
  }, []);

  useEffect(() => {
    getClientsByHouse(formData.houseCode);
    setFormData((prev) => ({
      ...prev,
      clientName: ''
    }));
    setStartDate('');
    setEndDate('');
  }, [formData.houseCode]);

  useEffect(() => {
    getClientsByHouseForAddCaseNote(addCaseNoteFormData.houseCode);
    setAddCaseNoteFormData((prev) => ({
      ...prev,
      clientName: ''
    }));
  }, [addCaseNoteFormData.houseCode]);

  useEffect(() => {
    setCaseNoteObj({
      clientId: clientList.length > 0
      && clientList.find((c) => c.label === addCaseNoteFormData.clientName
      && addCaseNoteFormData.clientName)
      && clientList.find((c) => c.label === addCaseNoteFormData.clientName).id,
      noteDate: '',
      startTime: '',
      endTime: '',
      subject: '',
      content: ''
    });
  }, [addCaseNoteFormData.clientName]);

  useEffect(() => {
    setListCaseNotes([]);
    if (formData.clientName && startDate && endDate) {
      getCaseNotesWithParameters();
    }
    setCaseNoteObj({
      clientId: clientList.length > 0
      && clientList.find((c) => c.label === formData.clientName
      && formData.clientName)
      && clientList.find((c) => c.label === formData.clientName).id,
      noteDate: '',
      startTime: '',
      endTime: '',
      subject: '',
      content: ''
    });
  }, [formData.clientName]);

  useEffect(() => {
    setListCaseNotes([]);
    if (startDate && endDate) {
      getCaseNotesWithParameters();
    }
  }, [startDate]);

  useEffect(() => {
    setListCaseNotes([]);
    if (endDate) {
      getCaseNotesWithParameters();
    }
  }, [endDate]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <ClientReportsWrapper>
        <div>
          <ReviewWrapper>
            <div className="review-row">
              <div className="w-25 mr">
                <p className="label">
                  Select House Code
                </p>

                <SelectContainer
                  options={listHouses.length > 0 && listHouses}
                  placeholderText="House Code"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: formData.houseCode,
                    label: formData.houseCode
                  }}
                  disabled={!listHouses.length}
                />
              </div>
              <div className="w-25 mr">
                <p className="label">
                  Select Client Name
                </p>

                <SelectContainer
                  options={clientList.length > 0 && clientList}
                  placeholderText="Client Name"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: formData.clientName,
                    label: formData.clientName
                  }}
                  disabled={!formData.houseCode || !clientList.length > 0}
                />
              </div>

              <div className="w-25 mr date-picker-wrapper">
                <p className="label">
                  Select Start Date
                </p>

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled={!formData.clientName}
                />
              </div>

              <div className="w-25 date-picker-wrapper">
                <p className="label">
                  Select End Date
                </p>

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  disabled={!startDate}
                  minDate={startDate && startDate}
                />
              </div>
            </div>
          </ReviewWrapper>
          <ShowReportsButtonWrapper>
            <button
              type="button"
              className="submit-btn"
              onClick={handleClearSelection}
            >
              Clear Selection
            </button>
            <button
              type="button"
              className="submit-btn"
              disabled={!endDate}
              onClick={handleShowReports}
            >
              Show Case Notes
            </button>
          </ShowReportsButtonWrapper>
        </div>
        <AddFolder onClick={() => handleAdd()}>
          <img src={AddIcon} alt="add icon" />
        </AddFolder>
        {
           showCaseNotes && startDate && endDate
             ? (
               <CaseNoteRecordsWrapper>
                 {
                  listCaseNotes.length === 0 && <NoData />
                }
                 {
                  listCaseNotes.length > 0
                  && listCaseNotes.map((ele) => (
                    <CaseNoteRecord
                      date={ele.noteDate}
                      startTime={ele.startTime}
                      endTime={ele.endTime}
                      subject={ele.subject}
                      content={ele.content}
                      openUpdateModal={() => handleUpdate(ele)}
                      deleteCaseNote={() => handleDeleteCaseNote(ele)}
                    />
                  ))
                }
               </CaseNoteRecordsWrapper>
             ) : null
        }
      </ClientReportsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>

      <CustomModal
        open={showCustomModalAddCaseNotes}
        setClose={() => {
          handleCloseCustomModal('add-case-notes');
        }}
        title="Add Case Notes"
        width="580px"
        operationName="Add"
        onSubmit={handleAddCaseNoteHandler}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="selectContainer">
                Select House Code
              </label>
              <SelectContainer
                options={listHouses.length > 0 && listHouses}
                placeholderText="House Code"
                className="select-container"
                isMultiple={false}
                onChange={handleSelectAddCaseCaseNoteChange}
                defaultValue={{
                  value: addCaseNoteFormData.houseCode,
                  label: addCaseNoteFormData.houseCode
                }}
                disabled={!listHouses.length}
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="clientName">
                Select Client Name
              </label>
              <SelectContainer
                options={clientList.length > 0 && clientList}
                placeholderText="Client Name"
                className="select-container"
                isMultiple={false}
                onChange={handleSelectAddCaseCaseNoteChange}
                defaultValue={{
                  value: addCaseNoteFormData.clientName,
                  label: addCaseNoteFormData.clientName
                }}
                disabled={!addCaseNoteFormData.houseCode || !clientList.length > 0}
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="noteDate">Date</label>
              <input
                type="date"
                id="noteDate"
                placeholder="Date"
                name="noteDate"
                onChange={handleChange}
                value={caseNoteObj.noteDate}
                required
              />
            </div>

            <div className="form-control-wrapper">
              <div className="input-with-full-width">
                <label htmlFor="startTime">Start Time</label>
                <input
                  type="time"
                  name="startTime"
                  onChange={handleChange}
                  value={caseNoteObj.startTime}
                  required
                />
              </div>
              <div className="input-with-full-width">
                <label htmlFor="endTime">End Time</label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  onChange={handleChange}
                  value={caseNoteObj.endTime}
                  required
                />
              </div>
            </div>

            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleChange}
                value={caseNoteObj.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleChange}
                value={caseNoteObj.content}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgAddCaseNote !== '' && <h6 className="text-danger mt-3 ml-2" data-testid="validation-add-case">{validationMsgAddCaseNote}</h6>}
        </div>
      </CustomModal>

      <CustomModal
        open={showCustomModalUpdateCaseNotes}
        setClose={() => {
          handleCloseCustomModal('update-case-notes');
        }}
        title="Update Case Notes"
        width="580px"
        operationName="Update"
        onSubmit={handleUpdateCaseNoteHandler}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="noteDate">Date</label>
              <input
                type="date"
                id="noteDate"
                placeholder="Date"
                name="noteDate"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.noteDate}
                required
              />
            </div>

            <div className="form-control-wrapper">
              <div className="input-with-full-width">
                <label htmlFor="startTime">Start Time</label>
                <input
                  type="time"
                  name="startTime"
                  onChange={handleUpdateChange}
                  value={caseNoteEditObj && caseNoteEditObj.startTime && caseNoteEditObj.startTime.split(' ')[1]}
                  required
                />
              </div>
              <div className="input-with-full-width">
                <label htmlFor="endTime">End Time</label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  onChange={handleUpdateChange}
                  value={caseNoteEditObj && caseNoteEditObj.endTime && caseNoteEditObj.endTime.split(' ')[1]}
                  required
                />
              </div>
            </div>

            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.content}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgUpdateCaseNote !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsgUpdateCaseNote}</h6>}
        </div>
      </CustomModal>

    </>
  );
};

export default AdminClients;
