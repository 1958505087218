/** @format */

import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const HeaderRow = styled.div`
  width: 100%;
  height: 85px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  .logo {
    width: 80px;
    height: 65px;
    margin-left: 10px;
  }

  .links-section {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .select-house-title {
    margin: 0 8px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.od_text_color};
    &:hover {
      color: ${({ theme }) => theme.colors.od_secondary};
    }
  }
  .house-num {
    color: ${({ theme }) => theme.colors.od_secondary};
  }
  .house-icon {
    display: inline-block;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.od_secondary};
    img {
      width: 30px;
      padding: 5px;
    }
  }

  .left {
    margin-right: 5rem;
  }
  .admin-left {
    margin-right: 1.5rem;
  }

  .faded {
    opacity: 0.5;
  }

  .right {
    display: flex;
    justify-content: space-between;

    .icon-wrapper {
      margin: 0 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justiy-content: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .button {
    padding: 10px;
    font-weight: 500;
    text-align: center;
    outline: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.od_text_color};
    &:hover {
      color: ${({ theme }) => theme.colors.od_secondary};
    }
  }
  .button:disabled {
    &:hover {
      color: ${({ theme }) => theme.colors.od_text_color};
    }
  }

  .section-3 {
    margin-right: 3.5rem;
  }

  .dropdown-toggle-logout::after {
    content: none;
  }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  background: ${(props) => props.theme.colors[props.bgcolor] || props.theme.colors.od_neutral};
  color: ${(props) => props.theme.colors[props.color] || props.theme.colors.od_text_color};
  border-radius: ${(props) => props.borderradius};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    background: ${(props) => props.theme.colors[props.bgcolor] || props.theme.colors.od_neutral};
    color: ${(props) => props.theme.colors[props.color] || props.theme.colors.od_secondary};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.od_text_color};
  }
`;

export const DropdownHouseMenu = styled(Dropdown.Menu)`
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 400px;
  padding: 0.75rem 0.5rem;

  .dropdown-item {
    display: block;
    width: 48%;
    font-size: 18px;
    border-radius: 5px;
    font-weight: 400;
    padding: 0.25rem;
    margin: 0.25rem 0;
    text-align: inherit;
    white-space: nowrap;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    color: ${({ theme }) => theme.colors.od_neutral};
    border: 0;
    & > button,
    .nav-link {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.od_text_color};
      width: 100%;
      text-align: left;
      padding: 5px 10px;
    }
    & > button.disabled-btn {
      opacity: 0.6;
    }
    & > button:hover,
    .nav-link:hover {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      color: ${({ theme }) => theme.colors.od_text_color};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
    }
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

  .dropdown-item:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .dropdown-item:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    font-size: 18px;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    & > button,
    .nav-link {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.od_text_color};
      width: 100%;
      text-align: left;
      padding: 5px 10px;
    }
    & > button.disabled-btn {
      opacity: 0.6;
    }
    & > button:hover,
    .nav-link:hover {
      background-color: ${({ theme }) => theme.colors.od_neutral};
      color: ${({ theme }) => theme.colors.od_secondary};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.od_neutral};
      border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
    }
  }
`;

export const DropdownHeader = styled.div`
  text-align: left;
  font-size: 18px;
  padding: 10px 35px;
  color: ${({ theme }) => theme.colors.od_secondary};
`;
