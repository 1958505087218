/** @format */

import styled from 'styled-components';

export const HandoversWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height: calc(75vh - 40px);

  .plus-icon-container{
    position: relative;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0 0 0.2rem 1.5rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const HandoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  h2.date{
    border: none;
  }
`;

export const AddHandover = styled.button`
  position: absolute;
  top: 65px;
  right: -35px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
`;
