/** @format */

import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width:
      state.selectProps.width !== ''
        ? `${state.selectProps.width} !important`
        : '80px',
    border: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#E86B55',
    backgroundColor: state.isSelected ? '#E86B55' : '#fff',
    ':hover': {
      backgroundColor: '#E86B55',
      color: '#fff',
      cursor: 'pointer'
    }
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    cursor: 'pointer',
    background: '#FFFFFF',
    border: '1px solid #F2E4D8',
    borderRadius: '5px',
    ':hover': {
      border: '1px solid #F2E4D8'
    }
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 8px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    fontSize: '16px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9F9F9F'
  }),
  multiValue: (provided) => ({
    ...provided,
    border: '1px solid #AFD8D0',
    background: '#FFFFFF',
    borderRadius: '10px',
    height: '32px',
    padding: '5px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#74BCA9',
    paddingRight: '10px',
    padding: '0px'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    curosr: 'pointer',
    background: '#AFD8D0',
    opacity: '0.2',
    borderRadius: '6px',
    ':hover': {
      cursor: 'pointer'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: 'none',
    display: 'inline-block'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'inline-block'
  })
};
const customBorder = {
  container: (provided, state) => ({
    ...provided,
    width:
      state.selectProps.width !== ''
        ? `${state.selectProps.width} !important`
        : '80px',
    border: '1px solid red'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#E86B55',
    backgroundColor: state.isSelected ? '#E86B55' : '#fff',
    ':hover': {
      backgroundColor: '#E86B55',
      color: '#fff',
      cursor: 'pointer'
    }
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    cursor: 'pointer',
    background: '#FFFFFF',
    border: '1px solid #F2E4D8',
    borderRadius: '5px',
    ':hover': {
      border: '1px solid #F2E4D8'
    }
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 8px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    fontSize: '16px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9F9F9F'
  }),
  multiValue: (provided) => ({
    ...provided,
    border: '1px solid #AFD8D0',
    background: '#FFFFFF',
    borderRadius: '10px',
    height: '32px',
    padding: '5px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#74BCA9',
    paddingRight: '10px',
    padding: '0px'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    curosr: 'pointer',
    background: '#AFD8D0',
    opacity: '0.2',
    borderRadius: '6px',
    ':hover': {
      cursor: 'pointer'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: 'none',
    display: 'inline-block'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'inline-block'
  })
};

const SelectContainer = ({
  options,
  className,
  isMultiple,
  width,
  onChange,
  defaultValue,
  placeholderText,
  disabled,
  error,
  classNamePrefix
}) => (
  <Select
    className={className}
    classNamePrefix={classNamePrefix}
    options={options}
    styles={error ? customBorder : customStyles}
    placeholder={`${(defaultValue.value === '' || defaultValue.length < 1) && placeholderText}`}
    isMulti={isMultiple}
    width={width}
    error={error}
    value={defaultValue.value !== '' && defaultValue}
    onChange={(selected) => onChange(selected)}
    isDisabled={disabled}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#E86B55',
        primary50: '#E86B55'
      }
    })}
  />
);

SelectContainer.defaultProps = {
  className: '',
  width: '',
  placeholderText: 'Select',
  classNamePrefix: ''
};

SelectContainer.propTypes = {
  options: PropTypes.shape.isRequired,
  className: PropTypes.string,
  isMultiple: PropTypes.bool.isRequired,
  width: PropTypes.string,
  placeholderText: PropTypes.string,
  defaultValue: PropTypes.shape.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  classNamePrefix: PropTypes.string
};

export default SelectContainer;
