/** @format */

import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from './routes/Routes';
import { useTheme } from './theme/useTheme';
import { GlobalStyles } from './theme/GlobalStyles';

function App() {
  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme, themeLoaded]);

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
