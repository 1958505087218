/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;
