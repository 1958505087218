/** @format */

import React from 'react';
import styled from 'styled-components';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import supportIcon from '../assets/logo/support_icon.svg';

const FooterDiv = styled.div`
  position: fixed;
  bottom: 0;
  background-color:#FFFFFF !important;
  width:100%;
  display:flex;
  height:50px;
  background: #EBF0F0;
  justify-content:space-between;
  align-items:center;

  img {
    width:20px;
    height:20px;
  }
`;

const DateBox = styled.div`
  font-size:16px;
  color: #38485F;
  margin-left:20px;
  display:flex;
  width:85px;
  justify-content:space-between;
  align-items:center;
`;

const SupportBox = styled.div`
  font-size:16px;
  color: #38485F;
  width:190px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-right:10px;
`;

const Footer = () => (
  <FooterDiv>
    <DateBox>
      <AiOutlineCopyrightCircle />
      <div>2021-25</div>
    </DateBox>
    <SupportBox>
      <img src={supportIcon} alt="support icon" />
      <div>support@edstem.com</div>
    </SupportBox>
  </FooterDiv>
);

export default Footer;
