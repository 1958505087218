/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { FaDotCircle } from 'react-icons/fa';
import moment from 'moment';
import { checkIfHouseAdminOnly, getUserDetails } from '../../helpers/utils';
import { statusList } from '../../lookups/lookups';
import { getHouseList, getIncidentList, editIncident } from '../../services/result.services';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  IncidentsWrapper, IncidentManagementHeader,
  IconContainer, StatusContainer, Dot
} from './index.style';
import {
  TableWrapper, StyledTable, TableContainer, Th, Td
} from '../../styledComponents/common/table.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import detailIcon from '../../assets/logo/detail.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import NoData from '../../components/NoData';
import SelectContainer from '../../components/SelectContainer';

const AdminIncidents = () => {
  const initialStatus = { label: 'ALL', value: 'ALL' };
  const tableBodyRef = useRef('');
  const [incidentList, setIncidentList] = useState([]);
  const [incidentListToBeDisplayed, setIncidentListToBeDisplayed] = useState([]);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [isClientIncidents, setIsClientIncidents] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState('ALL');
  const [houseListForFilter, setHouseListForFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const incidentListRequestObj = {
    pageNumber: 0,
    pageSize: 1000
  };

  const displayStaffIncidents = () => {
    setSelectedValue('ALL');
    setSelectedStatus(initialStatus);
    const staffIncidents = incidentList.filter((incident) => incident.raisedFor === 'STAFF');
    setIsClientIncidents(false);
    if (checkIfHouseAdminOnly()) {
      const staffIncidentsOfUsersOfHouseAdminHouses = staffIncidents.filter(
        (incident) => incident.reportedBy?.houses.some((house) => (
          getUserDetails().houseList.some(
            (houseCode) => houseCode === house.houseCode
          )))
      );
      setIncidentListToBeDisplayed([...staffIncidentsOfUsersOfHouseAdminHouses]);
    } else {
      setIncidentListToBeDisplayed([...staffIncidents]);
    }
  };

  const displayClientIncidents = () => {
    setSelectedValue('ALL');
    setSelectedStatus(initialStatus);
    const clientIncidents = incidentList.filter((incident) => incident.raisedFor === 'CLIENT');
    setIsClientIncidents(true);
    setIncidentListToBeDisplayed([...clientIncidents]);
  };

  const checkIfEscalatedAdmin = (incident) => {
    const isEscalatedTo = incident.escalatedTo && incident.escalatedTo.email;
    if (isEscalatedTo === (getUserDetails().email)) {
      return true;
    }
    return false;
  };

  const getIncidentListHandler = async (param) => {
    setLoading(true);
    const resp = await getIncidentList(param);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      if (!isClientIncidents) {
        setIsClientIncidents(true);
      }
      setLoading(false);
      setIncidentList([...resp.data.filter((incident) => incident.status !== 'INACTIVE')]);
      const clientIncidents = resp.data.filter(
        (incident) => incident.raisedFor === 'CLIENT' && incident.status !== 'INACTIVE'
      );
      const filteredIncidents = clientIncidents.filter(
        (incident) => getUserDetails().houseList.some(
          (houseCode) => houseCode === incident?.house?.houseCode
        )
      );
      setIncidentListToBeDisplayed([...filteredIncidents]);
    } else {
      setLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    if (value === 'ALL') {
      setSelectedValue(value);
      setIncidentListToBeDisplayed([...incidentList.filter(
        (incident) => (
          (isClientIncidents ? (incident.raisedFor === 'CLIENT') : (incident.raisedFor === 'STAFF'))
          && (selectedStatus.value !== 'ALL' ? (selectedStatus.value === incident.status) : (incident.status !== 'ALL'))
        )
      )]);
    } else {
      setSelectedValue(value);
      setIncidentListToBeDisplayed(
        incidentList.filter(
          (incident) => (incident?.house?.houseCode === value
          && (isClientIncidents ? (incident.raisedFor === 'CLIENT') : (incident.raisedFor === 'STAFF')))
          && (selectedStatus.value !== 'ALL' ? (selectedStatus.value === incident.status) : (incident.status !== 'ALL'))
        )
      );
    }
  };

  const handleStatusChange = (e) => {
    const { value } = e;
    if (checkIfHouseAdminOnly() && !isClientIncidents) {
      const staffIncidents = incidentList.filter((incident) => incident.raisedFor === 'STAFF');
      const staffIncidentsOfUsersOfHouseAdminHouses = staffIncidents.filter(
        (incident) => incident.reportedBy?.houses.some((house) => (
          getUserDetails().houseList.some(
            (houseCode) => houseCode === house.houseCode
          )))
      );
      if (value === 'ALL') {
        setSelectedStatus(initialStatus);
        setIncidentListToBeDisplayed([...staffIncidentsOfUsersOfHouseAdminHouses.filter(
          (incident) => (
            incident.status !== 'ALL'
          )
        )]);
      } else {
        setSelectedStatus({ label: value, value });
        setIncidentListToBeDisplayed([...staffIncidentsOfUsersOfHouseAdminHouses.filter(
          (incident) => incident.status === value
        )
        ]);
      }
    } else if (value === 'ALL') {
      setSelectedStatus(initialStatus);
      setIncidentListToBeDisplayed([...incidentList.filter(
        (incident) => (isClientIncidents
          ? (incident.raisedFor === 'CLIENT' && (
            selectedValue !== 'ALL' ? (incident?.house?.houseCode === selectedValue) : ((incident?.house?.houseCode !== 'ALL'))
          )) : (incident.raisedFor === 'STAFF'))
      )]);
    } else {
      setSelectedStatus({ label: value, value });
      setIncidentListToBeDisplayed(
        incidentList.filter(
          (incident) => (incident?.status === value
            && (isClientIncidents ? (incident.raisedFor === 'CLIENT' && (
              selectedValue !== 'ALL' ? (incident?.house?.houseCode === selectedValue) : ((incident?.house?.houseCode !== 'ALL'))
            )) : (incident.raisedFor === 'STAFF')))
        )
      );
    }
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) {
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const houseListModified = filteredHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`
      }));
      setHouseListForFilter([{ label: 'ALL', value: 'ALL' }, ...houseListModified]);
    } else {
      setHouseListForFilter([]);
    }
  };

  const handleDeleteIncident = async (incident) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const {
        category, type, classification, id,
        client, house, ...restIncidentDetails
      } = incident;
      const resp = await editIncident(id,
        {
          ...restIncidentDetails,
          categoryId: category.id,
          clientId: client?.id,
          houseId: house?.id,
          classificationId: classification.id,
          typeId: type.id,
          status: 'INACTIVE'
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Incident deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        setSelectedStatus(initialStatus);
        setSelectedValue('ALL');
        getIncidentListHandler(incidentListRequestObj);
      } else {
        setLoading(false);
        swal({
          title: 'Incident deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getHousesHandler();
    getIncidentListHandler(incidentListRequestObj);
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Incidents </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              {isClientIncidents ? 'Client' : 'Staff'}
            </span>
          </li>
        </ul>
      </BreadCrumb>
      <IncidentsWrapper>
        <IncidentManagementHeader>
          <h2>INCIDENT MANAGEMENT</h2>
          {isClientIncidents
            ? (
              <button
                type="button"
                onClick={() => displayStaffIncidents()}
              >
                Staff
              </button>
            )
            : (
              <button
                type="button"
                onClick={() => displayClientIncidents()}
              >
                Client
              </button>
            )}
        </IncidentManagementHeader>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  incidentList.length !== 0
                && (
                <tr>
                  <Th width="50px">No.</Th>
                  {
                    isClientIncidents && <Th width="100px">Client</Th>
                  }
                  {
                    !isClientIncidents && <Th width="100px">Reported Date</Th>
                  }
                  <Th width="160px">Category</Th>
                  <Th width="160px">Type</Th>
                  <Th width="160px">
                    Reported By
                    <div>Reviewed By</div>
                    <div>Closed By</div>
                  </Th>
                  {
                    isClientIncidents && (
                      <Th width="100px">
                        <span>House</span>
                        <div className="select-wrapper">
                          <SelectContainer
                            options={houseListForFilter.length > 0 && houseListForFilter}
                            placeholderText="House Code"
                            className="select-container"
                            isMultiple={false}
                            defaultValue={{
                              value: selectedValue,
                              label: selectedValue
                            }}
                            onChange={(e) => handleSelectChange(e.value)}
                          />
                        </div>
                      </Th>
                    )
                  }
                  <Th width="100px">
                    <span>Status</span>
                    <div className="select-wrapper">
                      <SelectContainer
                        options={statusList.length > 0 && statusList}
                        placeholderText="Status"
                        className="select-container"
                        isMultiple={false}
                        defaultValue={selectedStatus}
                        onChange={handleStatusChange}
                      />
                    </div>
                  </Th>
                  <Th width="100px" align="center"> </Th>
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                {incidentListToBeDisplayed
                  && incidentListToBeDisplayed.map((incident, index) => (
                    <tr key={incident.id}>
                      <Td width="50px">
                        {index + 1}
                        { checkIfEscalatedAdmin(incident) && incident.status !== 'CLOSED'
                          ? <Dot><FaDotCircle /></Dot> : null}
                      </Td>
                      {
                        isClientIncidents && <Td width="100px" data-testid="clientName">{incident?.client?.name}</Td>
                      }
                      {
                        !isClientIncidents && <Td width="100px">{moment(incident.createdAt).format('DD/MM/YYYY')}</Td>
                      }
                      <Td width="160px">{incident.category.name}</Td>
                      <Td width="160px">{incident.type.name}</Td>
                      {
                        incident.status === 'CLOSED' && <Td width="160px">{incident.closedBy}</Td>
                      }
                      {
                        incident.status === 'REVIEWED' && <Td width="160px">{incident.reviewedBy}</Td>
                      }
                      {
                        incident.status === 'RAISED' && <Td width="160px">{incident.reportedBy.firstName}</Td>
                      }
                      {
                        isClientIncidents && <Td width="100px">{incident?.house?.houseCode}</Td>
                      }
                      <Td width="100px">
                        <StatusContainer>
                          {incident.status === 'CLOSED' && <span className="closed">{incident.status}</span>}
                          {incident.status === 'REVIEWED' && <span className="reviewed">{incident.status}</span>}
                          {incident.status === 'RAISED' && <span className="raised">{incident.status}</span>}
                        </StatusContainer>
                      </Td>
                      <Td width="100px" align="center">
                        {
                          isClientIncidents && incident.status === 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/client/closed/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          isClientIncidents && incident.status !== 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/client/open/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          !isClientIncidents && incident.status === 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/staff/closed/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          !isClientIncidents && incident.status !== 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/staff/open/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteIncident(incident)}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>
                  ))}
                {
                    incidentListToBeDisplayed.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </IncidentsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminIncidents;
