/** @format */

import React from 'react';
import noDataIcon from '../assets/logo/noDataIcon.svg';
import { NoDataWrapper, NoDataImageWrapper, NoDataText } from '../styledComponents/common/noData.style';

const NoData = () => (
  <NoDataWrapper>
    <NoDataImageWrapper>
      <div className="image-icon-wrapper">
        <img src={noDataIcon} alt="No Data Icon" />
      </div>
    </NoDataImageWrapper>
    <NoDataText>
      No Data to Show
    </NoDataText>
  </NoDataWrapper>
);

export default NoData;
