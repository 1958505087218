/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { houseAndAssets, personFactors } from '../../../lookups/lookups';
import { ButtonWrapper, ReviewWrapper } from './index.style';

const IncidentReviewOne = ({
  handleSelectedIndex, editReviewData, incidentId,
  handleChange, reviewData, handleChangeEditReview
}) => (
  <>
    <ReviewWrapper>
      <div className="w-100 flex">
        <p className="label">Incident Number</p>
        <p className="incident_num">{incidentId}</p>
      </div>
      <p className="w-100 ml-sm">Contributing Factors :</p>
      <p className="w-100 ml-sm">Choose one or more House/Asset factors :</p>
      <div className="review-row">
        {houseAndAssets.map((houseFactor) => (
          <div className="w-25 input-like" key={houseFactor.name}>
            <label
              className="container"
              htmlFor={houseFactor.name}
            >
              <span>{houseFactor.label}</span>
              {
                !Object.keys(editReviewData).length > 0
                  ? (
                    <input
                      type="checkbox"
                      id={houseFactor.name}
                      onChange={handleChange}
                      name={houseFactor.name}
                      checked={reviewData[houseFactor.name]}
                    />
                  )
                  : (
                    <input
                      type="checkbox"
                      id={houseFactor.name}
                      checked={editReviewData[houseFactor.name]}
                      onChange={handleChangeEditReview}
                      name={houseFactor.name}
                    />
                  )
              }
              <span className="checkmark" />
            </label>
          </div>
        ))}
      </div>
      <div className="review-row">
        <div className="w-50 flex">
          <p className="label">Description :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like"
                  rows="4"
                  placeholder="Description"
                  name="factorHouseDescription"
                  onChange={handleChange}
                  value={reviewData.factorHouseDescription}
                />
              )
              : (
                <textarea
                  className="input-like"
                  placeholder="Description"
                  rows="4"
                  value={editReviewData.factorHouseDescription}
                  name="factorHouseDescription"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
        <div className="w-50 flex">
          <p className="label">What happened :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  rows="4"
                  name="factorHouseWhatHappened"
                  onChange={handleChange}
                  value={reviewData.factorHouseWhatHappened}
                />
              )
              : (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  as="textarea"
                  rows="4"
                  value={editReviewData.factorHouseWhatHappened}
                  name="factorHouseWhatHappened"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
      </div>
      <hr className="hr" />
      <div className="w-25 flex">
        <p className="label">
          Name of Contributing Person :
        </p>
        {
          !Object.keys(editReviewData).length > 0
            ? (
              <input
                className="input"
                type="text"
                placeholder="Name of Contributing Person!"
                name="factorPersonName"
                onChange={handleChange}
                value={reviewData.factorPersonName}
              />
            )
            : (
              <input
                className="input"
                type="text"
                placeholder="Name of Contributing Person!"
                value={editReviewData.factorPersonName}
                name="factorPersonName"
                onChange={handleChangeEditReview}
              />
            )
          }
      </div>
      <p className="w-100 ml-sm">
        Choose one or more Contributing Person factors :
      </p>
      <div className="review-row">
        {personFactors.map((personFactor) => (
          <div className="w-25 input-like" key={personFactor.name}>
            <label
              className="container"
              htmlFor={personFactor.name}
              key={personFactor.name}
            >
              <span>{personFactor.label}</span>
              {
                !Object.keys(editReviewData).length > 0
                  ? (
                    <input
                      type="checkbox"
                      id={personFactor.name}
                      onChange={handleChange}
                      name={personFactor.name}
                      checked={reviewData[personFactor.name]}
                    />
                  )
                  : (
                    <input
                      type="checkbox"
                      id={personFactor.name}
                      checked={editReviewData[personFactor.name]}
                      onChange={handleChangeEditReview}
                      name={personFactor.name}
                    />
                  )
              }
              <span className="checkmark" />
            </label>
          </div>
        ))}
      </div>
      <div className="review-row">
        <div className="w-50 flex">
          <p className="label">Description :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like"
                  rows="4"
                  placeholder="Description"
                  name="factorPersonDescription"
                  onChange={handleChange}
                  value={reviewData.factorPersonDescription}
                />
              )
              : (
                <textarea
                  className="input-like"
                  placeholder="Description"
                  rows="4"
                  value={editReviewData.factorPersonDescription}
                  name="factorPersonDescription"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
        <div className="w-50 flex">
          <p className="label">What happened :</p>
          {
            !Object.keys(editReviewData).length > 0
              ? (
                <textarea
                  className="input-like what-happend"
                  placeholder="What happened"
                  rows="4"
                  name="factorPersonWhatHappened"
                  onChange={handleChange}
                  value={reviewData.factorPersonWhatHappened}
                />
              )
              : (
                <textarea
                  className="input-like what-happend"
                  rows="4"
                  placeholder="What happened"
                  value={editReviewData.factorPersonWhatHappened}
                  name="factorPersonWhatHappened"
                  onChange={handleChangeEditReview}
                />
              )
          }
        </div>
      </div>
    </ReviewWrapper>
    <ButtonWrapper>
      <button
        type="button"
        className="next-btn"
        onClick={() => handleSelectedIndex(2)}
      >
        Next
      </button>
    </ButtonWrapper>
  </>

);

IncidentReviewOne.propTypes = {
  handleSelectedIndex: PropTypes.func.isRequired,
  editReviewData: PropTypes.shape.isRequired,
  handleChange: PropTypes.func.isRequired,
  reviewData: PropTypes.shape.isRequired,
  handleChangeEditReview: PropTypes.func.isRequired,
  incidentId: PropTypes.string.isRequired
};

export default IncidentReviewOne;
