import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserRoutes from './UserRoutes';

const InterRoutes = ({ setHouseChanged, setHouseListChanged }) => (
  <>
    <Route path="/" render={() => <UserRoutes setHouseListChanged={setHouseListChanged} setHouseChanged={setHouseChanged} />} />
  </>
);

InterRoutes.propTypes = {
  setHouseChanged: PropTypes.func.isRequired,
  setHouseListChanged: PropTypes.func.isRequired
};

export default InterRoutes;
