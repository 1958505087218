import React from 'react';
import PropTypes from 'prop-types';
import StaffIncidents from '../components/StaffIncidents/StaffIncidents';
import HomeNoticeBoard from '../components/NoticeBoard/HomeNoticeBoard';

const Home = ({ setHouseChanged }) => {
  const isHouse = JSON.parse(localStorage.getItem('user-details')).houseCode;
  return (
    <div>
      {
        !isHouse ? <StaffIncidents setHouseChanged={setHouseChanged} /> : <HomeNoticeBoard />
      }
    </div>

  );
};

Home.propTypes = {
  setHouseChanged: PropTypes.func.isRequired
};

export default Home;
