/** @format */

import styled from 'styled-components';

export const ReviewWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  flex-wrap: wrap;
  .hr{
    height: 2px;
    width: 99%;
    margin-right: 1rem;
    color: rgba(116, 188, 169, 1);
  }
  .ml-sm{
	margin-left: 0.25rem;
  }
  .review-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .date-picker-wrapper .react-datepicker-wrapper {
      width: 100%;
    }
    .date-picker-wrapper input {
      width: 100%;
      min-height: 38px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #f2e4d8;
    }
  }
  .review-row .input-like {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .mr {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .what-happend {
    margin-right: 0;
    margin-left: 1%;
  }
  .label {
    font-size: 12px;
    margin: 5px 7px;
  }
  .input,
  .input-like {
    padding: 0 8px;
    min-height: 38px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid #f2e4d8;
  }

  .input-like {
    padding: 4px 8px;
    margin-bottom: 0;
  }
  .flex {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    .incident_num {
      color: ${({ theme }) => theme.colors.od_secondary};
      padding: 0;
      margin: 0 7px;
    }
  }
  .w-100 {
    flex: 0 0 100%;
  }
  .w-25 {
    flex: 0 0 24%;
  }
  .w-50 {
    flex: 0 0 49.5%;
  }

  .container {
    display: block;
    position: relative;
    width: fit-content;
    margin: 0.5rem;
    padding-left: 2rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container > span:first-child {
    position: relative;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    border-radius: 50%;
  }

  /* On mouse-over, add a primary background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a primary background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 2.8rem;
  button {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 12px 10px;
    margin: 0 15px;
  }
  button:disabled{
	opacity: 0.6;
  }
  .next-btn,
  .modify-review-btn,
  .submit-btn {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
  }
  .close-incident-btn {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid rgba(67, 90, 173, 0.2);
  }
`;
