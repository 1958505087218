import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul{
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li{
      margin: 0;
    }

    li:last-child{
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img{
      width: 8px;
    }
    img.home-icon{
      width: 30px;
    }
    img.caret-icon{
      margin: 0 1rem;
    }
  }
`;

export const HouseWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
      width: 12px !important;
      height: auto;
    }
`;

export const HouseManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const HouseList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const HouseCard = styled.div`
  margin: 1rem 2rem 1rem 0;
  width: 27rem;
  border: 1px solid #D1DEDE;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 19px;

  .house-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    border-bottom: 2px solid #D1DEDE;

    p {
      font-size: 16px;
      font-weight: 700;
      margin: auto 0;
    }

    button {
      border-radius: 5px;
      margin: 2px 0;
      padding: 3px 10px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};

      img {
        margin: 0;
        width: 15px !important;
      }
    }
    .upload-btn{
      margin-right: 1rem;
      color: ${({ theme }) => theme.colors.od_secondary};
      svg{
        width: 20px;
      }
    }
    .delete-btn{
      margin: 0 0 0.2rem 1rem;
      padding: 0;
      img{
        width: 30px !important;
      }
    }
  }

  .house-body {
    padding: 2rem 3rem;

    img {
      width: 25px !important;
      margin: 0.5rem 1rem;
    }

    div {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;
    }
  }
}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.blank{
    width: 47%;
  }
  input {
    border: none;
    width: 47%;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.4rem 0.5rem;
  }
  input:focus{
    outline: none;
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
`;
