/** @format */

import React, { useState } from 'react';
import {
  Spinner
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  Wrapper, LowerCurve, UpperCurve, FormContainer,
  InputRow, CredentialIcon, ErrorMsg, Button
} from './index.style';
import srcLogo from '../../assets/logo/one_doorway_logo.png';
import upperCurve from '../../assets/logo/upper_curve.svg';
import lowerCurve from '../../assets/logo/lower_curve.svg';
import emailLogo from '../../assets/logo/email_logo.svg';
import backIcon from '../../assets/logo/back.svg';
import { sendForgotPasswordLink } from '../../services/result.services';

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [validRespMsg, setValidRespMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [validityMsgForEmail, setValidityMsgForEmail] = useState('');

  const checkValidity = (value) => {
    setValidRespMsg('');
    if (successMsg) {
      setSuccessMsg('');
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
      setValidityMsgForEmail('Please enter a valid email');
    } else {
      setValidityMsgForEmail('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    checkValidity(value);
  };

  const forgotPasswordHandler = async () => {
    setLoading(true);
    const resp = await sendForgotPasswordLink({ email });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setSuccessMsg('Check your mail to reset the password!');
      setValidityMsgForEmail('');
      setEmail('');
    } else if (resp.status === 400) {
      setLoading(false);
      setValidRespMsg('User not found with this Email Id!');
    } else {
      setLoading(false);
      setValidRespMsg('Something went wrong!, please try again');
      setEmail('');
      setValidityMsgForEmail('');
    }
  };

  return (
    <Wrapper>
      <UpperCurve src={upperCurve} />
      <LowerCurve src={lowerCurve} />
      <div className="form-wrapper">
        <div className="w-100 flex">
          <img data-testid="logo-icon" className="logo" src={srcLogo} alt="one doorway logo" />
        </div>
        <FormContainer>
          <button className="button back-btn" type="button" onClick={() => history.goBack()}>
            <img data-testid="back-icon" src={backIcon} alt="back icon" />
            Back
          </button>
          <h3 data-testid="forgot-Password" className="title">Forgot Password ?</h3>
          <p className="sub-title">
            <span className="highlighted">Don’t worry</span>
            , happens to the best of us.
          </p>
          <p className="label">  Email </p>
          <InputRow>
            <label htmlFor="email">
              <CredentialIcon src={emailLogo} />
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email Id"
              value={email}
              data-testid="email"
              onChange={handleChange}
            />
          </InputRow>
          <Button
            type="submit"
            data-testid="forgot-pass"
            onClick={forgotPasswordHandler}
            disabled={
              email === ''
              || validityMsgForEmail !== ''
              || validRespMsg !== ''
              || loading
            }
          >
            <span>
              {loading ? 'Sending' : 'Send Recovery Email'}
            </span>
            {loading && (
              <span className="spinner">
                <Spinner animation="border" variant="primary" />
              </span>
            )}
          </Button>
          <ErrorMsg color="warning">
            {validityMsgForEmail}
          </ErrorMsg>
          <ErrorMsg color="warning">
            {validRespMsg}
          </ErrorMsg>
          <ErrorMsg color="success">
            {successMsg}
          </ErrorMsg>
        </FormContainer>
      </div>
    </Wrapper>
  );
};

export default ForgotPassword;
