/** @format */

import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const StatusContainer = styled.div`
  span {
    text-align: center;
    display: inline-block;
    padding: 0.5rem 0;
    width: 90px;
    border-radius: 7px;
  }
  .raised {
    color: ${({ theme }) => theme.colors.od_primary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
  }
  .closed {
    color: ${({ theme }) => theme.colors.od_secondary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .reviewed {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0 0 0.2rem 1.5rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const AddIncident = styled.button`
  position: absolute;
  top: 205px;
  right: 25px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
`;

export const IncidentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.incident_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 48px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  .input-like::placeholder{
    color: #9F9F9F;
    font-size: 16px;
  }
  .input::placeholder{
    color: #9F9F9F;
    font-size: 16px;
  }
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-33{
    flex: 0 0 33%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;
export const Input = styled.input`
padding: 0 8px;
min-height: 48px;
border-radius: 5px;
outline: 0;
// border: 1px solid red;
.placeholder{
  color: '#E86B55';
}
border: ${({ error }) => (error ? '1px solid red' : '1px solid #F2E4D8')};
`;

export const RowReviewValidation = styled.div`
  width:80%;
  color:red;
  font-size:16px;
`;
export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;
