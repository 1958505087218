/** @format */

import React, { useState, useEffect } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import {
  ClientReportsWrapper,
  ClientReportsManagementHeader,
  ReviewWrapper,
  ShowReportsButtonWrapper,
  ReportWrapper,
  Heading,
  HeadingBoxWrapper,
  NightHeadingBoxWrapper
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import SelectContainer from '../../components/SelectContainer';
import NightChecklist from './Reports/NightChecklist';
import { getHouseList, getClientListByHouse, getFormsForClient } from '../../services/result.services';
import FoodDrinkTracker from './Reports/FoodDrinkTracker';
import SleepTracker from './Reports/SleepTracker';
import BowelMovementTracker from './Reports/BowelMovementTracker';
import { getUserDetails } from '../../helpers/utils';

const AdminClients = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [listHouses, setListHouses] = useState([]);
  const [formData, setFormData] = useState({
    houseCode: '',
    clientName: '',
    form: ''
  });
  const [clientList, setClientList] = useState([]);
  const [clientFormsData, setClientFormsData] = useState([]);
  const [reportDate, setReportDate] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelectChange = (e) => {
    const { name, value } = e;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClearSelection = () => {
    setFormData((prevState) => ({
      ...prevState,
      houseCode: '',
      clientName: '',
      form: ''
    }));
    setReportDate('');
  };

  const getHousesHandler = async () => {
    setLoading(true);
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses && filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const housesList = filteredHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`,
        name: 'houseCode'
      }));
      setListHouses(housesList);
      setLoading(false);
    } else {
      setListHouses([]);
      setLoading(false);
    }
  };

  const getClientsByHouse = async () => {
    setLoading(true);
    if (!formData.houseCode) {
      setLoading(false);
      return;
    }
    const resp = await getClientListByHouse(formData.houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientName',
        id: `${ele.id}`
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getClientFormsData = async () => {
    setLoading(true);
    if (!formData.clientName) {
      setLoading(false);
      return;
    }
    const resp = await getFormsForClient(
      clientList
      && clientList.find((c) => c.value === formData.clientName).id
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const clientForms = resp.data && resp.data.map((clientForm) => {
        if (clientForm.lookupId === 75) {
          return {
            label: 'Food and Drinks Diary',
            value: 'Food and Drinks Diary',
            name: 'form'
          };
        } if (clientForm.lookupId === 76) {
          return {
            label: 'Sleep Tracker',
            value: 'Sleep Tracker',
            name: 'form'
          };
        } if (clientForm.lookupId === 77) {
          return {
            label: 'Nightly Tasks Checklist',
            value: 'Nightly Tasks Checklist',
            name: 'form'
          };
        } if (clientForm.lookupId === 104) {
          return {
            label: 'Bowel Movement Tracker',
            value: 'Bowel Movement Tracker',
            name: 'form'
          };
        }
        return clientForm;
      });
      setClientFormsData(clientForms);
      setLoading(false);
    } else {
      setClientFormsData([]);
      setLoading(false);
    }
  };

  const handleShowReports = () => {
    setShowForm(true);
  };

  useEffect(() => {
    getHousesHandler();
  }, []);

  useEffect(() => {
    getClientsByHouse();
    setFormData((prev) => ({
      ...prev,
      clientName: '',
      form: ''
    }));
    setReportDate('');
    getClientFormsData();
  }, [formData.houseCode]);

  useEffect(() => {
    getClientFormsData();
    if (formData.clientName && formData.form) {
      setFormData((prev) => ({
        ...prev,
        form: ''
      }));
      setReportDate('');
    }
  }, [formData.clientName]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Client Reports </span>
          </li>
        </ul>
      </BreadCrumb>
      <ClientReportsWrapper>
        <ClientReportsManagementHeader>
          <h2>CLIENT REPORTS</h2>
        </ClientReportsManagementHeader>
        <div>
          <ReviewWrapper>
            <div className="review-row">
              <div className="w-25 mr" data-testid="select-container">
                <p className="label">
                  Select House Code
                </p>

                <SelectContainer
                  className="select-container"
                  classNamePrefix="select-container"
                  options={listHouses.length > 0 && listHouses}
                  placeholderText="House Code"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: formData.houseCode,
                    label: formData.houseCode
                  }}
                  disabled={!listHouses.length}
                />
              </div>
              <div className="w-25 mr">
                <p className="label">
                  Select Client Name
                </p>

                <SelectContainer
                  options={clientList.length > 0 && clientList}
                  placeholderText="Client Name"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: formData.clientName,
                    label: formData.clientName
                  }}
                  disabled={!formData.houseCode || !clientList.length}
                />
              </div>

              <div className="w-25 mr">
                <p className="label">
                  Select Form Type
                </p>

                <SelectContainer
                  options={clientFormsData.length > 0 && clientFormsData}
                  placeholderText="Form"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: formData.form,
                    label: formData.form
                  }}
                  disabled={!formData.clientName || !clientFormsData.length}
                />
              </div>

              <div className="w-25 date-picker-wrapper">
                <p className="label">
                  Select Date
                </p>

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Report date"
                  selected={reportDate}
                  onChange={(date) => setReportDate(date)}
                  disabled={!formData.form}
                />
              </div>
            </div>
          </ReviewWrapper>
          <ShowReportsButtonWrapper>
            <button
              type="button"
              className="submit-btn"
              onClick={handleClearSelection}
            >
              Clear Selection
            </button>
            <button
              type="button"
              className="submit-btn"
              disabled={!reportDate}
              onClick={handleShowReports}
            >
              Show Reports
            </button>
          </ShowReportsButtonWrapper>
        </div>
        {
            showForm && reportDate
              ? (
                <>
                  <NightHeadingBoxWrapper>
                    <HeadingBoxWrapper>
                      <Heading>
                        {formData.form}
                      </Heading>
                    </HeadingBoxWrapper>
                  </NightHeadingBoxWrapper>
                  {
                    formData.form === 'Nightly Tasks Checklist'
                    && (
                    <ReportWrapper>
                      <NightChecklist
                        clientId={
                      clientList.length > 0
                       && clientList.find((c) => c.value === formData.clientName)
                       && clientList.find((c) => c.value === formData.clientName).id
                      }
                        reportDate={reportDate}
                      />
                    </ReportWrapper>
                    )
                  }
                  {
                    formData.form === 'Food and Drinks Diary'
                    && (
                    <ReportWrapper>
                      <FoodDrinkTracker
                        clientId={
                      clientList.length > 0
                       && clientList.find((c) => c.value === formData.clientName)
                       && clientList.find((c) => c.value === formData.clientName).id
                      }
                        reportDate={reportDate}
                      />
                    </ReportWrapper>
                    )
                  }
                  {
                    formData.form === 'Sleep Tracker'
                    && (
                    <ReportWrapper>
                      <SleepTracker
                        clientId={
                      clientList.length > 0
                       && clientList.find((c) => c.value === formData.clientName)
                       && clientList.find((c) => c.value === formData.clientName).id
                      }
                        reportDate={reportDate}
                      />
                    </ReportWrapper>
                    )
                  }
                  {
                    formData.form === 'Bowel Movement Tracker'
                    && (
                    <ReportWrapper>
                      <BowelMovementTracker
                        clientId={
                      clientList.length > 0
                       && clientList.find((c) => c.value === formData.clientName)
                       && clientList.find((c) => c.value === formData.clientName).id
                      }
                        selectedDate={reportDate}
                      />
                    </ReportWrapper>
                    )
                  }
                </>
              ) : null
          }
      </ClientReportsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminClients;
