/** @format */
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const UsersWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
      width: 12px !important;
      height: auto;
    }
`;

export const UserManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }
  div.bg{
    width: 100%;
  }

  input:focus{
    outline: none;
  }
`;

export const SettingsModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label, label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper{
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      .icon{
        width: 25px;
        margin-right: 10px;
      }
    }
  }
 
  select:invalid {
    color: gray;
  }

  select:focus{
    outline: none;
  }
`;

export const SearchWrapper = styled.div`
  position:relative;
  padding: 12px 22px;
  margin-top: 5px;
  margin-bottom:-25px;
  display: flex;
  justify-content: start;
`;

export const SearchInputWrapper = styled.div`
  position:relative;
  padding: 12px 22px;
  margin-top:5px;
  margin-bottom:-25px;
  span {
    position:absolute;
    margin-left:12px;
    margin-top:5px;
  }

  span svg {
    color: ${({ theme }) => theme.colors.od_bg_secondary};
  }

  input {
    width:325px;
    border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
    height:45px;
    padding-left:38px;
    border-radius:5px;
    font-size:${({ theme }) => theme.fonts.font_size.normal_font};
    &::placeholder {
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
  }
  input:focus {
    outline: none;
  }
`;

export const Pagination = styled(ReactPaginate).attrs({
  // we can redifine classes here, if we want.
  activeClassName: 'active' // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  margin: 0 auto;
  max-width: 800px;
  margin-top: 1rem;

  li a {
    border-radius: 7px;
    padding: 0.2rem 1rem;
    margin: 0.3rem;
    border: gray 1px solid;
    cursor: pointer;
    text-decoration: none;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: ${({ theme }) => theme.colors.od_secondary};
  }
  li.active a {
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;
