/** @format */

import React, { useState, useEffect } from 'react';
import { AiFillFolder } from 'react-icons/ai';
import { Spinner, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import ClientSupportDocs from '../ClientSupportDocs/ClientSupportDocs';
import {
  OuterWrapper, ClientFolderWrapper, BoxWrapper,
  Box, FolderButton, FolderIcon, FormWrapper,
  AddFolder, EditRow, EditButton
} from './index.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import CustomModal from '../CustomModal';
import AddIcon from '../../assets/logo/add_icon.svg';
import editIcon from '../../assets/logo/edit.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import { getFolderList, createFolder, updateFolder } from '../../services/result.services';
import { getUserDetails } from '../../helpers/utils';
import NoData from '../NoData';

const ClientSupportFolders = () => {
  const location = useLocation();
  const clientId = location.pathname.split('/')[2];
  const [folderList, setFolderList] = useState([]);
  const [showCustomModalAddFolder, setShowCustomModalAddFolder] = useState(false);
  const [showCustomModalUpdateFolder, setShowCustomModalUpdateFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderDetails, setFolderDetails] = useState({
    folderName: '',
    status: 'ACTIVE'
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const resetFolderDetails = () => {
    setFolderDetails({
      folderName: '',
      status: 'ACTIVE'
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFolderDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-folder') {
      setShowCustomModalAddFolder(false);
    } else if (operation === 'update-folder') {
      setShowCustomModalUpdateFolder(false);
    }
  };

  const handleAdd = () => {
    resetFolderDetails();
    setShowCustomModalAddFolder(true);
  };

  const handleUpdate = (id) => {
    const folderToBeUpdated = folderList.find((folder) => folder.id === id);
    setFolderDetails(folderToBeUpdated);
    setShowCustomModalUpdateFolder(true);
  };

  const handleFolderClicked = (id) => {
    const folderClicked = folderList.find((folder) => folder.id === id);
    setSelectedFolder(folderClicked);
  };

  const resetFolderSelection = () => {
    setSelectedFolder(null);
  };

  const getFolderListHandler = async () => {
    setLoading(true);
    const resp = await getFolderList(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setFolderList([...resp.data.filter((contact) => contact.status === 'ACTIVE')
        .sort((a, b) => a.id - b.id)]);
    } else {
      setLoading(false);
    }
  };

  const addFolderHandler = async () => {
    setLoading(true);
    const resp = await createFolder({
      ...folderDetails,
      clientId,
      lastUpdatedBy: getUserDetails().firstName
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Folder created successfully!',
        icon: 'success',
        timer: 3000
      });
      handleCloseCustomModal('add-folder');
      getFolderListHandler();
      resetFolderDetails();
    } else if (resp.status === 400 && !resp.data.success) {
      setLoading(false);
      handleCloseCustomModal('add-folder');
      swal({
        title: resp.data.message,
        icon: 'error',
        timer: 3000
      });
    } else {
      setLoading(false);
      handleCloseCustomModal('add-folder');
      resetFolderDetails();
      swal({
        title: 'Folder creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const updateFolderHandler = async () => {
    setLoading(true);
    const resp = await updateFolder({
      ...folderDetails,
      clientId,
      lastUpdatedBy: getUserDetails().firstName
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Folder updated successfully!',
        icon: 'success',
        timer: 3000
      });
      handleCloseCustomModal('update-folder');
      getFolderListHandler();
      resetFolderDetails();
    } else if (resp.status === 400 && !resp.data.success) {
      setLoading(false);
      handleCloseCustomModal('update-folder');
      swal({
        title: resp.data.message,
        icon: 'error',
        timer: 3000
      });
    } else {
      setLoading(false);
      handleCloseCustomModal('update-folder');
      resetFolderDetails();
      swal({
        title: 'Folder updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleDeleteFolder = async (folder) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateFolder(
        {
          ...folder,
          status: 'INACTIVE',
          lastUpdatedBy: getUserDetails().firstName
        }
      );
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Folder deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getFolderListHandler();
      } else {
        setLoading(false);
        swal({
          title: 'Folder deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    getFolderListHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <OuterWrapper>
        {
          folderList.length === 0 && <NoData />
        }
        {
          selectedFolder ? (
            <ClientSupportDocs
              clientId={clientId}
              selectedFolder={selectedFolder}
              resetFolderSelection={resetFolderSelection}
            />
          ) : (
            <>
              {
                (folderList.length < 5) && (
                  <AddFolder onClick={() => handleAdd()}>
                    <img src={AddIcon} alt="add icon" />
                  </AddFolder>
                )
              }
              <ClientFolderWrapper>
                {folderList.map((folder) => (
                  <BoxWrapper key={folder.id}>
                    <Box>
                      <EditRow>
                        <EditButton onClick={() => handleUpdate(folder.id)}>
                          <img src={editIcon} alt="edit icon" />
                        </EditButton>
                        <EditButton onClick={() => handleDeleteFolder(folder)}>
                          <img className="cross" src={deleteIcon} alt="delete icon" />
                        </EditButton>
                      </EditRow>
                      <FolderIcon onClick={() => handleFolderClicked(folder.id)}>
                        <AiFillFolder />
                      </FolderIcon>
                      <FolderButton
                        onClick={() => handleFolderClicked(folder.id)}
                      >
                        {folder.folderName}
                      </FolderButton>
                    </Box>
                  </BoxWrapper>
                ))}
              </ClientFolderWrapper>
            </>
          )
        }
      </OuterWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddFolder}
        setClose={() => {
          handleCloseCustomModal('add-folder');
        }}
        title="Add Folder"
        width="580px"
        onSubmit={addFolderHandler}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="folder_name">Folder Name</label>
              <input
                id="folder_name"
                type="text"
                value={folderDetails.folderName}
                name="folderName"
                placeholder="Folder Name"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateFolder}
        setClose={() => {
          handleCloseCustomModal('update-folder');
        }}
        title="Update Folder"
        width="580px"
        onSubmit={updateFolderHandler}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="name">Folder Name</label>
              <input
                id="name"
                type="text"
                value={folderDetails.folderName}
                name="folderName"
                placeholder="Folder Name"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
    </>
  );
};

export default ClientSupportFolders;
