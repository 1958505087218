import styled from 'styled-components';

export const TableContainer = styled.div`
  margin: 2.5rem 2.5rem 1rem;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    min-height: calc(58vh - 120px);
    max-height: calc(60vh - 120px);
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr {
      border: 1px solid #E6E9F4;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 7px 5px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
    tr:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;
