import styled from 'styled-components';

export const IncidentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.incident_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{      
	  padding: 4px 8px;
	  margin-bottom: 0;
	}	
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-33{
	flex: 0 0 32.5%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  button{
	border: none;
	outline: none;
	border-radius: 10px;
    padding: 12px 10px;
    margin: 0 15px;
  }
  button:disabled{
	opacity: 0.6;
  }
  .review-btn{
	color: ${({ theme }) => theme.colors.od_extra_color};
	background-color: ${({ theme }) => theme.colors.od_neutral};
	border: 1px solid rgba(67, 90, 173, 0.2);
  }
  .save-incident-btn, .escalate-btn{
	color: ${({ theme }) => theme.colors.od_neutral};
	background-color: ${({ theme }) => theme.colors.od_secondary};  
  }
`;

export const SelectAdminWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
	margin-bottom: 0;
  }
  select{
	width: 60%;
	outline: none;
	background-color: ${({ theme }) => theme.colors.od_neutral};
	padding: 0.5rem;
	margin: 0.4rem 0.5rem;
	border-radius: 5px;
  }
`;
