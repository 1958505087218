/** @format */
import styled from 'styled-components';
import SelectContainer from '../../components/SelectContainer';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const ContactsWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height: calc(75vh - 120px);
  img {
    width: 12px;
    height: auto;
  }
`;

export const ContactManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const ContactList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ContactCard = styled.div`
  margin: 1rem 2rem 1rem 0;
  width: 27rem;
  border: 1px solid #D1DEDE;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 19px;

  .contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    border-bottom: 2px solid #D1DEDE;

    p {
      font-size: 16px;
      font-weight: 700;
      margin: auto 0;
    }

    button {
      border-radius: 5px;
      margin: 2px 0;
      padding: 2px 10px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};;

      img {
        margin: 0;
      }
    }
    .delete-btn{
      margin-left: 1rem;
      padding: 0;
      color: ${({ theme }) => theme.colors.od_secondary};
      img{
        width: 28px !important;
      }
    }
  }

  .contact-body {
    padding: 1rem 1.5rem;

    img {
      width: 25px;
      margin: 0.5rem 1rem;
    }
    .address-icon{
      width: 28px;
    }

    div {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;
    }
    .note-icon{
	    height: 27px;
	  }
  }
}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.select-house {
    margin-left: 0.4rem;
    width: 100.4%;
  }

  div.input-with-label {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  div.input-with-full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom: 3px;
    }
    select {
      width: 100%;
      padding: 0.5rem 0;
      border-radius: 5px;
      border: none;
    }
  }

  div.form-control-wrapper {
    width: 49%;
    display: flex;
    justify-content: space-between;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type='date'] {
    color: gray;
  }

  select:invalid {
    color: gray;
  }

  input:focus,
  select:focus {
    outline: none;
  }
`;

export const StyledSelectContainer = styled(SelectContainer)``;
