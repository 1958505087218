/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import {
  getSleepTrackerNote, createSleepReport, updateSleepReport
} from '../../../services/result.services';
import { checkIfAdminOnly, getUserDetails } from '../../../helpers/utils';
import { slotDetailsObj } from '../../../lookups/lookups';
import {
  BackButton,
  HeadingBoxWrapper,
  ButtonsBox,
  Heading,
  NightHeadingBoxWrapper,
  HeadingsBoxWrapper,
  RowBoxWrapper,
  TimeBoxWrapper,
  NameBox,
  RowsWrapper,
  RoundedDot,
  DateBox
} from './index.style';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import tickIcon from '../../../assets/logo/tick.svg';
import timerIcon from '../../../assets/logo/timerIcon.svg';
import userIcon from '../../../assets/logo/userIcon.svg';

const SleepTracker = ({ setSelectedTracker }) => {
  const location = useLocation();
  const [isFreshForm, setIsFreshForm] = useState(true);
  const [slotDetails, setSlotDetails] = useState(slotDetailsObj);
  const [sleepTrackerData, setSleepTrackerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [reportId, setReportId] = useState();
  const clientId = location.pathname.split('/')[2];
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getTodayDate = (para) => {
    const date = moment();
    const time = Number(moment().format('HHmm'));
    if (time > 0 && time < 1159) {
      if (para === 'forApi') {
        return moment(date).subtract(1, 'days').format('YYYY-MM-DD');
      }
      if (para === 'forDisplay') {
        return moment(date).subtract(1, 'days').format('DD/MM/YYYY');
      }
    }
    if (para === 'forApi') {
      return date.format('YYYY-MM-DD');
    }
    return date.format('DD/MM/YYYY');
  };

  function handleShowUserName(reported) {
    if (isFreshForm) {
      if (slotDetails[reported]) {
        return getUserDetails() && getUserDetails().firstName;
      }
      return '';
    }

    if (sleepTrackerData && !sleepTrackerData[reported]) {
      return '';
    }
    if (sleepTrackerData && sleepTrackerData[`${reported.replace('UpdatedBy', '')}Slot`] === '') {
      sleepTrackerData[reported] = '';
      return '';
    }
    return sleepTrackerData[reported];
  }

  const handleChange = (e) => {
    const {
      name, value, type, checked
    } = e.target;
    if (isFreshForm) {
      if (type === 'checkbox') {
        if (slotDetails[`${name.replace('UpdatedBy', '')}Slot`] === '') {
          swal({
            title: 'Comment not entered',
            icon: 'error'
          });
        } else {
          setSlotDetails((prevState) => ({
            ...prevState,
            [name]: checked ? getUserDetails() && getUserDetails().firstName : ''
          }));
        }
      } else {
        setSlotDetails((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    } else if (type === 'checkbox') {
      if (sleepTrackerData[`${name.replace('UpdatedBy', '')}Slot`] === '') {
        swal({
          title: 'Comment not entered',
          icon: 'error'
        });
      } else {
        setSleepTrackerData((prevState) => ({
          ...prevState,
          [name]: checked ? getUserDetails() && getUserDetails().firstName : ''
        }));
      }
    } else {
      setSleepTrackerData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const getSleepTrackerNoteHandler = async (payload) => {
    setSleepTrackerData({});
    setSlotDetails(slotDetailsObj);
    setLoading(true);
    const resp = await getSleepTrackerNote(payload);
    if (!resp) return;
    if (resp.status === 200) {
      setLoading(false);
      setIsFreshForm(false);
      setSleepTrackerData(resp.data);
      setReportId(resp.data.id);
    } else {
      setLoading(false);
      setIsFreshForm(true);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createSleepReportHandler = async (payload) => {
    setLoading(true);
    const resp = await createSleepReport(payload);
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Report Created Successfully!',
        icon: 'success',
        timer: 3000
      });
      setSleepTrackerData({});
      getSleepTrackerNoteHandler({ clientId, date: selectedDate && moment(selectedDate).format('YYYY-MM-DD') });
    } else {
      setLoading(false);
      swal({
        title: 'Report Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const updateSleepReportHandler = async (payload) => {
    setLoading(true);
    const resp = await updateSleepReport(payload);
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Report Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      setSlotDetails(slotDetailsObj);
      getSleepTrackerNoteHandler({ clientId, date: selectedDate && moment(selectedDate).format('YYYY-MM-DD') });
    } else {
      setLoading(false);
      swal({
        title: 'Report Updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleSaveValidation = () => {
    let para;
    if (isFreshForm) {
      para = slotDetails;
    } else {
      para = Object.keys(sleepTrackerData).length > 0 && sleepTrackerData;
    }
    if (Object.keys(para).length > 0 && para.firstSlot !== '' && para.firstUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.secondSlot !== '' && para.secondUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.thirdSlot !== '' && para.thirdUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.fourthSlot !== '' && para.fourthUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.fifthSlot !== '' && para.fifthUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.sixthSlot !== '' && para.sixthUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.seventhSlot !== '' && para.seventhUpdatedBy === '') {
      return false;
    }
    if (Object.keys(para).length > 0 && para.eighthSlot !== '' && para.eighthUpdatedBy === '') {
      return false;
    }
    return true;
  };

  const handleSaveForm = () => {
    if (!handleSaveValidation()) {
      swal({
        title: 'Comment not signed!',
        icon: 'error'
      });
      return;
    }
    if (isFreshForm) {
      createSleepReportHandler({
        ...slotDetails,
        clientId,
        reportDate: selectedDate && moment(selectedDate).format('YYYY-MM-DD')
      });
    } else {
      updateSleepReportHandler(
        {
          ...sleepTrackerData,
          id: reportId && reportId,
          currentLastUpdatedAt: sleepTrackerData && sleepTrackerData.lastUpdatedAt
        }
      );
    }
  };

  useEffect(() => {
    setSelectedDate(getTodayDate('forApi') && new Date(getTodayDate('forApi')));
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    setSleepTrackerData({});
    setSlotDetails(slotDetailsObj);
    getSleepTrackerNoteHandler({ clientId, date: selectedDate && moment(selectedDate).format('YYYY-MM-DD') });
    if (sleepTrackerData && Object.keys(sleepTrackerData).length > 0) {
      setIsFreshForm(true);
    } else {
      setIsFreshForm(false);
    }
  }, [clientId]);

  useEffect(async () => {
    setSleepTrackerData({});
    setSlotDetails(slotDetailsObj);
    await getSleepTrackerNoteHandler({ clientId, date: selectedDate && moment(selectedDate).format('YYYY-MM-DD') });
  }, [selectedDate]);

  return (
    <>
      <NightHeadingBoxWrapper>
        <HeadingBoxWrapper>
          <Heading>
            Sleep Tracker
          </Heading>
          <DateBox>
            <DatePicker
              timeFormat="HH:mm"
              className="input"
              dateFormat="dd/MM/yyyy"
              minDate={new Date(moment(getTodayDate('forApi')).subtract(1, 'days'))}
              maxDate={new Date(getTodayDate('forApi'))}
              placeholderText="Select date"
              selected={selectedDate}
              onChange={(selectedD) => setSelectedDate(selectedD)}
            />
          </DateBox>
        </HeadingBoxWrapper>
        <ButtonsBox>
          <BackButton type="button" onClick={() => setSelectedTracker(0)}>Back</BackButton>
          <BackButton type="button" onClick={handleSaveForm} disabled={checkIfAdminOnly() && checkIfAdminOnly()}>
            <img src={tickIcon} alt="tickIcon" />
            Save
          </BackButton>
        </ButtonsBox>
      </NightHeadingBoxWrapper>
      <HeadingsBoxWrapper>
        <div className="first-heading">TIME</div>
        <div className="second-heading">COMMENTS</div>
        <div className="third-heading">SIGN</div>
      </HeadingsBoxWrapper>
      <RowsWrapper>
        <div className="dotted-lines" />
        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">06:00 Pm</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">08:00 Pm</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="firstSlot"
              onChange={handleChange}
              data-testid="firstSlot"
              value={isFreshForm ? slotDetails.firstSlot : sleepTrackerData
                    && sleepTrackerData.firstSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('firstUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="firstUpdatedBy"
                checked={isFreshForm ? slotDetails.firstUpdatedBy : sleepTrackerData
                          && sleepTrackerData.firstUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>
        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>
        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">08:00 Pm</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">10:00 Pm</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="secondSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.secondSlot : sleepTrackerData
                      && sleepTrackerData.secondSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('secondUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="secondUpdatedBy"
                checked={isFreshForm ? slotDetails.secondUpdatedBy : sleepTrackerData
                          && sleepTrackerData.secondUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">10:00 Pm</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">12:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="thirdSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.thirdSlot : sleepTrackerData
                      && sleepTrackerData.thirdSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('thirdUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="thirdUpdatedBy"
                checked={isFreshForm ? slotDetails.thirdUpdatedBy : sleepTrackerData
                          && sleepTrackerData.thirdUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">12:00 Am</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">02:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="fourthSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.fourthSlot : sleepTrackerData
                     && sleepTrackerData.fourthSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('fourthUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="fourthUpdatedBy"
                checked={isFreshForm ? slotDetails.fourthUpdatedBy : sleepTrackerData
                          && sleepTrackerData.fourthUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">02:00 Am</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">04:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="fifthSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.fifthSlot : sleepTrackerData
                    && sleepTrackerData.fifthSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('fifthUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="fifthUpdatedBy"
                checked={isFreshForm ? slotDetails.fifthUpdatedBy : sleepTrackerData
                          && sleepTrackerData.fifthUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">04:00 Am</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">06:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="sixthSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.sixthSlot : sleepTrackerData
                      && sleepTrackerData.sixthSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('sixthUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="sixthUpdatedBy"
                checked={isFreshForm ? slotDetails.sixthUpdatedBy : sleepTrackerData
                          && sleepTrackerData.sixthUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">06:00 Am</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">08:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="seventhSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.seventhSlot : sleepTrackerData
                      && sleepTrackerData.seventhSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('seventhUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="seventhUpdatedBy"
                checked={isFreshForm ? slotDetails.seventhUpdatedBy : sleepTrackerData
                          && sleepTrackerData.seventhUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>

        <RoundedDot>
          <div className="inside-roll" />
        </RoundedDot>

        <RowBoxWrapper>
          <div className="first-heading">
            <TimeBoxWrapper>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">08:00 Am</div>
              <div className="dots">...</div>
              <img src={timerIcon} alt="timer icon" />
              <div className="text">10:00 Am</div>
            </TimeBoxWrapper>
          </div>
          <div className="second-heading">
            <textarea
              name="eighthSlot"
              onChange={handleChange}
              value={isFreshForm ? slotDetails.eighthSlot : sleepTrackerData
                      && sleepTrackerData.eighthSlot}
              disabled={checkIfAdminOnly() && checkIfAdminOnly()}
            />
          </div>
          <div className="third-heading">
            <label
              className="container"
            >
              <NameBox>
                <div className="icon-name-wrapper">
                  <img className="icon" src={userIcon} alt="user icon" />
                </div>
                <span>
                  { handleShowUserName('eighthUpdatedBy')}
                </span>
              </NameBox>
              <input
                type="checkbox"
                onChange={handleChange}
                name="eighthUpdatedBy"
                checked={isFreshForm ? slotDetails.eighthUpdatedBy : sleepTrackerData
                          && sleepTrackerData.eighthUpdatedBy}
                disabled={checkIfAdminOnly() && checkIfAdminOnly()}
              />
              <span className="checkmark" />
            </label>
          </div>
        </RowBoxWrapper>
      </RowsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

SleepTracker.propTypes = {
  setSelectedTracker: PropTypes.func.isRequired
};

export default SleepTracker;
