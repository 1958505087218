import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CaseNoteRecordWrapper, FirstRow, TimingsBox } from './index.style';
import editIcon from '../../assets/logo/edit.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';

function CaseNoteRecord(props) {
  const {
    date,
    startTime,
    endTime,
    subject,
    content,
    openUpdateModal,
    deleteCaseNote
  } = props;
  return (
    <CaseNoteRecordWrapper>
      <div className="contact-header" data-testid="contact">
        <div>
          <button
            type="button"
            onClick={openUpdateModal}
          >
            <img src={editIcon} alt="edit icon" />
          </button>
          <button
            type="button"
            className="delete-btn"
            onClick={deleteCaseNote}
          >
            <img src={deleteIcon} alt="delete icon" />
          </button>
        </div>
      </div>
      <FirstRow>
        <TimingsBox>
          <div className="time">
            Date:
          </div>
          <span>
            {' '}
            {moment(date).format('DD MMM YYYY')}
            {' '}
          </span>
        </TimingsBox>
        <TimingsBox>
          <div className="start">
            Start Time:
          </div>
          <span>
            {' '}
            {moment(startTime).format('LT')}
            {' '}
          </span>
          <div className="start">
            End Time:
          </div>
          <span>
            {' '}
            {moment(endTime).format('LT')}
            {' '}
          </span>
        </TimingsBox>
      </FirstRow>
      <TimingsBox>
        <div className="time">
          Subject:
        </div>
        <span>
          {' '}
          {subject}
          {' '}
        </span>
      </TimingsBox>
      <TimingsBox>
        <div className="time">
          Content:
        </div>
        <span><pre>{content}</pre></span>
      </TimingsBox>
    </CaseNoteRecordWrapper>
  );
}

CaseNoteRecord.propTypes = {
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  deleteCaseNote: PropTypes.func.isRequired
};

export default CaseNoteRecord;
