import styled from 'styled-components';

export const HandoverForm = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.house_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-33{
	flex: 0 0 32.5%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const HandoverWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height: calc(75vh - 120px);
  img {
      width: 12px;
      height: auto;
    }
`;

export const FormTitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;
    min-width: 90px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem;
  button{
	border: none;
	outline: none;
	border-radius: 10px;
  padding: 12px 10px;
  margin: 0 15px;
	min-width: 90px;
  }
  button:disabled{
	opacity: 0.6;
  }
  .save-btn {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;
    min-width: 90px;
  }
`;
