import styled from 'styled-components';

export const NotesTable = styled.table`
  border-collapse: unset;
  font-size: 18px;
  pre{
    margin-top: 10px;
    font-family: inherit;
    color: ${({ theme }) => theme.colors.od_table_color};
    white-space: pre-wrap;
  }
`;

export const TableHead = styled.thead`
  font-weight: 500;
  font-size: 17px;
  padding-right: 10px;

th {
  border: none;
}

tr {
  color: rgb(185, 174, 174);
  display: table;
  width: 95% !important;
  height:50px;
  table-layout: fixed;
  border-collapse: collapse;
  box-sizing: border-box;
  border-radius: 10px;
  margin:auto;
  border-collapse: separate;
}

tr > th {
  word-wrap: break-word;
  color: #38485F;
  font-size: 16px;
  padding-left:15px;
}

tr > th:first-child {
  width: calc(120px + 5px);
  vertical-align: middle;

}

tr > th:nth-child(2) {
  width: calc(140px + 5px);
  vertical-align: middle;
}

tr > th:nth-child(3) {
  width: calc(140px + 5px);
  vertical-align: middle;
}

tr > th:nth-child(4) {
  width: calc(7vw + 5px);
  vertical-align: middle;
}

tr > th:nth-child(5) { 
  width: auto;
  vertical-align: middle;
}

tr > th:nth-child(6) {
  width:50px;
  vertical-align: middle;
}

tr > th:nth-child(7) {
  width: 80px;
  vertical-align: middle;
}
tr > th:nth-child(8) {
  width: 0px;
  vertical-align: middle;
}

`;

export const TableBody = styled.tbody`
  min-height: calc(58vh - 120px);
  max-height: calc(60vh - 120px);
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  tr {
    color: rgb(185, 174, 174);
    display: table;
    width: 95% !important;
    height:64px;
    table-layout: fixed;
    border-collapse: separate;
    border: 1px solid #E6E9F4;
    box-sizing: border-box;
    border-radius: 10px;
    margin:auto;
    margin-bottom:5px;
    td {
      border: none;
    }
  }

  tr:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
    color:#2B45A4;
    font-size: 18px;
  }

  tr > td {
    word-wrap: break-word;
    color: #38485F;
    font-size: 16px;
    padding-left:15px;
  }

  tr > td:first-child {
    width: calc(120px + 5px);
    vertical-align: middle;
  }
  
  tr > td:nth-child(2) {
    width: calc(140px + 5px);
    vertical-align: middle;
  }

  tr > td:nth-child(3) {
    width: calc(140px + 5px);
    vertical-align: middle;
  }

  tr > td:nth-child(4) {
    width: calc(7vw + 5px);
    vertical-align: middle;
  }
  
  tr > td:nth-child(5) {
    width: auto;
    vertical-align: middle;
  }
  
  tr > td:nth-child(6) {
    width:50px;
    vertical-align: middle;
  }
  
  tr > td:nth-child(7) {
    width: 80px;
    vertical-align: middle;
  }
  tr > td:nth-child(8) {
    width: 0px;
    vertical-align: middle;
  }
`;
