import styled from 'styled-components';

export const StyledDocumentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`;

export const StyledDocument = styled.div`

`;
