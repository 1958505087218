/** @format */

import styled from 'styled-components';

export const BackButton = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  width: 77px;
  font-size: 15px;
  border: none;
  margin: 10px 0;
  height: ${(props) => (props.height ? props.height : '41px')};
  font-size: 13px;

  img {
    height: 8px;
    width: 11px;
    margin-right: 5px;
    margin-left: -5px;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

export const FoodHeadingBoxWrapper = styled.div`
  height: 73px;
  background: ${({ theme }) => theme.colors.od_primary_transparent};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const Heading = styled.h4`
  font-weight: 500;
  color: #74bca9;
  font-size: 18px;
  margin: 0 35px;
`;

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateBox = styled(Heading)`
  font-size: 16px;

  .input {
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
`;

export const ButtonBox = styled.div`
  display: flex;
  text-align: center;
  align-items: center;

  button {
    margin: 0 15px;
  }
`;

export const HeadingBoxWrapper = styled.div`
  display: flex;
  margin: 1rem 2rem;
  font-weight: 600;
  font-size: 16px;
  color: #000000;

  .first-heading {
    width: 180px;
  }
  .second-heading {
    flex-grow: 1;
  }
  .third-heading {
    flex-grow: 1;
  }
  .fourth-heading {
    flex-grow: 1;
  }
  .fifth-heading {
    width: 200px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  margin: 0 2rem;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  .first-heading {
    width: 180px;
    margin: 0 1.5rem;
  }
  .second-heading,
  .third-heading,
  .fourth-heading {
    padding: 0 0.75rem;
    flex-grow: 1;
  }
  .third-heading > span {
    padding-left: 2rem;
  }
  .fourth-heading > span {
    padding-left: 1.5rem;
  }
  .fifth-heading {
    width: 200px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowBoxWrapper = styled(HeadingBoxWrapper)`
  height: 115px;
  border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
  align-items: center;
  border-radius: 10px;
  margin-bottom: 5px;

  .first-heading {
    .title {
      color: ${({ theme }) => theme.colors.od_primary};
      margin-bottom: 0.5rem;
    }
    margin: 1rem 1.5rem;
    height: 115px;
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      padding: 0.25rem 0.5rem;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
    }
    input:focus {
      outline: none;
    }
  }
  .second-heading,
  .third-heading,
  .fourth-heading {
    height: 115px;
    flex-grow: 1;
    textarea {
      width: 100%;
      height: 115px;
      font-weight: 500;
      font-size: 16px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
      padding: 0.75rem;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }
  .third-heading {
    textarea {
      border-left: 0;
      border-right: 0;
    }
  }
  .fourth-heading {
    textarea {
      border-right: 0;
    }
  }
  .fifth-heading {
    height: 115px;
    border-right: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const NameBox = styled.span`
  display: flex;
  border: 0.2px solid rgba(134, 81, 137, 0.24);
  border-radius: 24px;
  width: 135px;
  padding: 3px;
  font-size: 12px;
  align-items: center;

  .icon-name-wrapper {
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.od_light_extra};
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;

    .icon {
      width: 12px;
      height: 12px;
      margin: auto;
    }
  }
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.od_text_color};
  }
`;
