/** @format */

import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import contactIcon from '../../assets/logo/contact.svg';
import addressIcon from '../../assets/logo/location.svg';
import emailIcon from '../../assets/logo/email_logo.svg';
import userIcon from '../../assets/logo/user.svg';
import noticeIcon from '../../assets/logo/notice.svg';
import {
  BreadCrumb, ContactManagementHeader, ContactsWrapper,
  ContactList, ContactCard
} from './index.style';
import { TableContainer } from '../../styledComponents/common/table.style';
import { getImportantContacts } from '../../services/result.services';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { getUserDetails } from '../../helpers/utils';
import NoData from '../../components/NoData';

const ImportantContacts = () => {
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getImportantContactsHandler = async (status) => {
    setLoading(true);
    const resp = await getImportantContacts({
      pageNumber: 0,
      pageSize: 1000,
      houseCode: getUserDetails().houseCode
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setContactList([...resp.data.filter((contact) => contact.status === status)
        .sort((a, b) => a.id - b.id)]);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getImportantContactsHandler('ACTIVE');
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Important Contacts </span>
          </li>
        </ul>
      </BreadCrumb>
      <ContactsWrapper>
        <ContactManagementHeader>
          <h2>IMPORTANT CONTACTS</h2>
        </ContactManagementHeader>
        <TableContainer>
          {
            contactList.length === 0 && (
              <NoData />
            )
          }
          <ContactList>
            {contactList
              && contactList.map((contact) => (
                <ContactCard key={contact.id}>
                  <div className="contact-header" data-testid="contact">
                    <p>
                      {contact.caption}
                    </p>
                  </div>
                  <div className="contact-body">
                    <div className="user-home-row">
                      <div>
                        <img src={userIcon} alt="contact person" />
                        <div>
                          {`${contact.firstName} ${contact.lastName}`}
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={emailIcon} alt="email icon" />
                      <div>
                        {contact.email}
                      </div>
                    </div>
                    {
                      (contact.address1
                      || contact.address2 || contact.city || contact.state || contact.zip)
                      && (
                        <div>
                          <img src={addressIcon} className="address-icon" alt="address icon" />
                          <div>
                            { contact.address1 && `${contact.address1}`}
                            { (contact.address1 && contact.address2) && ', '}
                            { contact.address2 && `${contact.address2}`}
                            { ((contact.address2 || contact.address1) && contact.city) && ', '}
                            { contact.city && `${contact.city}`}
                            { ((contact.city || contact.address2 || contact.address1) && contact.state) && ', '}
                            { contact.state && `${contact.state}`}
                            { ((contact.state || contact.city || contact.address2 || contact.address1) && contact.zip) && ', '}
                            { contact.zip && `${contact.zip}`}
                          </div>
                        </div>
                      )
                    }
                    <div>
                      <img src={contactIcon} alt="contact icon" />
                      <div>
                        {`${contact.phone1}${contact.phone2 && `, ${contact.phone2}`}`}
                      </div>
                    </div>
                    {
                      contact.note && (
                        <div>
                          <img className="note-icon" src={noticeIcon} alt="note icon" />
                          <div className="note">
                            {contact.notes}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </ContactCard>
              ))}
          </ContactList>
        </TableContainer>
      </ContactsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <LoadingModal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </LoadingModal.Body>
      </LoadingModal>
    </>
  );
};

export default ImportantContacts;
