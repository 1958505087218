/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import {
  BreadCrumb,
  EmergencyPlanWrapper,
  EmergencyPlanHeader,
  EmergencyContentWrapper,
  EmergencyBoxWrapper,
  PlansBoxWrapper,
  EmergencyBoxHeadingPlan,
  EmergencyBoxImage,
  EmergencyBoxHeadingHandout
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import homeIcon from '../../assets/logo/home.svg';
import plans from '../../assets/logo/plans.svg';
import uploadImage from '../../assets/logo/upload.svg';
import PdfViewPlans from '../../components/PdfViewPlans/PdfViewPlans';
import { getEmergencyPlanUrl } from '../../services/result.services';
import { getUserHouseCode } from '../../helpers/utils';

const AdminNotices = () => {
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [urlData, setUrlData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowPlanPdf = () => {
    setShowPdf(true);
    setPdfUrl('plan');
  };

  const handleShowHandoutPdf = () => {
    setShowPdf(true);
    setPdfUrl('handout');
  };

  const getEmergencyPlanUrlHandler = async (houseCode) => {
    setLoading(true);
    const resp = await getEmergencyPlanUrl(houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setUrlData(resp.data);
    } else {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    setShowPdf(false);
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getEmergencyPlanUrlHandler(getUserHouseCode() && getUserHouseCode());
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Emergency Plan </span>
          </li>
        </ul>
      </BreadCrumb>
      <EmergencyPlanWrapper>
        <EmergencyPlanHeader>
          <h2>Emergency Plan</h2>
          {
            showPdf ? <button type="button" onClick={handleGoBack}>Back</button> : null
          }
        </EmergencyPlanHeader>
        <EmergencyContentWrapper>
          {
            showPdf ? (
              <PdfViewPlans
                pdf={pdfUrl
                  && pdfUrl === 'plan'
                  ? urlData && urlData.emergencyPlanUrl
                  : urlData && urlData.emergencyHandoutUrl}
              />
            ) : (
              <EmergencyBoxWrapper>
                <PlansBoxWrapper>
                  <EmergencyBoxHeadingPlan>
                    Plans
                  </EmergencyBoxHeadingPlan>
                  <EmergencyBoxImage onClick={handleShowPlanPdf}>
                    <img src={plans} alt="upload" className="plans-img" />
                  </EmergencyBoxImage>
                </PlansBoxWrapper>
                <PlansBoxWrapper>
                  <EmergencyBoxHeadingHandout>
                    Handout
                    <a href={urlData && urlData.emergencyHandoutUrl} download>
                      <img src={uploadImage} alt="upload" className="upload-img" />
                    </a>
                  </EmergencyBoxHeadingHandout>
                  <EmergencyBoxImage onClick={handleShowHandoutPdf}>
                    <img src={plans} alt="plans" className="plans-img" />
                  </EmergencyBoxImage>
                </PlansBoxWrapper>
              </EmergencyBoxWrapper>
            )
          }

        </EmergencyContentWrapper>

      </EmergencyPlanWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminNotices;
