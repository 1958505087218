/** @format */

import styled from 'styled-components';

export const DocHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  background: ${({ theme }) => theme.colors.od_bg_primary};
`;

export const HeadingBox = styled.div`
  display: flex;
  justify: space-between;
  align-items: center;
  margin: auto 2rem;
  p {
    margin-top: 1rem;
    font-size: 20px;
    margin-right: 1rem;
  }
`;

export const PdfWrapper = styled.div`
  min-height: calc(58vh - 100px);
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
`;

export const Button = styled.button`
  min-width: 92px;
  height: 42px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  margin-right: 1rem;
  &:focus {
    outline: none;
  }
`;

export const UploadButton = styled(Button)`
  svg{
    color: ${({ theme }) => theme.colors.od_secondary};
    font-size: 25px;
  }
`;

export const DeleteButton = styled(Button)`
  min-width: auto;
  height: auto;
  margin-right: 0;
  padding: 0;
`;

export const PdfViewerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DocsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 1.5rem;
  .doc-box {
    width: 47%;
    margin: 1rem;
    padding: 0 1rem;
    height: 70px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.od_secondary};
      cursor: pointer;
    }
    .download-delete-box {
      display: flex;
      .doc-link {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.od_secondary};
        margin-right: 1.2rem;
        img {
          width: 30px;
          margin-top: 2px;
        }
      }
      .cross {
        cursor: pointer;
      }
    }
  }
`;

export const FolderIcon = styled.div`
  svg {
    width: 102px;
    height: auto;
    color: ${({ theme }) => theme.colors.od_secondary};
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.3rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }

  select:invalid {
    color: gray;
  }

  input, select:focus {
    outline: none;
  }
`;

export const AddFolder = styled.button`
  position: absolute;
  top: 255px;
  right: 30px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;
`;

export const ButtonBox = styled.div`
`;
