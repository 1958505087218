/** @format */

import React, { useState, useEffect } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import { FaSearch } from 'react-icons/fa';
import moment from 'moment';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  MiscellaneousNotesWrapper,
  SelectionWrapper,
  ShowNotesButtonWrapper,
  NotesListWrapper,
  AddNote,
  FormWrapper,
  SearchInputWrapper
} from './index.style';
import addIcon from '../../assets/logo/add_icon.svg';
import SelectContainer from '../../components/SelectContainer';
import MiscellaneousNotes from '../../components/MiscellaneousNote/MiscellaneousNote';
import NoData from '../../components/NoData';
import CustomModal from '../../components/CustomModal';
import { initialMiscellaneousNoteDetails } from '../../lookups/lookups';
import {
  createMiscellaneouNote, getMiscellaneousNotes, listMiscellaneousNoteCategories,
  updateMiscellaneousNote
} from '../../services/result.services';
import { getUserDetails } from '../../helpers/utils';

const AdminMiscellaneousNotes = () => {
  const requestObject = {
    pageNumber: 0,
    pageSize: 1000
  };
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [
    miscellaneousNoteDetails,
    setMiscellaneousNoteDetails
  ] = useState(initialMiscellaneousNoteDetails);
  const [category, setCategory] = useState({ name: '' });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [miscellaneousCategoryList, setMiscellaneousCategoryList] = useState([]);
  const [miscellaneousNoteList, setMiscellaneousNoteList] = useState([]);
  const [showMiscellaneousNotes, setShowMiscellaneousNotes] = useState(false);
  const [showCustomModalAddNote, setShowCustomModalAddNote] = useState(false);
  const [showCustomModalUpdateNote, setShowCustomModalUpdateNote] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelectChange = (event) => {
    const { value, id } = event;
    setCategory({ name: value, id });
  };

  const handleClearSelection = () => {
    setCategory({ name: '' });
    setStartDate('');
    setEndDate('');
  };

  const checkValidation = () => {
    if (validationMsg) {
      setValidationMsg('');
    }
    if (!miscellaneousNoteDetails.category.name) {
      setValidationMsg('Please select category');
      return false;
    }
    if (miscellaneousNoteDetails.subject === '') {
      setValidationMsg('Please enter subject');
      return false;
    }
    if (miscellaneousNoteDetails.content === '') {
      setValidationMsg('Please enter content');
      return false;
    }
    return true;
  };

  const getMiscellaneousNotesHandler = async (payload) => {
    setLoading(true);
    const resp = await getMiscellaneousNotes(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredNotDeletedNotes = resp.data
        .filter((ele) => !ele.deleted)
        .sort((a, b) => a.id - b.id);
      setMiscellaneousNoteList(filteredNotDeletedNotes);
    } else {
      setLoading(false);
    }
  };

  const handleShowReports = () => {
    setShowMiscellaneousNotes(true);
  };

  const resetMiscellaneousNotesDetails = () => {
    setMiscellaneousNoteDetails(initialMiscellaneousNoteDetails);
  };

  const handleSelectDate = (date) => {
    setMiscellaneousNoteDetails((prevDetails) => ({
      ...prevDetails,
      noteDate: date
    }));
  };

  const handleSelectCategory = (selectedCategory) => {
    const { value, id } = selectedCategory;
    setMiscellaneousNoteDetails((prevDetails) => ({
      ...prevDetails,
      category: { name: value, id }
    }));
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setMiscellaneousNoteDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const getSearchResults = () => {
    const searchResult = miscellaneousNoteList
      .filter((note) => (
        note.user.toLowerCase().includes((searchTerm).toLowerCase())
        || note.client.toLowerCase().includes((searchTerm).toLowerCase()))
        || note.house.toLowerCase().includes((searchTerm).toLowerCase()));
    return searchResult;
  };

  const handleUpdateModal = (noteDetails) => {
    setMiscellaneousNoteDetails(noteDetails);
    setShowCustomModalUpdateNote(true);
  };

  const handleDeleteNote = async (noteDetails) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateMiscellaneousNote(noteDetails.id,
        {
          ...noteDetails,
          noteDate: moment(noteDetails.noteDate).format('YYYY-MM-DD'),
          categoryId: noteDetails.category?.id,
          lastUploadedBy: getUserDetails().firstName,
          deleted: true
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Note deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getMiscellaneousNotesHandler({
          ...requestObject,
          category: category?.name,
          start: startDate && moment(startDate).format('YYYY-MM-DD'),
          end: endDate && moment(endDate).format('YYYY-MM-DD')
        });
      } else {
        setLoading(false);
        swal({
          title: 'Note deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleUpdateNote = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await updateMiscellaneousNote(miscellaneousNoteDetails.id,
      {
        ...miscellaneousNoteDetails,
        noteDate: moment(miscellaneousNoteDetails.noteDate).format('YYYY-MM-DD'),
        categoryId: miscellaneousNoteDetails.category?.id,
        lastUploadedBy: getUserDetails().firstName
      });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Note updated successfully!',
        icon: 'success',
        timer: 3000
      });
      getMiscellaneousNotesHandler({
        ...requestObject,
        category: category?.name,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      });
      setValidationMsg('');
      setShowCustomModalUpdateNote(false);
      resetMiscellaneousNotesDetails();
    } else {
      setLoading(false);
      swal({
        title: 'Note updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      setShowCustomModalUpdateNote(false);
      resetMiscellaneousNotesDetails();
    }
  };

  const handleAddNote = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await createMiscellaneouNote(
      {
        ...miscellaneousNoteDetails,
        noteDate: moment(miscellaneousNoteDetails.noteDate).format('YYYY-MM-DD'),
        categoryId: miscellaneousNoteDetails.category?.id,
        lastUploadedBy: getUserDetails().firstName
      }
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Note created successfully!',
        icon: 'success',
        timer: 3000
      });
      getMiscellaneousNotesHandler({
        ...requestObject,
        category: category?.name,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      });
      setValidationMsg('');
      setShowCustomModalAddNote(false);
      resetMiscellaneousNotesDetails();
    } else {
      setLoading(false);
      swal({
        title: 'Note creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      setShowCustomModalAddNote(false);
      resetMiscellaneousNotesDetails();
    }
  };

  const getMiscellaneousNotesCategories = async () => {
    const resp = await listMiscellaneousNoteCategories();
    if (!resp) {
      return;
    }
    if (resp.status === 200) {
      const miscellaneousNoteCategories = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'category'
      }));
      setMiscellaneousCategoryList([...miscellaneousNoteCategories]);
    }
  };

  useEffect(() => {
    getMiscellaneousNotesCategories();
  }, []);

  useEffect(() => {
    setMiscellaneousNoteList([]);
    setStartDate('');
    setEndDate('');
  }, [category.name]);

  useEffect(() => {
    setMiscellaneousNoteList([]);
    if (startDate && endDate) {
      getMiscellaneousNotesHandler({
        ...requestObject,
        category: category?.name,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      });
    }
  }, [startDate]);

  useEffect(() => {
    setMiscellaneousNoteList([]);
    if (endDate) {
      getMiscellaneousNotesHandler({
        ...requestObject,
        category: category?.name,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <MiscellaneousNotesWrapper>
        <AddNote onClick={() => {
          resetMiscellaneousNotesDetails();
          setShowCustomModalAddNote(true);
        }}
        >
          <img src={addIcon} alt="add icon" />
        </AddNote>
        <div>
          <SelectionWrapper>
            <div className="review-row">
              <div className="w-33 mr">
                <p className="label">
                  Select Category
                </p>
                <SelectContainer
                  options={miscellaneousCategoryList}
                  placeholderText="Category"
                  className="select-container"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    label: category?.name,
                    value: category?.name,
                    id: category?.id
                  }}
                  disabled={!miscellaneousCategoryList.length}
                />
              </div>
              <div className="w-33 mr date-picker-wrapper">
                <p className="label">
                  Select Start Date
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled={!category.name}
                />
              </div>
              <div className="w-33 date-picker-wrapper">
                <p className="label">
                  Select End Date
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  disabled={!startDate}
                  minDate={startDate && startDate}
                />
              </div>
            </div>
          </SelectionWrapper>
          <ShowNotesButtonWrapper>
            <button
              type="button"
              className="submit-btn"
              onClick={handleClearSelection}
            >
              Clear Selection
            </button>
            <button
              type="button"
              className="submit-btn"
              disabled={!endDate}
              onClick={handleShowReports}
            >
              Show Notes
            </button>
          </ShowNotesButtonWrapper>
        </div>
        {
           showMiscellaneousNotes && startDate && endDate
             ? (
               <NotesListWrapper>
                 {
                  miscellaneousNoteList.length > 0 && (
                    <SearchInputWrapper>
                      <FaSearch />
                      <input
                        className="search-field"
                        placeholder="Search by User/House/Client Name"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </SearchInputWrapper>
                  )
                }
                 {
                  miscellaneousNoteList.length === 0 && <NoData />
                }
                 {
                  miscellaneousNoteList && getSearchResults()
                    .map((noteDetails) => (
                      <MiscellaneousNotes
                        noteDetails={noteDetails}
                        handleUpdateModal={handleUpdateModal}
                        handleDeleteNote={handleDeleteNote}
                      />
                    ))
                }
               </NotesListWrapper>
             ) : null
        }
      </MiscellaneousNotesWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddNote}
        setClose={() => {
          setShowCustomModalAddNote(false);
        }}
        title="Add Note"
        width="580px"
        operationName="Add"
        onSubmit={handleAddNote}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="selectContainer">
                Select Date
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select Date"
                selected={miscellaneousNoteDetails.noteDate}
                onChange={(date) => handleSelectDate(date)}
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="Category">
                Select Category
              </label>
              <SelectContainer
                id="Category"
                options={miscellaneousCategoryList.length > 0 && miscellaneousCategoryList}
                placeholderText="Category"
                className="select-container"
                isMultiple={false}
                onChange={handleSelectCategory}
                defaultValue={{
                  label: miscellaneousNoteDetails.category?.name,
                  value: miscellaneousNoteDetails.category?.name,
                  id: category?.id
                }}
                disabled={!miscellaneousCategoryList.length > 0}
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="house">House</label>
              <input
                type="text"
                id="house"
                placeholder="House"
                name="house"
                onChange={handleChange}
                value={miscellaneousNoteDetails.house}
                required
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="User">User</label>
              <input
                type="text"
                id="User"
                placeholder="User"
                name="user"
                onChange={handleChange}
                value={miscellaneousNoteDetails.user}
                required
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="Client">Client</label>
              <input
                type="text"
                id="Client"
                placeholder="Client"
                name="client"
                onChange={handleChange}
                value={miscellaneousNoteDetails.client}
                required
              />
            </div>
            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleChange}
                value={miscellaneousNoteDetails.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleChange}
                value={miscellaneousNoteDetails.content}
                required
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateNote}
        setClose={() => {
          setShowCustomModalUpdateNote(false);
        }}
        title="Update Note"
        width="580px"
        operationName="Update"
        onSubmit={handleUpdateNote}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="selectContainer">
                Note Date
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select Date"
                selected={miscellaneousNoteDetails.noteDate
                  && new Date(miscellaneousNoteDetails.noteDate)}
                onChange={(date) => handleSelectDate(date)}
                disabled
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="Category">
                Category
              </label>
              <SelectContainer
                id="Category"
                options={miscellaneousCategoryList.length > 0 && miscellaneousCategoryList}
                placeholderText="Category"
                className="select-container"
                isMultiple={false}
                onChange={handleSelectCategory}
                defaultValue={{
                  label: miscellaneousNoteDetails.category?.name,
                  value: miscellaneousNoteDetails.category?.name,
                  id: category?.id
                }}
                disabled={!miscellaneousCategoryList.length > 0 || true}
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="house">House</label>
              <input
                type="text"
                id="house"
                placeholder="House"
                name="house"
                onChange={handleChange}
                value={miscellaneousNoteDetails.house}
                required
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="User">User</label>
              <input
                type="text"
                id="User"
                placeholder="User"
                name="user"
                onChange={handleChange}
                value={miscellaneousNoteDetails.user}
                required
              />
            </div>
            <div className="input-with-one-third-width">
              <label htmlFor="Client">Client</label>
              <input
                type="text"
                id="Client"
                placeholder="Client"
                name="client"
                onChange={handleChange}
                value={miscellaneousNoteDetails.client}
                required
              />
            </div>
            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleChange}
                value={miscellaneousNoteDetails.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleChange}
                value={miscellaneousNoteDetails.content}
                required
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsg}</h6>}
        </div>
      </CustomModal>
    </>
  );
};

export default AdminMiscellaneousNotes;
