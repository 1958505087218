/** @format */

import styled from 'styled-components';

export const OuterWrapper = styled.div`
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  border-radius: 27px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;

export const ClientFolderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: calc(56vh - 50px);
`;

export const BoxWrapper = styled.div`
  height: 251px;
  width: 251px;
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #f2e4d8;
  border-radius: 27px;
  border-bottom: none;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const EditRow = styled.div`
  margin: 5px 10px 0 auto;
`;

export const EditButton = styled.button`
  img {
	width: 22px;
	padding: 5px 5px 0 0;
	cursor: pointer;
  }
  .cross{
	width: 30px;
  }
  &{
	border:none;
	background: none;
  }
`;

export const FolderIcon = styled.div`
  svg {
    width: 102px;
    height: auto;
    color: ${({ theme }) => theme.colors.od_secondary};
	cursor: pointer;
  }
`;

export const FolderButton = styled.div`
  width: 251px;
  height: 53px;
  background: #e6e9f4;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.od_extra_color};
  cursor: pointer;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.input-with-full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom: 3px;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      border: none;
    }
  }
  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  select:invalid {
    color: gray;
  }
  input:focus,
  select:focus {
    outline: none;
  }
`;

export const AddFolder = styled.button`
  position: absolute;
  top: 195px;
  right: 30px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;
`;
