/** @format */

import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useLocation } from 'react-router-dom';
import {
  BreadCrumb,
  Wrapper,
  TabComponentWrapper,
  StyledTabs,
  StyledTabList,
  StyledTab,
  StyledTabPanel
} from './index.style';
import ClientIncidents from '../../components/ClientIncidents/ClientIncidents';
import ClientSupportFolders from '../../components/ClientSupportFolders/ClientSupportFolders';
import NotesTable from '../../components/NotesTable/NotesTable';
import ClientForms from '../../components/ClientReports/ClientReports';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';

const Client = () => {
  const location = useLocation();
  const clientName = location.pathname.split('/')[3];
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [clientName]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Houses </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Clients </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              {clientName}
            </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              { selectedIndex === 0 && 'Daily Notes'}
              { selectedIndex === 1 && 'Incidents'}
              { selectedIndex === 2 && 'Forms'}
              { selectedIndex === 3 && 'Support Documents'}
            </span>
          </li>
        </ul>
      </BreadCrumb>
      <Wrapper>
        <StyledTabs
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <StyledTabList>
            <StyledTab>Daily Notes</StyledTab>
            <StyledTab>Incidents</StyledTab>
            <StyledTab>Forms</StyledTab>
            <StyledTab>Support Documents</StyledTab>
          </StyledTabList>
          <StyledTabPanel>
            <TabComponentWrapper>
              <NotesTable />
            </TabComponentWrapper>
          </StyledTabPanel>
          <StyledTabPanel>
            <TabComponentWrapper>
              <ClientIncidents />
            </TabComponentWrapper>
          </StyledTabPanel>
          <StyledTabPanel>
            <TabComponentWrapper>
              <ClientForms />
            </TabComponentWrapper>
          </StyledTabPanel>
          <StyledTabPanel>
            <TabComponentWrapper>
              <ClientSupportFolders />
            </TabComponentWrapper>
          </StyledTabPanel>
        </StyledTabs>
      </Wrapper>
    </>

  );
};

export default Client;
