/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Dropdown, Modal, Spinner
} from 'react-bootstrap';
import { FaEllipsisV, FaSearch } from 'react-icons/fa';
import swal from 'sweetalert';
import {
  checkValidationAddUsers, checkIfHouseAdminOnly, checkValidationEditUsers
} from '../../helpers/utils';
import CustomModal from '../../components/CustomModal';
import {
  getUserListPerPage, register, getRolesList, addRole,
  removeRole, getHouseList, addHouse, removeHouse, updateUser
} from '../../services/result.services';
import {
  UsersWrapper, UserManagementHeader, Pagination,
  FormWrapper, SettingsModalWrapper, SearchWrapper, SearchInputWrapper, IconContainer
} from './index.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  TableWrapper, StyledTable, Th, Td, TableContainer
} from '../../styledComponents/common/table.style';
import { DropdownItem, DropdownToggle } from '../../styledComponents/common/dropdown.style';
import deleteIcon from '../../assets/logo/close_cross.svg';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import plusIcon from '../../assets/logo/plus.svg';
import userIcon from '../../assets/logo/user.svg';
import NoData from '../../components/NoData';

const AdminUsers = () => {
  const tableBodyRef = useRef('');
  const [userList, setUserList] = useState([]);
  const [userListToBeDisplayed, setUserListToBeDisplayed] = useState([]);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalAddUser, setShowCustomModalAddUser] = useState(false);
  const [showCustomModalEditUser, setShowCustomModalEditUser] = useState(false);
  const [showCustomModalAddHouse, setShowCustomModalAddHouse] = useState(false);
  const [showCustomModalAddRole, setShowCustomModalAddRole] = useState(false);
  const [showCustomModalRemoveRole, setShowCustomModalRemoveRole] = useState(false);
  const [showCustomModalRemoveHouse, setShowCustomModalRemoveHouse] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [listHouses, setListHouses] = useState([]);
  const [rolesAvailableToBeAdded, setRolesAvailableToBeAdded] = useState(true);
  const [housesAvailableToBeAdded, setHousesAvailableToBeAdded] = useState(true);
  const [rolesAvailableToBeRemoved, setRolesAvailableToBeRemoved] = useState(true);
  const [housesAvailableToBeRemoved, setHousesAvailableToBeRemoved] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addableRoles, setAddableRoles] = useState({});
  const [addableHouses, setAddableHouses] = useState({});
  const [removableRoles, setRemovableRoles] = useState({});
  const [removableHouses, setRemovableHouses] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedHouseCode, setSelectedHouseCode] = useState(null);
  const [validationMsg, setValidationMsg] = useState('');
  const [validationEditMsg, setValidationEditMsg] = useState('');
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: 'simple',
    mobile: '',
    phone: ''
  });
  const [pageCount, setPageCount] = useState(10);
  const [totalUserCount, setTotalUserCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchNameOrEmail, setSearchNameOrEmail] = useState('');
  const [searchHouse, setSearchHouse] = useState('');
  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const PER_PAGE = 10;

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-role') {
      setShowCustomModalAddRole(false);
    } else if (operation === 'remove-role') {
      setShowCustomModalRemoveRole(false);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(false);
    } else if (operation === 'remove-house') {
      setShowCustomModalRemoveHouse(false);
    } else if (operation === 'add-user') {
      setShowCustomModalAddUser(false);
    } else if (operation === 'edit-user') {
      setShowCustomModalEditUser(false);
    }
  };

  const handleShowCustomModal = (operation) => {
    if (operation === 'add-role') {
      setShowCustomModalAddRole(true);
    } else if (operation === 'remove-role') {
      setShowCustomModalRemoveRole(true);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(true);
    } else if (operation === 'remove-house') {
      setShowCustomModalRemoveHouse(true);
    } else if (operation === 'add-user') {
      setShowCustomModalAddUser(true);
    } else if (operation === 'edit-user') {
      setShowCustomModalEditUser(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResetAddUserForm = () => {
    setUserDetails((prevState) => ({
      ...prevState,
      firstName: '',
      lastName: '',
      email: '',
      password: 'simple',
      mobile: '',
      phone: ''
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUserDetailsEdit((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getUsersHandler = async (payload) => {
    setLoading(true);
    try {
      const resp = await getUserListPerPage(payload);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        const { users, totalUsers } = resp.data;
        setUserList(users);
        setUserListToBeDisplayed(users.filter(
          (ele, ind) => ind === users.findIndex((elem) => elem.id === ele.id)
        ));
        setTotalUserCount(totalUsers);
        setPageCount(Math.ceil(totalUsers / PER_PAGE));
      } else if (resp.status === 500) {
        setLoading(false);
        swal({
          title: 'Internal Server Error, Try again later',
          icon: 'error',
          timer: 3000
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const handlePageChange = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
    if (searchNameOrEmail.length > 2 && searchHouse.length > 2) {
      getUsersHandler({
        pageSize: PER_PAGE,
        pageNumber: selected,
        nameOrEmail: searchNameOrEmail,
        houseCode: searchHouse
      });
    } else if (searchNameOrEmail.length > 2 && searchHouse === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected, nameOrEmail: searchNameOrEmail });
    } else if (searchNameOrEmail === '' && searchHouse.length > 2) {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected, houseCode: searchHouse });
    } else {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected });
    }
  };

  const getRoles = async () => {
    const resp = await getRolesList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setListRoles(resp.data);
    } else {
      setListRoles([]);
    }
  };

  const getHouses = async () => {
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      setListHouses(filteredActiveHouses);
    } else {
      setListHouses([]);
    }
  };

  const handleSelectedUser = (user) => {
    setSelectedUser(user);
  };

  const checkIfRolesAvailableToBeAddedOrRemoved = (user) => {
    let rolesToBeAdded = [];
    let rolesToBeRemoved = [];
    if (checkIfHouseAdminOnly()) {
      rolesToBeAdded = listRoles.filter((ele) => !user.roleNames.includes(ele.name));
      rolesToBeAdded = rolesToBeAdded.filter((ele) => ele.name !== 'ADMIN');
    } else {
      rolesToBeAdded = listRoles.filter((ele) => !user.roleNames.includes(ele.name));
    }
    if (rolesToBeAdded.length > 0) {
      setRolesAvailableToBeAdded(true);
    } else {
      setRolesAvailableToBeAdded(false);
    }
    if (checkIfHouseAdminOnly()) {
      rolesToBeRemoved = listRoles.filter((ele) => user.roleNames.includes(ele.name));
      rolesToBeRemoved = rolesToBeRemoved.filter((ele) => ele.name !== 'ADMIN');
    } else {
      rolesToBeRemoved = listRoles.filter((ele) => user.roleNames.includes(ele.name));
    }
    if (rolesToBeRemoved.length > 1) {
      setRolesAvailableToBeRemoved(true);
    } else {
      setRolesAvailableToBeRemoved(false);
    }
  };

  const checkIfHousesAvailableToBeAddedOrRemoved = (user) => {
    const housesToBeRemoved = listHouses.filter((ele) => user.houses.includes(ele.houseCode));
    if (housesToBeRemoved.length > 0) {
      setHousesAvailableToBeRemoved(true);
    } else {
      setHousesAvailableToBeRemoved(false);
    }

    const housesToBeAdded = listHouses.filter((ele) => !user.houses.includes(ele.houseCode));
    if (housesToBeAdded.length > 0) {
      const isAdmin = user.roleNames.some(
        (role) => ['ADMIN'].indexOf(role) > -1
      );
      if (isAdmin) {
        setHousesAvailableToBeAdded(false);
        return;
      }
      setHousesAvailableToBeAdded(true);
    } else if (housesToBeAdded.length === 0) {
      setHousesAvailableToBeAdded(false);
    }
  };
  const handleAddRole = (user) => {
    let roles = [];
    roles = listRoles.filter((ele) => !user.roleNames.includes(ele.name));
    if (checkIfHouseAdminOnly()) {
      roles = roles.filter((ele) => ele.name !== 'ADMIN');
    }
    setAddableRoles({ roles: [...roles], userId: user.id });
    handleShowCustomModal('add-role');
  };

  const handleRemoveRole = (user) => {
    let roles = [];
    roles = listRoles.filter((ele) => user.roleNames.includes(ele.name));
    const rolesWithoutUSER = roles.filter((ele) => ele.id !== 1);
    if (checkIfHouseAdminOnly()) {
      roles = rolesWithoutUSER.filter((ele) => ele.name !== 'ADMIN');
    }
    setRemovableRoles({ roles: [...roles], userId: user.id });
    handleShowCustomModal('remove-role');
  };

  const handleAddUser = () => {
    handleShowCustomModal('add-user');
  };

  const handleAddHouse = (user) => {
    const houses = listHouses.filter((ele) => !user.houses.includes(ele.houseCode));
    setAddableHouses({ houses: [...houses], userId: user.id });
    handleShowCustomModal('add-house');
  };

  const handleRemoveHouse = (user) => {
    const houses = listHouses.filter((ele) => user.houses.includes(ele.houseCode));
    setRemovableHouses({ roles: [...houses], userId: user.id });
    handleShowCustomModal('remove-house');
  };

  const handleAddRoleChange = (e) => {
    const { value } = e.target;
    setSelectedRoleId(Number(value));
  };

  const handleAddHouseChange = (e) => {
    const { value } = e.target;
    setSelectedHouseCode(value);
  };

  const handleRemoveHouseChange = (e) => {
    const { value } = e.target;
    setSelectedHouseCode(value);
  };

  const handleRemoveRoleChange = (e) => {
    const { value } = e.target;
    setSelectedRoleId(Number(value));
  };

  const addRoleToUser = async () => {
    handleCloseCustomModal('add-role');
    setLoading(true);
    const resp = await addRole({ userId: addableRoles.userId, roleId: selectedRoleId });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setAddableRoles({});
      setSelectedRoleId(null);
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
    } else {
      setLoading(false);
      setAddableRoles({});
      setSelectedRoleId(null);
    }
  };

  const removeRoleFromUser = async () => {
    handleCloseCustomModal('remove-role');
    setLoading(true);
    const resp = await removeRole({ userId: removableRoles.userId, roleId: selectedRoleId });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setRemovableRoles({});
      setSelectedRoleId(null);
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
    } else {
      setLoading(false);
      setRemovableRoles({});
      setSelectedRoleId(null);
    }
  };

  const addHouseToUser = async () => {
    handleCloseCustomModal('add-house');
    setLoading(true);
    const resp = await addHouse({ userId: addableHouses.userId, houseCode: selectedHouseCode });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setAddableHouses({});
      setSelectedHouseCode(null);
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
    } else {
      setLoading(false);
      setAddableHouses({});
      setSelectedHouseCode(null);
    }
  };

  const removeHouseFromUser = async () => {
    handleCloseCustomModal('remove-house');
    setLoading(true);
    const resp = await removeHouse({
      userId: removableHouses.userId, houseCode: selectedHouseCode
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setRemovableHouses({});
      setSelectedHouseCode(null);
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
    } else {
      setLoading(false);
      setRemovableHouses({});
      setSelectedHouseCode(null);
    }
  };

  const handleDeleteUser = async (id, userObj) => {
    const value = await swal({
      text: `Are you sure you want to delete ${userObj.firstName} ${userObj.lastName}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateUser(id, userObj);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${userObj.firstName} ${userObj.lastName} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        await getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
      } else {
        setLoading(false);
        swal({
          title: 'User deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getRoles();
    getHouses();
    getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0 });
  }, []);

  useEffect(() => {
    if (searchNameOrEmail.length > 2 && searchHouse.length > 2) {
      getUsersHandler({
        pageSize: PER_PAGE,
        pageNumber: 0,
        nameOrEmail: searchNameOrEmail,
        houseCode: searchHouse
      });
      setCurrentPage(0);
    } else if (searchNameOrEmail.length > 2 && searchHouse === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0, nameOrEmail: searchNameOrEmail });
      setCurrentPage(0);
    } else if (searchNameOrEmail === '' && searchHouse.length > 2) {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0, houseCode: searchHouse });
      setCurrentPage(0);
    } else if (searchNameOrEmail === '' && searchHouse === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0 });
      setCurrentPage(0);
    }
  }, [searchNameOrEmail, searchHouse]);

  const addUser = async () => {
    if (!checkValidationAddUsers(userDetails, setValidationMsg)) return;
    setLoading(true);
    setShowCustomModalAddUser(true);
    const resp = await register({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      password: userDetails.password,
      mobile: userDetails.mobile,
      phone: userDetails.phone
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddUser(false);
      handleResetAddUserForm();
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddUser(false);
      swal({
        title: 'User created successfully!',
        icon: 'success',
        timer: 3000
      });
      if (Number.isInteger(totalUserCount / PER_PAGE)) {
        getUsersHandler({ pageSize: PER_PAGE, pageNumber: pageCount });
        setCurrentPage(pageCount);
      } else {
        getUsersHandler({ pageSize: PER_PAGE, pageNumber: pageCount - 1 });
        setCurrentPage(pageCount - 1);
      }
    } else {
      setLoading(false);
      setShowCustomModalAddUser(false);
      swal({
        title: 'User creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const showEditUserModal = (user) => {
    setUserDetailsEdit(user && user);
    handleShowCustomModal('edit-user');
  };

  const editUser = async () => {
    if (!checkValidationEditUsers(userDetailsEdit
       && userDetailsEdit, setValidationEditMsg)) return;
    setLoading(true);
    const resp = await updateUser(userDetailsEdit.id, {
      firstName: userDetailsEdit && userDetailsEdit.firstName,
      lastName: userDetailsEdit && userDetailsEdit.lastName,
      email: userDetailsEdit && userDetailsEdit.email,
      mobile: userDetailsEdit && userDetailsEdit.mobile,
      phone: userDetailsEdit && userDetailsEdit.phone,
      deleted: false
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditUser(false);
      swal({
        title: 'User updated successfully!',
        icon: 'success',
        timer: 3000
      });
      await getUsersHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
    } else {
      setLoading(false);
      setShowCustomModalEditUser(false);
      swal({
        title: 'User updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Users </span>
          </li>
        </ul>
      </BreadCrumb>
      <UsersWrapper>
        <UserManagementHeader>
          <h2>USER MANAGEMENT</h2>
          <button type="button" onClick={() => handleAddUser()}>
            <img src={plusIcon} alt="plus icon" />
            Add User
          </button>
        </UserManagementHeader>
        <SearchWrapper>
          <SearchInputWrapper>
            <span>
              {' '}
              <FaSearch />
            </span>
            <input
              placeholder="Search by Name or Email"
              onChange={(e) => setSearchNameOrEmail(e.target.value)}
            />
          </SearchInputWrapper>
          <SearchInputWrapper>
            <span>
              {' '}
              <FaSearch />
            </span>
            <input
              placeholder="Search by House"
              onChange={(e) => setSearchHouse(e.target.value)}
            />
          </SearchInputWrapper>
        </SearchWrapper>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  userList.length !== 0
                && (
                <tr>
                  <Th width="40px">No.</Th>
                  <Th width="100px">Name</Th>
                  <Th width="180px">Email</Th>
                  <Th width="140px">Phone</Th>
                  <Th width="140px">Mobile</Th>
                  <Th width="140px">Roles</Th>
                  <Th width="120px">House</Th>
                  <Th width="60px" align="right">Action</Th>
                  <Th width="40px" align="left" />
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                { userListToBeDisplayed
                  .map((user, index) => (
                    <tr key={user.id}>
                      <Td width="40px">{index + 1}</Td>
                      <Td width="100px">{user.firstName ? `${user.firstName} ${user.lastName}` : ''}</Td>
                      <Td width="180px" data-testid="user">{user.email}</Td>
                      <Td width="140px" data-testid="user">{user.phone}</Td>
                      <Td width="140px" data-testid="user">{user.mobile}</Td>
                      <Td width="140px">{user.roleNames.join(', ')}</Td>
                      <Td width="120px">{user.houses ? user.houses.join(', ') : null}</Td>
                      <Td width="60px" align="right">
                        <Dropdown
                          alignRight
                          onClick={() => {
                            checkIfRolesAvailableToBeAddedOrRemoved(user);
                            checkIfHousesAvailableToBeAddedOrRemoved(user);
                            handleSelectedUser(user);
                          }}
                        >
                          <DropdownToggle
                            id="options-button"
                            variant="borderless-dark"
                            bsPrefix="no-chevron"
                            size="sm"
                          >
                            <FaEllipsisV />
                          </DropdownToggle>
                          <Dropdown.Menu flip>
                            <DropdownItem>
                              <button type="button" onClick={() => showEditUserModal(user)}>
                                Update User
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" className={`${!rolesAvailableToBeAdded && 'disabled-btn'}`} onClick={() => handleAddRole(user)} disabled={!rolesAvailableToBeAdded}>
                                Add Role
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" className={`${!rolesAvailableToBeRemoved && 'disabled-btn'}`} onClick={() => handleRemoveRole(user)} disabled={!rolesAvailableToBeRemoved}>
                                Remove Role
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" className={`${!housesAvailableToBeAdded && 'disabled-btn'}`} onClick={() => handleAddHouse(user)} disabled={!housesAvailableToBeAdded}>
                                Add House
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" className={`${!housesAvailableToBeRemoved && 'disabled-btn'}`} onClick={() => handleRemoveHouse(user)} disabled={!housesAvailableToBeRemoved}>
                                Remove House
                              </button>
                            </DropdownItem>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Td>
                      <Td width="40px" align="left">
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteUser(user.id, {
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            deleted: true
                          })}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>
                  ))}
                {
                  userList.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            forcePage={currentPage}
            previousLabel="Prev"
            marginPagesDisplayed={1}
            pageRangeDisplayed={4}
          />
        </TableContainer>
      </UsersWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddRole}
        setClose={() => {
          handleCloseCustomModal('add-role');
          setSelectedRoleId(null);
        }}
        title="Add Role"
        onSubmit={addRoleToUser}
        isDisabled={!selectedRoleId}
        operationName="Add"
      >
        <SettingsModalWrapper>
          {selectedUser
            && (
            <div className="input-label-wrapper">
              <p className="label">User Name</p>
              <div className="icon-name-wrapper">
                <img className="icon" src={userIcon} alt="user icon" />
                <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
              </div>
            </div>
            )}
          <div className="input-label-wrapper">
            <label htmlFor="role">Role</label>
            <select className="form-select" onChange={(event) => handleAddRoleChange(event)} id="role">
              <option disabled selected value="">Select Role</option>
              { addableRoles
              && addableRoles.roles
              && addableRoles.roles.length > 0
              && addableRoles.roles.map((role) => (
                <option
                  key={role.id}
                  value={role.id}
                >
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </SettingsModalWrapper>
      </CustomModal>
      <CustomModal
        open={showCustomModalRemoveRole}
        setClose={() => {
          handleCloseCustomModal('remove-role');
          setSelectedRoleId(null);
        }}
        title="Remove Role"
        onSubmit={removeRoleFromUser}
        isDisabled={!selectedRoleId}
        operationName="Remove"
      >
        <SettingsModalWrapper>
          {selectedUser
            && (
            <div className="input-label-wrapper">
              <p className="label">User Name</p>
              <div className="icon-name-wrapper">
                <img className="icon" src={userIcon} alt="user icon" />
                <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
              </div>
            </div>
            )}
          <div className="input-label-wrapper">
            <label htmlFor="role">Role</label>
            <select className="form-select" onChange={(event) => handleRemoveRoleChange(event)} id="role">
              <option disabled selected value="">Select Role</option>
              { removableRoles
              && removableRoles.roles
              && removableRoles.roles.length > 0
              && removableRoles.roles.map((role) => (
                <option
                  key={role.id}
                  value={role.id}
                  disabled={role.id === 1}
                >
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </SettingsModalWrapper>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddUser}
        setClose={() => {
          handleCloseCustomModal('add-user');
          handleResetAddUserForm();
          setValidationMsg('');
        }}
        title="Add User"
        onSubmit={addUser}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="fname">First Name</label>
              <input
                id="fname"
                required
                type="text"
                placeholder="First Name"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="lname">Last Name</label>
              <input
                id="lname"
                required
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label bg">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                required
                type="email"
                value={userDetails.email}
                placeholder="Email"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                id="phone"
                required
                type="text"
                placeholder="Phone"
                value={userDetails.phone}
                name="phone"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="mobile">04 or 05, 8 digit mobile number</label>
              <input
                id="mobile"
                required
                type="text"
                placeholder="Mobile"
                name="mobile"
                value={userDetails.mobile}
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditUser}
        setClose={() => {
          handleCloseCustomModal('edit-user');
          setValidationEditMsg('');
        }}
        title="Update User"
        onSubmit={editUser}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="fname">First Name</label>
              <input
                id="fname"
                required
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleEditChange}
                defaultValue={userDetailsEdit && userDetailsEdit.firstName}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="lname">Last Name</label>
              <input
                id="lname"
                required
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleEditChange}
                defaultValue={userDetailsEdit && userDetailsEdit.lastName}
              />
            </div>
            <div className="input-with-label bg">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                required
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleEditChange}
                defaultValue={userDetailsEdit && userDetailsEdit.email}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                id="phone"
                required
                type="text"
                placeholder="Phone"
                value={userDetailsEdit && userDetailsEdit.phone}
                name="phone"
                onChange={handleEditChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="mobile">04 or 05, 8 digit mobile number</label>
              <input
                id="mobile"
                required
                type="text"
                placeholder="Mobile"
                name="mobile"
                value={userDetailsEdit && userDetailsEdit.mobile}
                onChange={handleEditChange}
              />
            </div>
          </FormWrapper>
          { validationEditMsg !== '' && <h6 className="m-3 text-danger">{validationEditMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddHouse}
        setClose={() => {
          handleCloseCustomModal('add-house');
        }}
        title="Add House"
        onSubmit={addHouseToUser}
        operationName="Add"
        isDisabled={!selectedHouseCode}
      >
        <SettingsModalWrapper>
          {selectedUser
          && (
          <div className="input-label-wrapper">
            <p className="label">User Name</p>
            <div className="icon-name-wrapper">
              <img className="icon" src={userIcon} alt="user icon" />
              <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
            </div>
          </div>
          )}
          <div className="input-label-wrapper">
            <label htmlFor="house">House Code</label>
            <select className="form-select" onChange={(event) => handleAddHouseChange(event)} id="house">
              <option disabled selected value="null">Select House</option>
              { addableHouses
              && addableHouses.houses
              && addableHouses.houses.length > 0
              && addableHouses.houses.map((house) => (
                <option
                  key={house.id}
                  value={house.houseCode}
                >
                  {house.houseCode}
                </option>
              ))}
            </select>
          </div>
        </SettingsModalWrapper>
      </CustomModal>
      <CustomModal
        open={showCustomModalRemoveHouse}
        setClose={() => {
          handleCloseCustomModal('remove-house');
        }}
        title="Remove House"
        onSubmit={removeHouseFromUser}
        operationName="Remove"
        isDisabled={!selectedHouseCode}
      >
        <SettingsModalWrapper>
          {selectedUser
          && (
          <div className="input-label-wrapper">
            <p className="label">User Name</p>
            <div className="icon-name-wrapper">
              <img className="icon" src={userIcon} alt="user icon" />
              <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
            </div>
          </div>
          )}
          <div className="input-label-wrapper">
            <label htmlFor="house">House Code</label>
            <select className="form-select" onChange={(event) => handleRemoveHouseChange(event)}>
              <option disabled selected value="">Select House</option>
              { removableHouses
              && removableHouses.roles
              && removableHouses.roles.length > 0
              && removableHouses.roles.map((house) => (
                <option
                  key={house.id}
                  value={house.houseCode}
                >
                  {house.houseCode}
                </option>
              ))}
            </select>
          </div>
        </SettingsModalWrapper>
      </CustomModal>
    </>
  );
};

export default AdminUsers;
