/** @format */
import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const ReviewWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  flex-wrap: wrap;
  margin-top:25px;

  .hr{
    height: 2px;
    width: 99%;
    margin-right: 1rem;
    color: rgba(116, 188, 169, 1);
  }
  .ml-sm{
	margin-left: 0.25rem;
  }
  .review-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .date-picker-wrapper .react-datepicker-wrapper {
      width: 100%;
    }
    .date-picker-wrapper input {
      width: 100%;
      min-height: 38px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #f2e4d8;
    }
  }
  .review-row .input-like {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .mr {
    margin: 0 1% 0.5rem 0;
  }
  .review-row .what-happend {
    margin-right: 0;
    margin-left: 1%;
  }
  .label {
    font-size: 12px;
    margin: 5px 7px;
  }
  .input,
  .input-like {
    padding: 0 8px;
    min-height: 38px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid #f2e4d8;
  }

  .input-like {
    padding: 4px 8px;
    margin-bottom: 0;
  }
  .flex {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    .incident_num {
      color: ${({ theme }) => theme.colors.od_secondary};
      padding: 0;
      margin: 0 7px;
    }
  }
  .w-100 {
    flex: 0 0 100%;
  }
  .w-25 {
    flex: 0 0 24%;
  }
  .w-50 {
    flex: 0 0 49.5%;
  }
  .w-33 {
	flex: 0 0 32.2%; 
  }
  .date-picker-wrapper {
    margin-right: 1%;
  }
`;

export const ShowReportsButtonWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top:22px;

  button {
    margin:12px;
    border:none;
    font-weight: bold;
    font-size: 16px;
    cursor:pointer;
  }

  button:first-child {
    background: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }

  .submit-btn {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    padding:12px;
    border-radius:5px;
    border:none;
  }
  .submit-btn:disabled {
    opacity:0.6;
  }
`;
