/** @format */

import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const SliderButtonContainer = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;

  h2{
    color: ${({ theme }) => theme.colors.od_secondary};
    font-size: 20px;
  }
  button{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    margin: 0rem 0.25rem;
  }
  button:hover{
    background-color: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const SliderContainer = styled.div`
  margin: 1rem 0 2rem -1rem;
  .slick-arrow {
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.od_primary};
  }
  .box {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 380px !important;
    height: 370px;
    margin: auto 1rem;
    .lower-part {
      margin: 0.75rem;
    }
    .img-container {
      max-width: 100%;
      height: 250px;
      border-radius: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
    .house-phone-wrap {
      display: flex;
      justify-content: space-between;
    }
    .icon-with-text {
      display: flex;
      align-items: flex-start;
      color: ${({ theme }) => theme.colors.od_placeholder};
      .address-icon {
        margin-top: 0.3rem;
      }
      .contact-icon {
        margin-top: 0.2rem;
        width: 22px;
      }
      span {
        margin-left: 10px;
      }
    }
    .house-code {
      color: ${({ theme }) => theme.colors.od_text_color};
    }
  }
`;

export const StatusContainer = styled.div`
  span {
    text-align: center;
    display: inline-block;
    padding: 0.5rem 0;
    width: 90px;
    border-radius: 7px;
  }
  .raised {
    color: ${({ theme }) => theme.colors.od_primary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
  }
  .closed {
    color: ${({ theme }) => theme.colors.od_secondary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .reviewed {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
  }
`;

export const IncidentsWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  .plus-icon-container{
    position: relative;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0 0 0.2rem 1.5rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const IncidentManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
`;

export const AddIncident = styled.button`
  position: absolute;
  top: 65px;
  right: -35px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
`;

export const IncidentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.incident_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  .input-like::placeholder{
    color: #9F9F9F;
    font-size: 16px;
  }
  .input::placeholder{
    color: #9F9F9F;
    font-size: 16px;
  }
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-33{
    flex: 0 0 33%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;

export const RowReviewValidation = styled.div`
  width:80%;
  color:red;
  font-size:16px;
`;
export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;
