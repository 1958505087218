/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';
import MealTracker from './MealTracker';
import { meal } from '../../../lookups/lookups';
import {
  BackButton, FoodHeadingBoxWrapper, Heading, ButtonBox, HeadingWrapper,
  RowWrapper, RowBoxWrapper, DateBox, BoxWrapper
} from './index.style';
import { getMealParticular, createMeal, updateMeal } from '../../../services/result.services';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import {
  getUserDetails, getLocalTimeOnly, getUtcDateTime,
  getUtcDateTimeIfDateIsPresent
} from '../../../helpers/utils';

const DailyFoodDrinkTracker = ({ setSelectedTracker }) => {
  const location = useLocation();
  const clientId = location.pathname.split('/')[2];
  const [breakFastDetails, setBreakFastDetails] = useState(meal);
  const [morningSnackDetails, setMorningSnackDetials] = useState(meal);
  const [lunchDetails, setLunchDetials] = useState(meal);
  const [afterNoonSnackDetails, setAfterSnackDetials] = useState(meal);
  const [eveningSnackDetails, setEveningSnackDetials] = useState(meal);
  const [dinnerDetails, setDinnerDetials] = useState(meal);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeBreakFast = (event) => {
    const { name, value } = event.target;
    setBreakFastDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeMornSnack = (event) => {
    const { name, value } = event.target;
    setMorningSnackDetials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeLunch = (event) => {
    const { name, value } = event.target;
    setLunchDetials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeAfterSnack = (event) => {
    const { name, value } = event.target;
    setAfterSnackDetials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeEveSnack = (event) => {
    const { name, value } = event.target;
    setEveningSnackDetials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeDinner = (event) => {
    const { name, value } = event.target;
    setDinnerDetials((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getMealDetailsHandler = async (mealType) => {
    const resp = await getMealParticular({ clientId, mealType, reportDate: moment(selectedDate).format('YYYY-MM-DD') });
    if (!resp) {
      return;
    } if (resp.status === 200) {
      if (mealType === 'BREAKFAST') {
        setBreakFastDetails(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'MORN_SNACK') {
        setMorningSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'LUNCH') {
        setLunchDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'AFTER_SNACK') {
        setAfterSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'DINNER') {
        setDinnerDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'EVE_SNACK') {
        setEveningSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      }
    }
  };

  const filterMealUpdateDetails = (mealDetail) => ({
    id: mealDetail.diaryId,
    mealType: mealDetail.mealType,
    mealTime: getUtcDateTimeIfDateIsPresent(`${mealDetail.mealDateTime.split(' ')[0]} ${mealDetail.mealTime}`),
    mealFood: mealDetail.mealFood,
    mealDrink: mealDetail.mealDrink,
    mealComments: mealDetail.mealComments,
    mealUpdatedBy: getUserDetails().firstName,
    currentLastUpdatedAt: mealDetail.lastUpdatedAt
  });

  const filterMealCreateDetails = (mealDetail) => ({
    clientId,
    mealTime: getUtcDateTime(mealDetail.mealTime),
    mealFood: mealDetail.mealFood,
    mealDrink: mealDetail.mealDrink,
    mealComments: mealDetail.mealComments,
    mealUpdatedBy: getUserDetails().firstName
  });

  const handleSaveBreakFast = async (diaryId) => {
    if (breakFastDetails.mealTime === '' || breakFastDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered',
        icon: 'error'
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(diaryId,
        { ...filterMealUpdateDetails(breakFastDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        getMealDetailsHandler('BREAKFAST');
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'BREAKFAST', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(breakFastDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('BREAKFAST');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const handleSaveMornSnack = async (diaryId) => {
    if (morningSnackDetails.mealTime === '' || morningSnackDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered!',
        icon: 'error',
        timer: 3000
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(diaryId,
        { ...filterMealUpdateDetails(morningSnackDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('MORN_SNACK');
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'MORN_SNACK', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(morningSnackDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('MORN_SNACK');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleSaveLunch = async (diaryId) => {
    if (lunchDetails.mealTime === '' || lunchDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered!',
        icon: 'error'
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(diaryId,
        { ...filterMealUpdateDetails(lunchDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('LUNCH');
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'LUNCH', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(lunchDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('LUNCH');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleSaveAfterSnack = async (diaryId) => {
    if (afterNoonSnackDetails.mealTime === '' || afterNoonSnackDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered!',
        icon: 'error'
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(
        diaryId,
        { ...filterMealUpdateDetails(afterNoonSnackDetails) }
      );
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('AFTER_SNACK');
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'AFTER_SNACK', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(afterNoonSnackDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('AFTER_SNACK');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleSaveEvenSnack = async (diaryId) => {
    if (eveningSnackDetails.mealTime === '' || eveningSnackDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered!',
        icon: 'error'
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(diaryId,
        { ...filterMealUpdateDetails(eveningSnackDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('EVE_SNACK');
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'EVE_SNACK', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(eveningSnackDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('EVE_SNACK');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleSaveDinner = async (diaryId) => {
    if (dinnerDetails.mealTime === '' || dinnerDetails.mealTime === '00:00:00') {
      swal({
        title: 'Meal time not entered!',
        icon: 'error'
      });
      return;
    }
    if (diaryId) {
      const resp = await updateMeal(diaryId,
        { ...filterMealUpdateDetails(dinnerDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal updated Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('DINNER');
      } else {
        swal({
          title: 'Failed to update meal!',
          icon: 'error',
          timer: 3000
        });
      }
    } else {
      const resp = await createMeal({ mealType: 'DINNER', reportDate: moment(selectedDate).format('YYYY-MM-DD'), ...filterMealCreateDetails(dinnerDetails) });
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        swal({
          title: 'Meal created Successfully!',
          icon: 'success',
          timer: 3000
        });
        getMealDetailsHandler('DINNER');
      } else {
        swal({
          title: 'Failed to create meal!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getMealDetailsHandler('BREAKFAST'),
      getMealDetailsHandler('MORN_SNACK'),
      getMealDetailsHandler('LUNCH'),
      getMealDetailsHandler('AFTER_SNACK'),
      getMealDetailsHandler('DINNER'),
      getMealDetailsHandler('EVE_SNACK')
    ]).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    setBreakFastDetails(meal);
    setMorningSnackDetials(meal);
    setLunchDetials(meal);
    setAfterSnackDetials(meal);
    setEveningSnackDetials(meal);
    setDinnerDetials(meal);
    setLoading(true);
    Promise.all([
      getMealDetailsHandler('BREAKFAST'),
      getMealDetailsHandler('MORN_SNACK'),
      getMealDetailsHandler('LUNCH'),
      getMealDetailsHandler('AFTER_SNACK'),
      getMealDetailsHandler('DINNER'),
      getMealDetailsHandler('EVE_SNACK')
    ]).then(() => setLoading(false));
  }, [selectedDate]);

  return (
    <>
      <FoodHeadingBoxWrapper>
        <BoxWrapper>
          <Heading>
            Food and Drinks Diary
          </Heading>
          <DateBox>
            <DatePicker
              timeFormat="HH:mm"
              className="input"
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={new Date(moment().subtract(1, 'days'))}
              placeholderText="Select date"
              selected={selectedDate}
              onChange={(selectedD) => setSelectedDate(selectedD)}
            />
          </DateBox>
        </BoxWrapper>
        <ButtonBox>
          <BackButton type="button" onClick={() => setSelectedTracker(0)}>Back</BackButton>
        </ButtonBox>
      </FoodHeadingBoxWrapper>
      <HeadingWrapper>
        <div className="first-heading">
          <span>TIME</span>
        </div>
        <div className="second-heading">
          <span>FOOD</span>
        </div>
        <div className="third-heading">
          <span>DRINKS</span>
        </div>
        <div className="fourth-heading">
          <span>COMMENTS</span>
        </div>
        <div className="fifth-heading">
          <span>ACTION</span>
        </div>
      </HeadingWrapper>
      <RowWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeBreakFast}
            handleSaveReport={handleSaveBreakFast}
            title="Breakfast"
            mealDetails={breakFastDetails}
          />
        </RowBoxWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeMornSnack}
            handleSaveReport={handleSaveMornSnack}
            title="Morning Snacks"
            mealDetails={morningSnackDetails}
          />
        </RowBoxWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeLunch}
            handleSaveReport={handleSaveLunch}
            title="Lunch"
            mealDetails={lunchDetails}
          />
        </RowBoxWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeAfterSnack}
            handleSaveReport={handleSaveAfterSnack}
            title="Afternoon Snacks"
            mealDetails={afterNoonSnackDetails}
          />
        </RowBoxWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeDinner}
            handleSaveReport={handleSaveDinner}
            title="Dinner"
            mealDetails={dinnerDetails}
          />
        </RowBoxWrapper>
        <RowBoxWrapper>
          <MealTracker
            handleChange={handleChangeEveSnack}
            handleSaveReport={handleSaveEvenSnack}
            title="Evening Snacks"
            mealDetails={eveningSnackDetails}
          />
        </RowBoxWrapper>
      </RowWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

DailyFoodDrinkTracker.propTypes = {
  setSelectedTracker: PropTypes.func.isRequired
};

export default DailyFoodDrinkTracker;
