/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dropdown, Modal, Spinner
} from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import moment from 'moment';
import swal from 'sweetalert';
import CustomModal from '../../components/CustomModal';
import {
  getUserDetails, checkValidationAddClients, getAgeFromDOB
} from '../../helpers/utils';
import { clientDetailsObj } from '../../lookups/lookups';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import { DropdownItem, DropdownToggle } from '../../styledComponents/common/dropdown.style';
import {
  TableWrapper, StyledTable, Th, Td, TableContainer
} from '../../styledComponents/common/table.style';
import {
  ClientsWrapper, ClientManagementHeader,
  FormWrapper, AssignHouseWrapper, IconContainer
} from './index.style';
import SelectContainer from '../../components/SelectContainer';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import plusIcon from '../../assets/logo/plus.svg';
import userIcon from '../../assets/logo/user.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import {
  addClient,
  updateClient,
  getClientList,
  getHouseList,
  addClientToHouse,
  listForms,
  getFormsForClient,
  toggleFormsForClient
} from '../../services/result.services';
import NoData from '../../components/NoData';

const AdminClients = () => {
  const history = useHistory();
  const tableBodyRef = useRef('');
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalAddClient, setShowCustomModalAddClient] = useState(false);
  const [showCustomModalAddHouse, setShowCustomModalAddHouse] = useState(false);
  const [showCustomModalAddForms, setShowCustomModalAddForms] = useState(false);
  const [showCustomModalUpdateClient, setShowCustomModalUpdateClient] = useState(false);
  const [selectedclient, setSelectedclient] = useState(null);
  const [selectedHouseCode, setSelectedHouseCode] = useState(null);
  const [validationMsgAddClient, setValidationMsgAddClient] = useState('');
  const [validationMsgUpdateClient, setValidationMsgUpdateClient] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [editableClientList, setEditableClientList] = useState([]);
  const [editableClient, setEditableClient] = useState(null);
  const [listHouses, setListHouses] = useState([]);
  const [updateHouseClient, setUpdateHouseClient] = useState(null);
  const [updatableHouses, setUpdatableHouses] = useState([]);
  const [clientDetails, setClientDetails] = useState(clientDetailsObj);
  const [listFormsData, setListFormsData] = useState([]);
  const [selectedFormsData, setSelectedFormsData] = useState([]);
  const [houseListForFilter, setHouseListForFilter] = useState([]);
  const [clientListToBeDisplayed, setClientListToBeDisplayed] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  const clearInput = () => {
    setClientDetails(clientDetailsObj);
  };

  const handleShowCustomModal = (operation) => {
    if (operation === 'add-client') {
      setShowCustomModalAddClient(true);
    } else if (operation === 'update-client') {
      setShowCustomModalUpdateClient(true);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(true);
    } else if (operation === 'add-forms') {
      setShowCustomModalAddForms(true);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-client') {
      setShowCustomModalAddClient(false);
    } else if (operation === 'update-client') {
      setShowCustomModalUpdateClient(false);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(false);
    } else if (operation === 'add-forms') {
      setShowCustomModalAddForms(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setEditableClient((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (value) => {
    if (value === 'ALL') {
      setSelectedValue(value);
      setClientListToBeDisplayed([...clientsList]);
    } else {
      setSelectedValue(value);
      setClientListToBeDisplayed(
        clientsList.filter(
          (client) => client.house?.houseCode === value
        )
      );
    }
  };

  const toggleFormsForClientHandler = async (payload) => {
    setLoading(true);
    const resp = await toggleFormsForClient(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleCheckChange = async (e, lookupId) => {
    const { checked } = e.target;
    // eslint-disable-next-line no-unused-expressions
    selectedclient && await toggleFormsForClientHandler({
      clientId: selectedclient.id,
      lookupId,
      toggle: checked
    });
  };

  const getClientsHandler = async () => {
    setLoading(true);
    const resp = await getClientList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredResp = resp.data.filter((client) => !client.deleted);
      const filteredClients = filteredResp.filter(
        (client) => getUserDetails().houseList.some(
          (houseCode) => ((houseCode === client.house?.houseCode) || (!client.house))
        )
      );
      setEditableClientList(filteredClients);
      let modifiedResp = filteredClients.map((ele) => (
        { ...ele, dob: ele.dob && getAgeFromDOB(ele.dob) }
      ));
      modifiedResp = modifiedResp.sort((a, b) => a.id - b.id);
      setClientsList(modifiedResp);
      setClientListToBeDisplayed(modifiedResp);
      setSelectedValue('ALL');
    } else {
      setLoading(false);
    }
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const houseListModified = filteredHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`
      }));
      setListHouses(filteredHouses);
      setHouseListForFilter([{ label: 'ALL', value: 'ALL' }, ...houseListModified]);
    } else {
      setListHouses([]);
    }
  };

  const getFormsHandler = async () => {
    setLoading(true);
    const resp = await listForms();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setListFormsData(resp.data);
      setLoading(false);
    } else {
      setListFormsData([]);
      setLoading(false);
    }
  };

  const getClientFormsData = async (clientId) => {
    setLoading(true);
    const resp = await getFormsForClient(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setSelectedFormsData(resp.data);
      setLoading(false);
    } else {
      setSelectedFormsData([]);
      setLoading(false);
    }
  };

  const handleSelectedclient = (client) => {
    setSelectedclient(client);
  };

  const handleAddClient = () => {
    handleShowCustomModal('add-client');
    clearInput();
  };

  const handleUpdateHouse = (client) => {
    if (client.house === null) {
      setUpdatableHouses(listHouses);
    } else {
      const houses = listHouses.filter((ele) => ele.houseCode !== client.house.houseCode);
      setUpdatableHouses(houses);
    }
    setUpdateHouseClient(client.id);
    handleShowCustomModal('add-house');
  };

  const handleAddForms = async () => {
    // eslint-disable-next-line no-unused-expressions
    selectedclient && await getClientFormsData(selectedclient.id);
    handleShowCustomModal('add-forms');
  };

  const handleUpdateClient = (client) => {
    const res = editableClientList.filter((item) => item.id === client.id);
    const modifyDob = (dob) => {
      const date = new Date(
        dob[0],
        dob[1] - 1,
        dob[2]
      );
      return moment(date).format('YYYY-MM-DD');
    };
    const updatedRes = {
      ...res[0],
      dob: Array.isArray(res[0].dob) ? modifyDob(res[0].dob) : res[0].dob
    };
    setEditableClient(updatedRes);
    handleShowCustomModal('update-client');
  };

  const handleUpdateHouseChange = (e) => {
    const { value } = e.target;
    setSelectedHouseCode(value);
  };

  const addClientsHandler = async () => {
    if (!checkValidationAddClients(clientDetails, setValidationMsgAddClient)) return;
    setLoading(true);
    const resp = await addClient(clientDetails);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientsHandler();
      handleCloseCustomModal('add-client');
      swal({
        title: 'Client created successfully!',
        icon: 'success',
        timer: 3000
      });
      clearInput();
    } else {
      setLoading(false);
      swal({
        title: 'Client creation failed!',
        icon: 'error',
        timer: 3000,
        buttons: { confirm: 'ok' }
      });
    }
  };

  const updateClientsHandler = async () => {
    if (!checkValidationAddClients(editableClient
      && editableClient, setValidationMsgUpdateClient)) {
      return;
    }
    setLoading(true);
    const resp = await updateClient(editableClient);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientsHandler();
      handleCloseCustomModal('update-client');
      swal({
        title: 'Client updated successfully!',
        icon: 'success',
        timer: 3000
      });
      clearInput();
    } else {
      setLoading(false);
      swal({
        title: 'Client updation failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };

  const addHouseToClientHandler = async () => {
    handleCloseCustomModal('add-house');
    setLoading(true);
    const resp = await addClientToHouse(
      {
        clientId: updateHouseClient,
        houseCode: selectedHouseCode
      }
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientsHandler();
      setSelectedHouseCode(null);
      setUpdateHouseClient(null);
    } else {
      setLoading(false);
      setSelectedHouseCode(null);
      setUpdateHouseClient(null);
    }
  };

  const handleDeleteClient = async (client) => {
    const res = editableClientList.filter((item) => item.id === client.id);
    const value = await swal({
      text: `Are you sure you want to delete ${client.name}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    const { house, ...rest } = res;
    if (value) {
      setLoading(true);
      const resp = await updateClient({ ...rest[0], deleted: true });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${client.name} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        await getClientsHandler();
      } else {
        setLoading(false);
        swal({
          title: 'Client deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const addFormToClientHandler = () => {
    handleCloseCustomModal('add-forms');
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    setLoading(true);
    getClientsHandler();
    getHousesHandler();
    getFormsHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Clients </span>
          </li>
        </ul>
      </BreadCrumb>
      <ClientsWrapper>
        <ClientManagementHeader>
          <h2>CLIENT MANAGEMENT</h2>
          <button type="button" onClick={() => handleAddClient()}>
            <img src={plusIcon} alt="plus icon" />
            Add Client
          </button>
        </ClientManagementHeader>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  clientsList.length !== 0
                && (
                <tr>
                  <Th width="40px">No.</Th>
                  <Th width="100px">Name</Th>
                  <Th width="40px">M / F</Th>
                  <Th width="40px">Age</Th>
                  <Th width="160px">Address</Th>
                  <Th width="120px">Phone</Th>
                  <Th width="140px">Email</Th>
                  <Th width="93px">
                    <span>House</span>
                    <SelectContainer
                      options={houseListForFilter.length > 0 && houseListForFilter}
                      placeholderText="House Code"
                      className="select-container"
                      isMultiple={false}
                      defaultValue={{
                        value: selectedValue,
                        label: selectedValue
                      }}
                      onChange={(e) => handleSelectChange(e.value)}
                    />
                  </Th>
                  <Th width="60px" align="right">Action</Th>
                  <Th width="40px" align="left" />
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                { clientListToBeDisplayed.length > 0
                  && clientListToBeDisplayed.map((client, index) => (
                    <tr key={client.id}>
                      <Td width="40px">{index + 1}</Td>
                      <Td width="100px" data-testid="clientName">{client.name}</Td>
                      <Td width="40px">{client.gender}</Td>
                      <Td width="40px">{client.dob}</Td>
                      <Td width="160px">
                        {`${client.addrLine1}, `}
                        {client.addrLine2 && `${client.addrLine2}, `}
                        {client.city}
                      </Td>
                      <Td width="120px">{client.phone}</Td>
                      <Td width="140px">{client.email}</Td>
                      <Td width="90px">{client.house && client.house.houseCode}</Td>
                      <Td width="60px" align="right">
                        <Dropdown
                          alignRight
                          onClick={() => {
                            handleSelectedclient(client);
                          }}
                        >
                          <DropdownToggle
                            id="options-button"
                            variant="borderless-dark"
                            bsPrefix="no-chevron"
                            size="sm"

                          >
                            <FaEllipsisV />
                          </DropdownToggle>
                          <Dropdown.Menu flip>
                            <DropdownItem>
                              <button type="button" onClick={() => handleUpdateClient(client)}>
                                Update Client
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" onClick={() => handleUpdateHouse(client)}>
                                Assign House
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" onClick={() => handleAddForms()}>
                                Assign Forms
                              </button>
                            </DropdownItem>
                            <DropdownItem>
                              <button type="button" onClick={() => history.push(`/admin/${client.name}/${client.id}/contacts`)}>
                                Manage Contacts
                              </button>
                            </DropdownItem>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Td>
                      <Td width="40px" align="left">
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteClient(client)}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>
                  ))}
                {
                  clientListToBeDisplayed.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </ClientsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddClient}
        setClose={() => {
          handleCloseCustomModal('add-client');
        }}
        title="Add Client"
        width="850px"
        onSubmit={addClientsHandler}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                value={clientDetails.name}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  required
                  id="gender"
                  name="gender"
                  value={clientDetails.gender}
                  data-testid="gender"
                  placeholder="gender"
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div className="input-with-label">
                <label htmlFor="dob">DOB</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="DOB"
                  name="dob"
                  value={clientDetails.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={clientDetails.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                placeholder="Phone Number"
                id="phone"
                name="phone"
                value={clientDetails.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="Address1">Address 1</label>
              <input
                id="address1"
                type="text"
                placeholder="Address 1"
                name="addrLine1"
                value={clientDetails.addrLine1}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                id="address2"
                placeholder="Address 2"
                name="addrLine2"
                value={clientDetails.addrLine2}
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={clientDetails.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state"
                  value={clientDetails.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                placeholder="Postcode"
                name="postCode"
                value={clientDetails.postCode}
                onChange={handleChange}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgAddClient !== '' && <h6 className="m-3 text-danger" data-testid="validation-add-client">{validationMsgAddClient}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateClient}
        setClose={() => {
          handleCloseCustomModal('update-client');
        }}
        title="Update Client"
        width="850px"
        onSubmit={updateClientsHandler}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                defaultValue={editableClient && editableClient.name}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  required
                  id="gender"
                  name="gender"
                  defaultValue={editableClient && editableClient.gender}
                  data-testid="gender"
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div className="input-with-label">
                <label htmlFor="dob">DOB</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="DOB"
                  name="dob"
                  defaultValue={editableClient && editableClient.dob && editableClient.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                defaultValue={editableClient && editableClient.email && editableClient.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                placeholder="Phone Number"
                id="phone"
                name="phone"
                defaultValue={editableClient && editableClient.phone && editableClient.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="Address1">Address 1</label>
              <input
                id="address1"
                type="text"
                placeholder="Address 1"
                name="addrLine1"
                defaultValue={editableClient && editableClient.addrLine1}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                id="address2"
                placeholder="Address 2"
                name="addrLine2"
                value={editableClient && editableClient.addrLine2}
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  defaultValue={editableClient && editableClient.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state"
                  defaultValue={editableClient && editableClient.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                placeholder="Postcode"
                name="postCode"
                defaultValue={editableClient && editableClient.postCode}
                onChange={handleChange}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgUpdateClient !== '' && <h6 className="text-danger" data-testid="validation-update-client">{validationMsgUpdateClient}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddHouse}
        setClose={() => {
          handleCloseCustomModal('add-house');
        }}
        title="Assign House"
        onSubmit={addHouseToClientHandler}
        operationName="Assign"
        isDisabled={!selectedHouseCode}
      >
        <AssignHouseWrapper>
          {selectedclient
            && (
            <div className="input-label-wrapper">
              <p className="label">Client</p>
              <div className="icon-name-wrapper">
                <img className="icon" src={userIcon} alt="user icon" />
                <span>{selectedclient.name}</span>
              </div>
            </div>
            )}
          <div className="input-label-wrapper">
            <label htmlFor="house">House Code</label>
            <select onChange={(event) => handleUpdateHouseChange(event)} id="house">
              <option disabled selected value="">Select House</option>
              { updatableHouses
              && updatableHouses.length > 0
              && updatableHouses.map((house) => (
                <option
                  key={house.id}
                  value={house.houseCode}
                >
                  {house.houseCode}
                </option>
              ))}
            </select>
          </div>
        </AssignHouseWrapper>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddForms}
        setClose={() => {
          handleCloseCustomModal('add-forms');
        }}
        title="Assign/Remove Form"
        onSubmit={addFormToClientHandler}
        operationName="Done"
      >
        <FormWrapper>
          {
            listFormsData && listFormsData.length > 0
            && listFormsData.map((form) => (
              <div className="input-with-full-width" key={form.id}>
                <label
                  className="container"
                  htmlFor={form.name}
                >
                  <span>{form.name}</span>
                  <input
                    name={form.name}
                    type="checkbox"
                    id={form.name}
                    onChange={(e) => handleCheckChange(e, form.id)}
                    defaultChecked={selectedFormsData.length > 0
                      && selectedFormsData.find((f) => f.lookupId === form.id)}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            ))
          }
        </FormWrapper>
      </CustomModal>
    </>
  );
};

export default AdminClients;
