/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import { nightChecklistData, checklistObj } from '../../../lookups/lookups';
import { checkIfAdminOnly, getUserDetails } from '../../../helpers/utils';
import {
  BackButton,
  HeadingBoxWrapper,
  ButtonsBox,
  Heading,
  TableWrapper,
  TableHeadingFirstBox,
  TableHeadingSecondBox,
  TableHeadingsWrapper,
  TableRowWrapper,
  ColumnOneBox,
  ColumnTwoBox,
  NameBox,
  DateBox,
  NightHeadingBoxWrapper
} from './index.style';
import tickIcon from '../../../assets/logo/tick.svg';
import userIcon from '../../../assets/logo/userIcon.svg';
import { createNightReport, updateNightReport, getNightReportParticular } from '../../../services/result.services';

const NightChecklist = ({ setSelectedTracker }) => {
  const location = useLocation();
  const [checklistData, setChecklistData] = useState([]);
  const [checklistDataStateObj, setChecklistDataStateObj] = useState(checklistObj);
  const [isFreshForm, setIsFreshForm] = useState(true);
  const [reportId, setReportId] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (isFreshForm) {
      setChecklistDataStateObj((prevState) => ({
        ...prevState,
        [name]: checked,
        [`${name}By`]: getUserDetails() && getUserDetails().firstName
      }));
    } else {
      setChecklistData((prevState) => ({
        ...prevState,
        [name]: checked,
        [`${name}By`]: getUserDetails() && getUserDetails().firstName
      }));
    }
  };

  const clientId = location.pathname.split('/')[2];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTodayDate = () => {
    const date = moment(selectedDate).format('DD');
    const month = moment(selectedDate).format('MMM').toUpperCase();
    const day = moment(selectedDate).format('dddd');
    return `${date} ${month}/${day}`;
  };

  const getNightReportHandler = async (id) => {
    setLoading(true);
    setChecklistData([]);
    setChecklistDataStateObj(checklistObj);
    const resp = await getNightReportParticular({ clientId: id, reportDate: moment(selectedDate).format('YYYY-MM-DD') });
    if (resp.status === 200) {
      setLoading(false);
      setIsFreshForm(false);
      setChecklistData(resp.data);
      setReportId(resp.data.reportId);
    } else if (resp.data && !resp.data.status) {
      setLoading(false);
      setIsFreshForm(true);
    } else {
      setLoading(false);
      swal({
        title: 'Failed to get NightCheckList!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const createNightReportHandler = async (payload) => {
    setLoading(true);
    const resp = await createNightReport(payload);
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Report Created Successfully!',
        icon: 'success',
        timer: 3000
      });
      getNightReportHandler(clientId);
    } else {
      setLoading(false);
      swal({
        title: 'Report Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const updateNightReportHandler = async (payload) => {
    setLoading(true);
    const resp = await updateNightReport(payload);
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Report Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getNightReportHandler(clientId);
    } else {
      setLoading(false);
      swal({
        title: 'Report Updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleSaveForm = () => {
    if (isFreshForm) {
      createNightReportHandler({ ...checklistDataStateObj, clientId, reportDate: moment(selectedDate).format('YYYY-MM-DD') });
    } else {
      updateNightReportHandler(
        {
          ...checklistData,
          id: reportId && reportId,
          currentLastUpdatedAt: checklistData.lastUpdatedAt
        }
      );
    }
  };

  function handleShowUserName(reported) {
    if (isFreshForm) {
      if (checklistDataStateObj[reported]) {
        return getUserDetails() && getUserDetails().firstName;
      }
      return '';
    }

    if (checklistData && !checklistData[reported]) {
      return '';
    }
    return checklistData[`${reported}By`];
  }

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getNightReportHandler(clientId);
    if (checklistData && Object.keys(checklistData).length > 0) {
      setIsFreshForm(true);
    } else {
      setIsFreshForm(false);
    }
  }, [clientId]);

  useEffect(() => {
    setChecklistData([]);
    setChecklistDataStateObj(checklistObj);
    getNightReportHandler(clientId);
  }, [selectedDate]);
  return (
    <>
      <NightHeadingBoxWrapper>
        <HeadingBoxWrapper>
          <Heading>
            Nightly Tasks Checklist
          </Heading>
          <DateBox>
            <DatePicker
              timeFormat="HH:mm"
              className="input w-100"
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={new Date(moment().subtract(1, 'days'))}
              placeholderText="Select date"
              selected={selectedDate}
              onChange={(selectedD) => setSelectedDate(selectedD)}
            />
          </DateBox>
        </HeadingBoxWrapper>
        <ButtonsBox>
          <BackButton type="button" onClick={() => setSelectedTracker(0)}>Back</BackButton>
          <BackButton type="button" onClick={handleSaveForm}>
            <img src={tickIcon} alt="tickIcon" />
            Save
          </BackButton>
        </ButtonsBox>
      </NightHeadingBoxWrapper>
      <div>
        <TableWrapper>
          <TableHeadingsWrapper>
            <TableHeadingFirstBox>
              Tasks
            </TableHeadingFirstBox>
            <TableHeadingSecondBox>
              {getTodayDate()}
            </TableHeadingSecondBox>
          </TableHeadingsWrapper>

          {
            nightChecklistData.map((task) => (
              <TableRowWrapper key={task.id}>
                <ColumnOneBox>
                  {task.taskName}
                </ColumnOneBox>
                <ColumnTwoBox>
                  <label
                    className={`${checkIfAdminOnly() ? 'container disabled' : 'container'}`}
                    htmlFor={task.id}
                  >
                    <NameBox>
                      <div className="icon-name-wrapper">
                        <img className="icon" src={userIcon} alt="user icon" />
                      </div>
                      <span>
                        {handleShowUserName(task.name)}
                      </span>
                    </NameBox>
                    <input
                      name={task.name}
                      type="checkbox"
                      id={task.id}
                      onChange={handleChange}
                      disabled={checkIfAdminOnly() && checkIfAdminOnly()}
                      checked={checklistData
                        ? checklistData[task.name]
                        : checklistDataStateObj[task.name]}
                    />
                    <span className="checkmark" />
                  </label>
                </ColumnTwoBox>
              </TableRowWrapper>
            ))
          }
        </TableWrapper>
        <LoadingModal
          show={show}
          onHide={handleClose}
          animation
          centered
          width="4%"
        >
          <Modal.Body className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Modal.Body>
        </LoadingModal>
      </div>
    </>
  );
};

NightChecklist.propTypes = {
  setSelectedTracker: PropTypes.func.isRequired
};

export default NightChecklist;
