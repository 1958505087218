/** @format */

import React, { useState, useRef, useEffect } from 'react';
import {
  Spinner
} from 'react-bootstrap';
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { AiOutlineEdit } from 'react-icons/ai';
import SelectContainer from '../../components/SelectContainer';
import CustomModal from '../../components/CustomModal';
import { checkIfHouseAdminOnly, getLocalTime, getUserDetails } from '../../helpers/utils';
import {
  NoticesWrapper,
  NoticeManagementHeader,
  IconButton,
  NoticeHeadingBoxWrapper,
  NoticeButton,
  FormWrapper,
  StyledSelectContainer
} from './index.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import {
  TableWrapper, StyledTable, Th, Td, TableContainer
} from '../../styledComponents/common/table.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import plusIcon from '../../assets/logo/plus.svg';
import {
  createNotice,
  getHouseList,
  getNoticeList,
  updateNotice
} from '../../services/result.services';
import { noticeObj } from '../../lookups/lookups';
import NoData from '../../components/NoData';

const AdminNotices = () => {
  const tableBodyRef = useRef('');
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [show, setShow] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const [isActiveNotices, setIsActiveNotices] = useState(true);
  const [validationMsgAddNotice, setValidationMsgAddNotice] = useState('');
  const [showCustomModalAddNotice, setShowCustomModalAddNotice] = useState(false);
  const [showCustomModalUpdateNotice, setShowCustomModalUpdateNotice] = useState(false);
  const [houseList, setHouseList] = useState([]);
  const [currentNoticeDetails, setCurrentNoticeDetails] = useState(noticeObj);
  const [currentNoticeDetailsUnChanged, setCurrentNoticeDetailsUnchanged] = useState(noticeObj);
  const [noticeListToBeDisplayed, setNoticeListToBeDisplayed] = useState([]);
  const [houseCodeList, setHouseCodeList] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState('');
  const [selectedValue, setSelectedValue] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const FIXEDTIMESTAMPSTART = 'T00:00:00.000+00:00';
  const FIXEDTIMESTAMPEND = 'T23:59:59.000+00:00';

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const displayInactiveNotices = () => {
    setSelectedValue('ALL');
    const inactiveNotices = noticeList.filter((notice) => notice.noticeStatus === 'INACTIVE');
    setIsActiveNotices(false);
    setNoticeListToBeDisplayed([...inactiveNotices]);
  };

  const displayActiveNotices = () => {
    setSelectedValue('ALL');
    const activeNotices = noticeList.filter((notice) => notice.noticeStatus === 'ACTIVE');
    setIsActiveNotices(true);
    setNoticeListToBeDisplayed([...activeNotices]);
  };

  const handleChange = (e) => {
    if (validationMsgAddNotice) {
      setValidationMsgAddNotice('');
    }
    const { name, value } = e.target;
    if (name === 'radio') {
      setCurrentNoticeDetails((prevState) => ({
        ...prevState,
        noticeStatus: value
      }));
    } else {
      setCurrentNoticeDetails((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (value) => {
    if (value === 'ALL') {
      setSelectedValue(value);
      setNoticeListToBeDisplayed([...noticeList.filter(
        (notice) => (isActiveNotices ? (notice.noticeStatus === 'ACTIVE') : (notice.noticeStatus !== 'ACTIVE'))
      )]);
    } else {
      setSelectedValue(value);
      setNoticeListToBeDisplayed(
        noticeList.filter(
          (notice) => (notice.houses.some((house) => (
            house.houseCode === value
          )))
          && (isActiveNotices ? (notice.noticeStatus === 'ACTIVE') : (notice.noticeStatus !== 'ACTIVE'))
        )
      );
    }
  };

  const compareCurrentNoticeDetails = () => (
    JSON.stringify(currentNoticeDetails) === JSON.stringify(currentNoticeDetailsUnChanged)
  );

  const handleSelectHouse = (selectedHouses) => {
    if (validationMsgAddNotice) {
      setValidationMsgAddNotice('');
    }
    const isAllSelected = selectedHouses.some((house) => house.value === 'ALL');
    if (isAllSelected) {
      setCurrentNoticeDetails((prevState) => ({
        ...prevState,
        houseCode: [{ label: 'ALL', value: 'ALL' }]
      }));
    } else {
      setCurrentNoticeDetails((prevState) => ({
        ...prevState,
        houseCode: selectedHouses
      }));
    }
  };

  const handleSelectStartDate = (date) => {
    if (validationMsgAddNotice) {
      setValidationMsgAddNotice('');
    }
    setCurrentNoticeDetails((prevState) => ({
      ...prevState,
      startDate: date
    }));
  };
  const handleSelectEndDate = (date) => {
    if (validationMsgAddNotice) {
      setValidationMsgAddNotice('');
    }
    setCurrentNoticeDetails((prevState) => ({
      ...prevState,
      endDate: date
    }));
  };

  const resetCurrentNoticeDetails = () => {
    setCurrentNoticeDetails(noticeObj);
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-notice') {
      setShowCustomModalAddNotice(false);
    } else if (operation === 'update-notice') {
      setShowCustomModalUpdateNotice(false);
    }
  };

  const checkAddNoticeValidation = () => {
    if (currentNoticeDetails.houseCode.length < 1) {
      setValidationMsgAddNotice('Please select a house');
      return false;
    }
    if (currentNoticeDetails.notice === '') {
      setValidationMsgAddNotice('Please enter the notice');
      return false;
    }
    if (moment(currentNoticeDetails.startDate).isAfter(currentNoticeDetails.endDate)) {
      setValidationMsgAddNotice('Start date must be earlier than end date');
      return false;
    }
    return true;
  };

  const openAddNoticeModal = () => {
    setValidationMsgAddNotice('');
    resetCurrentNoticeDetails();
    setShowCustomModalAddNotice(true);
  };

  const restructureHouseOption = (houses) => {
    if (houses.length === houseCodeList.length) {
      return [{ label: 'ALL', value: 'ALL' }];
    }
    return [...houses.map((ele) => ({ label: ele.houseCode, value: ele.houseCode }))];
  };

  const openUpdateNoticeModal = (id) => {
    const noticeToBeUpdated = noticeList.find((notice) => notice.id === id);
    const modifiedNotice = {
      startDate: new Date(noticeToBeUpdated.startDate),
      endDate: new Date(noticeToBeUpdated.endDate),
      noticeStatus: noticeToBeUpdated.noticeStatus,
      notice: noticeToBeUpdated.notice,
      houseCode: restructureHouseOption(noticeToBeUpdated.houses)
    };
    setSelectedNotice(id);
    setCurrentNoticeDetails(modifiedNotice);
    setCurrentNoticeDetailsUnchanged(modifiedNotice);
    setValidationMsgAddNotice('');
    setShowCustomModalUpdateNotice(true);
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const modifiedHouses = filteredHouses.map((house) => ({
        label: house.houseCode,
        value: house.houseCode
      }));
      setHouseList([{ label: 'ALL', value: 'ALL' }, ...modifiedHouses]);
      setHouseCodeList([...modifiedHouses.map((ele) => ele.value)]);
    } else {
      setHouseList([]);
    }
  };

  const getNoticeHandler = async () => {
    setLoading(true);
    const resp = await getNoticeList({
      pageSize: 10000,
      pageNumber: 0
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      if (!isActiveNotices) {
        setIsActiveNotices(true);
      }
      setLoading(false);
      const fiteredNotices = resp.data.filter(
        (notice) => notice.houses.some((house) => (
          getUserDetails().houseList.some(
            (houseCode) => houseCode === house.houseCode
          )))
      );
      setNoticeList([...fiteredNotices]);
      setNoticeListToBeDisplayed([...fiteredNotices.filter((notice) => notice.noticeStatus === 'ACTIVE')]);
    } else {
      setLoading(false);
    }
  };

  const getHouseCode = (house) => {
    if (house.length === 1 && house[0].value === 'ALL') {
      return houseCodeList;
    }
    return house.map((ele) => ele.value);
  };

  const handleUpdateNotice = async () => {
    if (!checkAddNoticeValidation()) return;
    setLoading(true);
    const resp = await updateNotice(selectedNotice, {
      ...currentNoticeDetails,
      startDate: `${moment(currentNoticeDetails.startDate).format().split('T')[0]}${FIXEDTIMESTAMPSTART}`,
      endDate: `${moment(currentNoticeDetails.endDate).format().split('T')[0]}${FIXEDTIMESTAMPEND}`,
      houseCode: getHouseCode(currentNoticeDetails.houseCode)
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Notice updated successfully!',
        icon: 'success',
        timer: 3000
      });
      getNoticeHandler();
      setValidationMsgAddNotice('');
      setShowCustomModalUpdateNotice(false);
      resetCurrentNoticeDetails();
    } else {
      setLoading(false);
      swal({
        title: 'Notice updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgAddNotice('');
      setShowCustomModalUpdateNotice(false);
      resetCurrentNoticeDetails();
    }
  };

  const handleAddNotice = async () => {
    if (!checkAddNoticeValidation()) return;
    setLoading(true);
    const resp = await createNotice({
      startDate: `${moment(currentNoticeDetails.startDate).format().split('T')[0]}${FIXEDTIMESTAMPSTART}`,
      endDate: `${moment(currentNoticeDetails.endDate).format().split('T')[0]}${FIXEDTIMESTAMPEND}`,
      notice: currentNoticeDetails.notice,
      houseCode: getHouseCode(currentNoticeDetails.houseCode)
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Notice created successfully!',
        icon: 'success',
        timer: 3000
      });
      getNoticeHandler();
      setValidationMsgAddNotice('');
      setShowCustomModalAddNotice(false);
      resetCurrentNoticeDetails();
    } else {
      setLoading(false);
      swal({
        title: 'Notice creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgAddNotice('');
      setShowCustomModalAddNotice(false);
      resetCurrentNoticeDetails();
    }
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    getHousesHandler();
    getNoticeHandler();
  }, []);

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Notices </span>
          </li>
        </ul>
      </BreadCrumb>
      <NoticesWrapper>
        <NoticeManagementHeader>
          <h2>NOTICE MANAGEMENT</h2>
          <button type="button" onClick={() => openAddNoticeModal()}>
            <img data-testid="plus-icon" src={plusIcon} alt="plus icon" />
            Add Notice
          </button>
        </NoticeManagementHeader>
        {
          noticeList.length !== 0
        && (
        <NoticeHeadingBoxWrapper>
          {isActiveNotices
            ? (
              <NoticeButton
                data-testid="inactiveIcon"
                onClick={() => displayInactiveNotices()}
              >
                Inactive
              </NoticeButton>
            )
            : (
              <NoticeButton
                onClick={() => displayActiveNotices()}
              >
                Active
              </NoticeButton>
            )}
        </NoticeHeadingBoxWrapper>
        )
        }
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  noticeList.length !== 0
                && (
                <tr>
                  <Th width="40px">No.</Th>
                  <Th width="140px">Created By</Th>
                  <Th width="140px">Created At</Th>
                  <Th width="120px">
                    <span>House</span>
                    <SelectContainer
                      options={houseList.length > 0 && houseList}
                      placeholderText="House Code"
                      className="select-container"
                      isMultiple={false}
                      defaultValue={{
                        value: selectedValue,
                        label: selectedValue
                      }}
                      onChange={(e) => handleSelectChange(e.value)}
                    />
                  </Th>
                  <Th width="280px">Notice</Th>
                  <Th width="80px" align="center">Action</Th>
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                {noticeListToBeDisplayed
                  && noticeListToBeDisplayed.map((notice, index) => (
                    <tr key={notice.id}>
                      <Td width="40px">
                        {
                          index + 1
                        }
                      </Td>
                      <Td width="140px">{notice.createdBy.email}</Td>
                      <Td width="140px">{getLocalTime(notice.createdAt)}</Td>
                      <Td width="120px">{notice.houses.map((ele) => ele.houseCode).join(', ')}</Td>
                      <Td width="280px" data-testid="notice">{notice.notice}</Td>
                      <Td width="80px" align="center">
                        <IconButton
                          type="button"
                          onClick={() => openUpdateNoticeModal(notice.id)}
                        >
                          <AiOutlineEdit color="#E86B55" />
                        </IconButton>
                      </Td>
                    </tr>
                  ))}
                {
                  noticeListToBeDisplayed.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </NoticesWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <LoadingModal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </LoadingModal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddNotice}
        setClose={() => {
          handleCloseCustomModal('add-notice');
        }}
        title="Add Notice"
        onSubmit={handleAddNotice}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="name">Select Houses</label>
              <div className="select-house">
                <StyledSelectContainer
                  id="name"
                  options={houseList}
                  isMultiple
                  defaultValue={currentNoticeDetails.houseCode}
                  onChange={handleSelectHouse}
                />
              </div>
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="startDate">Start Date</label>
                <DatePicker
                  id="startDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="Start Date"
                  selected={currentNoticeDetails.startDate}
                  onChange={(date) => handleSelectStartDate(date)}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  id="endDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="End Date"
                  selected={currentNoticeDetails.endDate}
                  onChange={(date) => handleSelectEndDate(date)}
                />
              </div>
            </div>
            <div className="input-with-full-width">
              <label htmlFor="dob">Content</label>
              <textarea
                id="notice"
                placeholder="Content"
                onChange={handleChange}
                value={currentNoticeDetails.notice}
                name="notice"
                required
              />
            </div>
            { validationMsgAddNotice !== '' && <h6 className="mt-3 ml-2 text-danger">{validationMsgAddNotice}</h6>}
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateNotice}
        setClose={() => {
          handleCloseCustomModal('update-notice');
        }}
        isDisabled={compareCurrentNoticeDetails()}
        title="Update Notice"
        onSubmit={handleUpdateNotice}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="houses">Select Houses</label>
              <div className="select-house">
                <SelectContainer
                  id="houses"
                  options={checkIfHouseAdminOnly() ? houseList.slice(1) : houseList}
                  isMultiple
                  defaultValue={currentNoticeDetails.houseCode}
                  onChange={handleSelectHouse}
                />
              </div>
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="startDate">Start Date</label>
                <DatePicker
                  id="startDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="Start Date"
                  selected={currentNoticeDetails.startDate}
                  onChange={(date) => handleSelectStartDate(date)}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  id="endDate"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="End Date"
                  selected={currentNoticeDetails.endDate}
                  onChange={(date) => handleSelectEndDate(date)}
                />
              </div>
            </div>
            <div className="input-with-full-width">
              <label htmlFor="notice">Content</label>
              <textarea
                id="notice"
                placeholder="Content"
                onChange={handleChange}
                value={currentNoticeDetails.notice}
                name="notice"
                required
              />
            </div>
            <div className="input-with-half-width">
              <div>
                <label
                  className="container"
                  htmlFor="active"
                  key="active"
                >
                  <span>Active</span>
                  <input
                    type="radio"
                    id="active"
                    defaultChecked={currentNoticeDetails.noticeStatus === 'ACTIVE'}
                    onChange={handleChange}
                    value="ACTIVE"
                    name="radio"
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="centered-line" />
              <div>
                <label
                  className="container"
                  htmlFor="inactive"
                  key="inactive"
                >
                  <span>Inactive</span>
                  <input
                    type="radio"
                    id="inactive"
                    defaultChecked={currentNoticeDetails.noticeStatus === 'INACTIVE'}
                    value="INACTIVE"
                    onChange={handleChange}
                    name="radio"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </FormWrapper>
          { validationMsgAddNotice !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsgAddNotice}</h6>}
        </div>
      </CustomModal>
    </>
  );
};

export default AdminNotices;
