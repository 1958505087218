/** @format */

import styled from 'styled-components';

export const StyledLogin = styled.div`
  background: #fff;
  width: 100%;
  height: 100vh;
`;

export const Image = styled.img`
  margin: 1rem;
  width: 10%;
  height: auto;
  position: relative;
  z-index: 1;
`;

export const UpperCurve = styled.img`
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 30%;
  top: 0;
  left: 0;
  margin-left: 0;
  opacity: 0.9;
`;

export const LowerCurve = styled.img`
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  margin-left: 0;
  opacity: 0.6;
`;

export const MainWrapper = styled.div`
  display: grid;
  max-width: 74%;
  grid-template-columns: 58% 42%;
  margin: 0 auto;

  .form-part {
    margin-top: 15%;
  }
  .label {
    color: ${({ theme }) => theme.colors.od_text_color};
    font-size: 16px;
    margin: 1rem 0 0.5rem 1rem;
    text-decoration: underline;
  }
`;

export const StyledHeading = styled.p`
  margin: 0 auto;
  font-family: Poppins;
  position: relative;
  z-index: 10;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1;
  background: #58c7ab;
  max-width: 540px;
  background: -webkit-linear-gradient(to left, #58c7ab 0%, #004937 92.73%);
  background: -moz-linear-gradient(to left, #58c7ab 0%, #004937 92.73%);
  background: linear-gradient(to left, #58c7ab 0%, #004937 92.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MainImage = styled.img`
  min-width: 500px;
  width: 45%;
  position: absolute;
  bottom: 0%;
  left: 14%;
`;

export const LoginTitle = styled.p`
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  color: #07221b;
  opacity: 0.8;
  margin: 2rem 0.75rem;
  & > span {
    font-weight: 400;
  }
`;

export const CredentialIcon = styled.img`
  width: 25px;
`;

export const FormContainer = styled.div`
  position: relative;
  max-width: 500px;
`;

export const InputRow = styled.div`
  background: #e8f8f8;
  padding: 0.75rem 0.5rem;
  margin: 0.75rem;
  display: flex;
  input {
    border: none;
    background: none;
    width: 90%;
    margin-left: 20px;
  }
  input:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  background-color: #58c7ab;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -20px;
  bottom: 80px;
  border: 0.6rem solid #fff;
  img {
    width: 60%;
    margin-left: 0;
  }
`;

export const ErrorMsg = styled.h6`
  color: #d8000c;
  margin: 1rem;
`;

export const ToastWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;
