/** @format */
import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const IncidentsWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
      width: 12px !important;
      height: auto;
    }
  
  .select-wrapper{
    width: 85%;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0 1.2rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const Dot = styled.span`
  margin-left: 10px;
  color: #ff0000 !important;
`;

export const StatusContainer = styled.div`
  span{
    text-align: center;
    display: inline-block;
    padding: 0.5rem 0;
    width: 90px;
    border-radius: 7px;
  }
  .raised{
    color: ${({ theme }) => theme.colors.od_primary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
  }
  .closed{
    color: ${({ theme }) => theme.colors.od_secondary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .reviewed{
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
  }
`;

export const IncidentManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;
    min-width: 92px;
    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  div.form-control-wrapper{
    width: 49%;
    display: flex;
    justify-content: space-between;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type="date"]{
    color: gray;
  }

  select:invalid {
    color: gray;
  }

  input:focus, select:focus{
    outline: none;
  }
`;

export const AssignHouseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label, label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper{
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      .icon{
        width: 25px;
        margin-right: 10px;
      }
    }
  }
 
  select:invalid {
    color: gray;
  }

  select:focus{
    outline: none;
  }

`;
