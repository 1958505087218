/** @format */

import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100vh;

  .logo {
    width: 180px;
    height: auto;
  }
  .w-100 {
    width: 100%;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button {
    outline: none;
    border: none;
    background: none;
  }
  .back-btn {
    color: ${({ theme }) => theme.colors.od_placeholder};
    img {
      margin: 0 0.75rem 0.25rem 0;
    }
  }
  .title {
    color: ${({ theme }) => theme.colors.od_text_color};
    font-size: 18px;
    font-weight: 700;
    margin: 1rem auto 1rem 1rem;
  }
`;

export const UpperCurve = styled.img`
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 30%;
  top: 0;
  left: 0;
  margin-left: 0;
  opacity: 0.9;
`;

export const LowerCurve = styled.img`
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  margin-left: 0;
  opacity: 0.6;
`;

export const FormContainer = styled.div`
  position: relative;
  min-width: 450px;
  margin: 1rem auto;
  padding: 1rem;
  border: 1px solid #f2e4d8;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
`;

export const InputRow = styled.div`
  background: #e8f8f8;
  padding: 0.75rem 0.5rem;
  margin: 0.75rem;
  display: flex;
  border: ${({ error }) => (error ? '1px solid red' : 'none')};
  input {
    border: none;
    background: none;
    width: 90%;
    margin-left: 20px;
  }
  input:focus {
    outline: none;
  }
`;

export const CredentialIcon = styled.img`
  width: 25px;
`;

export const ErrorMsg = styled.h6`
  color: #d8000c;
  margin: 1rem;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.od_secondary};
  color: ${({ theme }) => theme.colors.od_neutral};
  display: flex;
  width: 95%;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.5rem;
  margin: 1.5rem 0 0 0.75rem;

  &:disabled {
    opacity: 0.6;
  }
  img {
    width: 60%;
    margin-left: 0;
  }
`;
