import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const NotesHeadingBoxWrapper = styled.div`
  height: 73px;
  background: ${({ theme }) => theme.colors.od_primary_transparent};
  display:flex;
  justify-content:end;
`;

export const NotesToolBox = styled.div`
  display:flex;
  margin-left: auto;
`;

export const NotesButton = styled.button`
  width: 92px;
  height: 42px;
  border-radius: 10px;
  margin:auto;
  margin-right:20px;
  font-size:13px;
  font-weight:700;
  border:none;
  background: ${({ theme }) => theme.colors.od_primary};
  color: ${({ theme }) => theme.colors.od_neutral};
`;

export const NotesDatePicker = styled(DatePicker)`
  border:none;
  outline:none;
`;

export const NotesSubmitButton = styled(NotesButton)`
  border:none;
  font-weight: bold;
  font-size: 16px;
  cursor:pointer;
`;

export const NotesSearchBarWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 10px;
  width:262px;
  height:42px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #DADFEF;
  box-sizing: border-box;
  border-radius: 10px;
  margin:auto;
  margin-right:20px;
  display:flex;
`;

export const IconImage = styled.div`
  height: 20px;
  padding-right: 10px;
`;

export const ToastWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;
