/** @format */

import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  Switch, Route, useHistory, Redirect
} from 'react-router-dom';
import Client from '../pages/Client/Client';
import AdminHouses from '../pages/AdminHouse/AdminHouses';
import AdminClients from '../pages/AdminClients/AdminClients';
import AdminIncidents from '../pages/AdminIncidents/AdminIncidents';
import AdminUsers from '../pages/AdminUsers/AdminUsers';
import Home from '../pages/Home';
import { getUserDetails } from '../helpers/utils';
import { getClientListByHouse } from '../services/result.services';
import IncidentTabs from '../components/IncidentManagement/IncidentTab/IncidentTabs';
import AdminNotices from '../pages/AdminNotices/AdminNotices';
import EmergencyPlan from '../pages/EmergencyPlan/EmergencyPlan';
import AdminEmergencyPlan from '../pages/AdminEmergencyPlan/AdminEmergencyPlan';
import AdminClientReports from '../pages/AdminClientReports/AdminClientReports';
import AdminClientContacts from '../pages/AdminClientContacts/AdminClientContacts';
import ImportantContacts from '../pages/ImportantContacts/ImportantContacts';
import AdminImportantcontacts from '../pages/AdminImportantContacts/AdminImportantContacts';
import Handovers from '../pages/Handover/Handovers/Handovers';
import AdminHandoverSummary from '../pages/AdminHandoverSummary/AdminHandoverSummary';
import AdminNotes from '../pages/AdminNotes/AdminNotes';

const style = {
  minHeight: 'calc(85vh - 100px)',
  borderRadius: '5px',
  padding: '15px 40px',
  margin: '0 0 3.5rem'
};

const UserRoutes = ({ setHouseChanged, setHouseListChanged }) => {
  const history = useHistory();
  const [clientList, setClientList] = useState([]);

  const getClientsByHouse = async () => {
    const resp = await getClientListByHouse(getUserDetails().houseCode);
    if (!resp) return;
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      setClientList(filteredResp);
    } else {
      setClientList([]);
    }
  };

  useEffect(() => {
    if (!getUserDetails().houseCode) return;
    getClientsByHouse();
  }, [getUserDetails().houseCode]);

  useEffect(() => {
    if (history.location.pathname === '/') {
      history.push('/home');
    }
  }, [history, clientList]);
  return (
    <>
      <Row
        className="pt-4 justify-content-center"
        style={{ marginTop: '50px' }}
      >
        <Col md={12}>
          <Container fluid style={style}>
            <Switch>
              <Route path="/client/:id/:name">
                <Client />
              </Route>
              <Route exact path="/home" render={() => <Home setHouseChanged={setHouseChanged} />} />
              <Route exact path="/handover" component={Handovers} />
              <Route exact path="/admin/houses" render={() => <AdminHouses setHouseListChanged={setHouseListChanged} />} />
              <Route exact path="/admin/clients" component={AdminClients} />
              <Route exact path="/admin/:client/:id/contacts" component={AdminClientContacts} />
              <Route exact path="/admin/users" component={AdminUsers} />
              <Route exact path="/admin/notices" component={AdminNotices} />
              <Route exact path="/admin/incidents" component={AdminIncidents} />
              <Route exact path="/admin/incidents/:incidentType/open/:id" render={() => <IncidentTabs isOpen />} />
              <Route exact path="/admin/incidents/:incidentType/closed/:id" render={() => <IncidentTabs />} />
              <Route exact path="/emergencyplan" render={() => <EmergencyPlan />} />
              <Route exact path="/admin/emergencyplan" render={() => <AdminEmergencyPlan />} />
              <Route exact path="/admin/clientreports" render={() => <AdminClientReports />} />
              <Route exact path="/importantcontact" render={() => <ImportantContacts />} />
              <Route exact path="/admin/importantcontact" render={() => <AdminImportantcontacts />} />
              <Route exact path="/admin/handovers" render={() => <AdminHandoverSummary />} />
              <Route exact path="/admin/notes" render={() => <AdminNotes />} />
              <Route path="/*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Container>
        </Col>
      </Row>
    </>
  );
};

UserRoutes.propTypes = {
  setHouseChanged: PropTypes.func.isRequired,
  setHouseListChanged: PropTypes.func.isRequired
};

export default UserRoutes;
