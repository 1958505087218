/** @format */

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import { checkForSpecificShift, compare, getUserDetails } from '../../../helpers/utils';
import {
  StyledTable, TableContainer, TableWrapper, Th, Td
} from '../../../styledComponents/common/table.style';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import detailIcon from '../../../assets/logo/detail.svg';
import addIcon from '../../../assets/logo/add_icon.svg';
import caretRightIcon from '../../../assets/logo/caret_right.svg';
import homeIcon from '../../../assets/logo/home.svg';
import deleteIcon from '../../../assets/logo/close_cross.svg';
import {
  IconContainer, HandoversWrapper, HandoverHeader, AddHandover
} from './index.style';
import { BreadCrumb } from '../index.style';
import NoData from '../../../components/NoData';
import { initialHandoverSummary } from '../../../lookups/lookups';
import Handover from '../Handover';
import { getHandoverList, getUserListByHouse, updateHandover } from '../../../services/result.services';

const Handovers = () => {
  const tableBodyRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRefetchRequired, setIsRefetchRequired] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [handoverList, setHandoverList] = useState([]);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [showHandoverSummary, setShowHandoverSummary] = useState(false);
  const [selectedHandover, setSelectedHandover] = useState(null);

  const handleShowHandoverDetails = () => {
    setShowHandoverSummary((prevState) => !prevState);
  };

  const handleRefetchRequirement = () => {
    setIsRefetchRequired((prevState) => !prevState);
  };

  const checkDisablity = (handover) => (
    getUserDetails().email !== handover.handoverBy?.email
  );

  const handleDeleteHandover = async (handover) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateHandover(handover.id, {
        ...handover,
        handoverToId: handover.handoverTo?.id,
        houseCode: getUserDetails().houseCode,
        deleted: true
      });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Handover deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        handleRefetchRequirement();
      } else {
        setLoading(false);
        swal({
          title: 'Handover deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const getUsersHandler = async () => {
    const resp = await getUserListByHouse(getUserDetails().houseCode);
    if (!resp) return;
    if (resp.status === 200) {
      const fitleredUser = resp.data.filter(
        (user) => !user.deleted && user.email !== getUserDetails().email
      );
      const userList = fitleredUser && fitleredUser.map((ele) => (
        {
          label: `${ele.firstName} ${ele.lastName}`,
          value: `${ele.firstName} ${ele.lastName}`,
          id: ele.id,
          name: 'handoverTo'
        }
      ));
      setStaffList(userList);
    }
  };

  const getHandoverSummaryList = async (payload) => {
    setLoading(true);
    const resp = await getHandoverList(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredNotDeletedSummary = resp.data.filter((ele) => !ele.deleted).sort(compare);
      setHandoverList(filteredNotDeletedSummary);
    } else {
      setLoading(false);
    }
  };

  const getHandoverSummaryListWithParams = () => {
    getHandoverSummaryList({
      start: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
      pageNumber: 0,
      pageSize: 1000,
      houseCode: getUserDetails().houseCode
    });
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    if (tableBodyRef.current) {
      checkScrollbar();
    }
  });

  useEffect(() => {
    getUsersHandler();
  }, []);

  useEffect(() => {
    getHandoverSummaryListWithParams();
  }, [isRefetchRequired]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Handovers </span>
            {
              showHandoverSummary && (
                <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
              )
            }
          </li>
          {
            showHandoverSummary && (
              <li>
                <span> Handover Summary </span>
              </li>
            )
          }
        </ul>
      </BreadCrumb>
      {
        showHandoverSummary && selectedHandover ? (
          <Handover
            staffList={staffList}
            handleShowHandoverDetails={handleShowHandoverDetails}
            handoverDetails={selectedHandover}
            handleRefetchRequirement={handleRefetchRequirement}
            isDisabled={isDisabled}
          />
        ) : (
          <>
            <HandoversWrapper>
              <HandoverHeader>
                <h2>HANDOVERS</h2>
              </HandoverHeader>
              <div className="plus-icon-container">
                <AddHandover onClick={() => {
                  setSelectedHandover(initialHandoverSummary);
                  setIsDisabled(false);
                  setShowHandoverSummary(true);
                }}
                >
                  <img data-testid="add-button" src={addIcon} alt="add icon" />
                </AddHandover>
              </div>
              <TableContainer>
                <TableWrapper>
                  <StyledTable>
                    <thead
                      className={`${
                        isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                      }`}
                    >
                      {
                        handoverList.length !== 0
                      && (
                      <tr>
                        <Th data-testid="No" width="60px">No.</Th>
                        <Th width="140px">Handover By</Th>
                        <Th width="140px">Handover To</Th>
                        <Th width="140px">Handover For</Th>
                        <Th width="100px">Shift</Th>
                        <Th width="140px">Handover Time</Th>
                        <Th width="100px" align="left"> </Th>
                      </tr>
                      )
                      }
                    </thead>
                    <tbody ref={tableBodyRef}>
                      {handoverList.length > 0
                        && handoverList.map((handover, index) => (
                          <tr key={handover.id}>
                            <Td width="60px">{index + 1}</Td>
                            <Td width="140px">{`${handover.handoverBy.firstName} ${handover.handoverBy.lastName}`}</Td>
                            <Td width="140px">{`${handover.handoverTo.firstName} ${handover.handoverTo.lastName}`}</Td>
                            <Td width="140px">{moment(handover.handoverDate).format('DD/MM/YYYY')}</Td>
                            <Td width="100px">{checkForSpecificShift(handover.handoverShift)}</Td>
                            <Td width="140px">{moment(handover.handoverTime).format('DD/MM/YYYY HH:mm:ss')}</Td>
                            <Td width="100px" align="left">
                              <IconContainer
                                onClick={() => {
                                  setSelectedHandover({
                                    ...handover,
                                    handoverTime: new Date(handover.handoverTime),
                                    handoverDate: new Date(handover.handoverDate)
                                  });
                                  setIsDisabled(checkDisablity(handover));
                                  setShowHandoverSummary(true);
                                }}
                              >
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                              {
                                !checkDisablity(handover)
                                && (
                                  <IconContainer
                                    className="delete-btn"
                                    onClick={() => handleDeleteHandover(handover)}
                                  >
                                    <img src={deleteIcon} alt="delete icon" />
                                  </IconContainer>
                                )
                              }
                            </Td>
                          </tr>
                        ))}
                      {
                          handoverList.length === 0 && <NoData />
                      }
                    </tbody>
                  </StyledTable>
                </TableWrapper>
              </TableContainer>
            </HandoversWrapper>
            <LoadingModal
              show={show}
              onHide={handleClose}
              animation
              centered
              width="4%"
            >
              <Modal.Body className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </Modal.Body>
            </LoadingModal>
          </>
        )
      }
    </>
  );
};

export default Handovers;
