import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const IncidentDetailBoxContainer = styled.div`
  padding: 2rem 3rem;
  display: flex;
  flex-wrap: wrap;
  .w-33 {
    flex: 0 0 32.5%;
	height: 80px;
  }
  .dot{
	display: inline-block;
	position: absolute;
	left: 1.3rem;
	top: 1.7rem;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.od_secondary};
  }
  .flex {
    display: flex;
	position: relative;
    flex-direction: column;
    margin: 0.5rem 0.8% 0.5rem 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
	border: 1px solid #DDDDDD;
	border-radius: 10px;
	font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
    .label {
      font-size: 18px;
	  padding-left: 2rem;
	  padding-top: 1.2rem;
      margin: 0 7px;
	  color: ${({ theme }) => theme.colors.od_placeholder};
    }
	.detail{
	  font-size: 19px;
	  margin: 0 7px;
	  padding-left: 2rem;
	  padding-bottom: 1.2rem;
	  color: ${({ theme }) => theme.colors.od_text_color};
	}
  }
`;
