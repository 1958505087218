/* eslint-disable import/prefer-default-export */
/** @format */

import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const LoadingModal = styled(Modal)`
  .modal-dialog {
    max-width: ${(props) => props.width};
  }
  .modal-content {
    background: rgba(255, 255, 255, 0.01);
    border: none;
    width: auto;
    height: auto;
  }
`;
