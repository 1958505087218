/** @format */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../pages/Login/Login';
import PrivateRoutes from './PrivateRoutes';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';

const Routes = () => (
  <>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/resetPassword" component={ResetPassword} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Route path="/changePassword" component={ChangePassword} />
      <Route path="/">
        <PrivateRoutes />
      </Route>
    </Switch>
  </>
);

export default Routes;
