/** @format */

import React, {
  useEffect,
  useState,
  useMemo
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import EditableCell from '../EditableCell';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../Table/Table';
import { getUserDetails, checkIfAdminOnly } from '../../helpers/utils';
import {
  getNotes,
  addNote,
  updateNote
} from '../../services/result.services';
import {
  NotesHeadingBoxWrapper,
  NotesToolBox,
  NotesButton,
  NotesDatePicker,
  NotesSubmitButton,
  NotesSearchBarWrapper,
  IconImage,
  ToastWrapper
} from './index.styles';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import NoData from '../NoData';

const NotesTable = () => {
  const { pathname } = useLocation();
  const params = pathname && pathname.split('/');
  const clientId = params[2];
  const [show, setShow] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState('');
  const [data, setData] = useState([]);
  const [isTodayNotes, setIsTodayNotes] = useState(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [isZeroNotes, setZeroNotes] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const newDate = new Date();
  const utcOffset = newDate.getTimezoneOffset();
  const FIXEDTIMESTAMP = '.000+00:00';
  const todayDate = moment().format().split('T')[0];

  const sevenDayBeforeYesterday = moment(`${todayDate}T02:00:00`)
    .add(utcOffset, 'minutes')
    .subtract(7, 'days')
    .format()
    .split('+')[0];

  const yesterday = moment(`${todayDate}T02:00:00`)
    .add(utcOffset, 'minutes')
    .format()
    .split('+')[0];

  const today = moment(`${todayDate}T02:00:00`)
    .add(1, 'days')
    .add(utcOffset, 'minutes')
    .format()
    .split('+')[0];

  const dailyNoteRequestObj = {
    start: `${yesterday}${FIXEDTIMESTAMP}`,
    end: `${today}${FIXEDTIMESTAMP}`,
    pageSize: 100,
    pageNumber: 0,
    houseCode: getUserDetails().houseCode,
    clientId
  };

  const checkIfUserHasAccess = () => {
    const userDetails = getUserDetails();
    const haveAccess = userDetails.roles.some((role) => ['ADMIN'].indexOf(role) > -1);
    return haveAccess;
  };
  const modifyDate = (date) => {
    const modifiedDate = moment(date, 'YYYY-MM-DD hh:mm:ss')
      .subtract(utcOffset, 'minutes')
      .format('DD MMM YYYY');
    return modifiedDate;
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getNotesHandler = async (reqObj, whichDay) => {
    try {
      const resp = await getNotes(reqObj);
      if (resp.status === 200) {
        setLoading(false);
        if (whichDay === 'prev') {
          setIsTodayNotes(false);
        } else if (whichDay === 'today') {
          setIsTodayNotes(true);
        } else if (whichDay === 'anyDay') {
          setIsTodayNotes(false);
        }
        if (whichDay === 'today' && resp.data.length === 0) {
          if (checkIfUserHasAccess()) {
            setZeroNotes(true);
            return;
          }
          setZeroNotes(false);
          setData([{
            id: uuidv4(),
            note: 'Click on add to start adding notes'
          }]);
        } else if (resp.data.length === 0) {
          setZeroNotes(true);
        } else {
          setZeroNotes(false);
          // eslint-disable-next-line
          const modifiedResp = resp.data.map((ele) => {
            return { ...ele, createdAt: modifyDate(ele.createdAt) };
          });
          setData(modifiedResp);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getNotesHandler(dailyNoteRequestObj, 'today');
  }, [clientId]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  const addMessage = async (message) => {
    setLoading(true);
    try {
      const resp = await addNote({
        houseCode: message.houseCode,
        note: message.note,
        startTime: message.startTime,
        endTime: message.endTime,
        clientId
      });
      if (resp.status === 200) {
        setLoading(false);
        getNotesHandler(dailyNoteRequestObj, 'today');
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const submitMessage = async (item) => {
    const message = {
      ...item
    };
    addMessage(message);
  };

  const cancelLineItem = (row) => {
    if (row.original.user === undefined) {
      const newData = data.slice(0, data.length - 1);
      setData([...newData]);
    }
  };

  const addLineItem = (row) => {
    const noteInput = document.getElementsByName(`${row.id}note`)[0];
    const startTimeInput = document.getElementsByName(`${row.id}startTime`)[0];
    const endTimeInput = document.getElementsByName(`${row.id}endTime`)[0];
    if (noteInput.value && startTimeInput.value && endTimeInput.value) {
      let note = '';
      if (noteInput) {
        note = noteInput.value;
      }
      const lineItem = {
        id: data[parseInt(row.id, 10)].id,
        note,
        startTime: startTimeInput.value ? `${moment().format('YYYY-MM-DD')} ${startTimeInput.value}:00` : null,
        endTime: endTimeInput.value ? `${moment().format('YYYY-MM-DD')} ${endTimeInput.value}:00` : null,
        houseCode: getUserDetails().houseCode
      };
      submitMessage(lineItem);
      setEditable('');
    } else if (!noteInput.value) {
      setAlertMessage('Notes cannot be blank');
      setAlertShow(true);
    } else if (!startTimeInput.value) {
      setAlertMessage('Start Time cannot be blank');
      setAlertShow(true);
    } else if (!endTimeInput.value) {
      setAlertMessage('End Time cannot be blank');
      setAlertShow(true);
    }
  };

  const updateLineItem = async (row) => {
    const noteInput = document.getElementsByName(`${row.id}note`)[0];
    const startTimeInput = document.getElementsByName(`${row.id}startTime`)[0];
    const endTimeInput = document.getElementsByName(`${row.id}endTime`)[0];
    const dateInput = document.getElementsByName(`${row.id}createdAt`)[0];
    const date = moment(dateInput.textContent, 'DD MMM YYYY').format('YYYY-MM-DD');
    if (noteInput.value && startTimeInput.value && endTimeInput.value) {
      setEditable('');
      let note = '';
      if (noteInput) {
        note = noteInput.value;
      }
      const startTime = startTimeInput.value
        ? (startTimeInput.value.length === 5 ? `${date} ${startTimeInput.value}:00` : `${date} ${startTimeInput.value}`) : null;
      const endTime = endTimeInput.value
        ? (endTimeInput.value.length === 5 ? `${date} ${endTimeInput.value}:00` : `${date} ${endTimeInput.value}`) : null;

      setLoading(true);
      try {
        const resp = await updateNote({
          note, id: row.original.id, startTime, endTime
        });
        if (resp.status === 200) {
          setLoading(false);
          getNotesHandler(dailyNoteRequestObj, 'today');
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else if (!noteInput.value) {
      setAlertMessage('Notes cannot be blank');
      setAlertShow(true);
    } else if (!startTimeInput.value) {
      setAlertMessage('Start Time cannot be blank');
      setAlertShow(true);
    } else if (!endTimeInput.value) {
      setAlertMessage('End Time cannot be blank');
      setAlertShow(true);
    }
  };

  const addNewRow = () => {
    setData((prevData) => [...prevData, {
      id: uuidv4(), note: '', startTime: '', endTime: ''
    }]);
    setEditable(data.length.toString());
  };

  // func to fetch previous 7 day notes
  const fetchPreviouDayNotes = () => {
    setLoading(true);
    const prevDayNotesReqObj = {
      ...dailyNoteRequestObj,
      start: `${sevenDayBeforeYesterday}${FIXEDTIMESTAMP}`,
      end: `${yesterday}${FIXEDTIMESTAMP}`
    };
    getNotesHandler(prevDayNotesReqObj, 'prev');
  };

  // func to fetch today notes
  const fetchTodayNotes = () => {
    setLoading(true);
    getNotesHandler(dailyNoteRequestObj, 'today');
  };

  // func to fetch any specific date notes
  const fetchAnyDayNote = () => {
    const anyDate = moment(selectedDate).format().split('T')[0];
    const formatedAnyDate = moment(`${anyDate}T02:00:00`)
      .add(1, 'days')
      .add(utcOffset, 'minutes')
      .format()
      .split('+')[0];
    const formatedAnyDateMinus1 = moment(`${anyDate}T02:00:00`)
      .add(utcOffset, 'minutes')
      .format()
      .split('+')[0];
    setLoading(true);
    const prevDayNotesReqObj = {
      ...dailyNoteRequestObj,
      start: `${formatedAnyDateMinus1}${FIXEDTIMESTAMP}`,
      end: `${formatedAnyDate}${FIXEDTIMESTAMP}`
    };
    getNotesHandler(prevDayNotesReqObj, 'anyDay');
  };

  const cols = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        id: 'createdAt',
        cellClass: '',
        Cell: (props) => EditableCell({
          ...props,
          editable,
          noOfRows: data.length,
          setEditable
        })
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        id: 'startTime',
        cellClass: '',
        Cell: (props) => EditableCell({
          ...props,
          editable,
          noOfRows: data.length,
          setEditable
        })
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        id: 'endTime',
        cellClass: '',
        Cell: (props) => EditableCell({
          ...props,
          editable,
          noOfRows: data.length,
          setEditable
        })
      },
      {
        Header: 'User',
        accessor: 'createByFirstName',
        id: 'user',
        cellClass: '',
        Cell: (props) => EditableCell({
          ...props,
          noOfRows: data.length
        })
      },

      {
        Header: 'Notes',
        accessor: 'note',
        id: 'note',
        Cell: (props) => EditableCell({ ...props, editable, setEditable })
      },

      {
        Header: '',
        id: 'edit',
        Cell: (props) => EditableCell({
          editable,
          setEditable,
          addLineItem,
          updateLineItem,
          isTodayNotes,
          noOfRows: data.length,
          ...props
        })
      },
      {
        Header: '',
        id: 'delete',
        Cell: (props) => EditableCell({
          ...props,
          editable,
          setEditable,
          isTodayNotes,
          noOfRows: data.length,
          cancelLineItem
        })
      },
      {
        Header: '',
        id: 'new',
        cellClass: '',
        Cell: (props) => EditableCell({
          ...props,
          editable,
          isTodayNotes,
          addNewRow,
          noOfRows: data.length,
          setEditable
        })
      }
    ],
    // eslint-disable-next-line
    [editable, data.length, isTodayNotes]
  );

  return (
    <>
      <NotesHeadingBoxWrapper>
        <NotesToolBox>
          {
            isTodayNotes
              ? (
                <NotesButton onClick={() => {
                  fetchPreviouDayNotes();
                  if (editable !== '') {
                    setEditable('');
                  }
                }}
                >
                  Previous
                </NotesButton>
              ) : (
                <NotesButton onClick={fetchTodayNotes}>
                  Today
                </NotesButton>
              )
          }

          { checkIfAdminOnly()
            ? (
              <>
                <NotesSearchBarWrapper>
                  <IconImage>
                    <BiSearch
                      size="2em"
                      color="#939EC5"
                    />
                    <NotesDatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={selectedDate}
                      placeholderText="Select Date"
                      maxDate={new Date()}
                      onChange={(date) => setSelectedDate(date)}
                      showDisabledMonthNavigation
                      className="date-picker"
                    />
                  </IconImage>
                </NotesSearchBarWrapper>
                <NotesSubmitButton
                  disabled={!selectedDate}
                  onClick={fetchAnyDayNote}
                >
                  Submit
                </NotesSubmitButton>
              </>
            ) : (
              null
            )}
        </NotesToolBox>
      </NotesHeadingBoxWrapper>

      {
        !isZeroNotes
          ? (<Table columns={cols} data={data} />)
          : (<NoData />)
      }
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <ToastWrapper>
        <Toast>
          <Toast
            onClose={() => setAlertShow(false)}
            show={alertShow}
            delay={3000}
            autohide
          >
            <Toast.Body>
              <h6
                data-testid="authentication-msg"
                className="text-danger"
              >
                {alertMessage}
              </h6>
            </Toast.Body>
          </Toast>
        </Toast>
      </ToastWrapper>
    </>
  );
};

export default NotesTable;
