/** @format */
/*eslint-disable */

import axios from './axios';

// Auth related apis start here

export const login = async (payload) => {
  try {
    return await axios.post('/login', payload, {
      headers: { Authorization: null }
    });
  } catch (error) {
    return error.response;
  }
};

export const register = async (payload) => {
  try {
    return await axios.post('/register', payload);
  }  catch (error) {
    return error.response;
  }
};

export const sendForgotPasswordLink = async (payload) => {
  try {
    return await axios.post('/user/forgot-password', payload, {
      headers: { Authorization: null }
    });
  } catch (error) {
    return error.response;
  }
};

export const resetPassword = async (payload) => {
  try {
    return await axios.post('/user/reset-password', payload, {
      headers: { Authorization: null }
    });
  } catch (error) {
    return error.response;
  }
};

export const changePassword = async (payload) => {
  try {
    return await axios.post('/change-password', payload);
  } catch (error) {
    return error.response;
  }
};

// Auth apis end here

// Client contacts apis start here

export const getClientContacts = async (clientId) => {
  try {
    return await axios.get(`/client/list/contact/${clientId}`);
  } catch (error) {
    return error.response;
  }
};

export const createClientContact = async (payload) => {
  try {
    return await axios.post('/client/create-contact', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateClientContact = async (contactId, payload) => {
  try {
    return await axios.put(`/client/update/contact/${contactId}`, payload);
  } catch (error) {
    return error.response;
  }
};

// Client Contacts apis end here

// Important Contacts apis start here

export const getImportantContacts = async (payload) => {
  try {
    return await axios.post('/house/list/contact', payload);
  } catch (error) {
    return error.response;
  }
};

export const createImportantContact = async (payload) => {
  try {
    return await axios.post('/house/create-contact', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateImportantContact = async (contactId, payload) => {
  try {
    return await axios.put(`/house/update/contact/${contactId}`, payload);
  } catch (error) {
    return error.response;
  }
};

// Important Contacts apis end here

// Notes related apis start here

export const addNote = async (payload) => {
  try {
    return await axios.post('/dailynote/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const getNotes = async (payload) => {
  try {
    return await axios.post('/dailynote/search', payload);
  } catch (error) {
    return error.response;
  }
};

export const deleteNote = async (payload) => {
  try {
    return await axios.delete(`/dailynote/delete/${payload}`);
  } catch (error) {
    return error.response;
  }
};

export const updateNote = async (payload) => {
  try {
    return await axios.put('/dailynote/update', payload);
  } catch (error) {
    return error.response;
  }
};

// Notes related apis end here

// User and roles apis start here

export const getUsersList = async () => {
  try {
    return await axios.get('/user/list');
  } catch (error) {
    return error.response;
  }
};

export const getUserListByHouse = async (houseCode) => {
  try {
    return await axios.post('user/list-by-house', { houseCode });
  } catch (error) {
    return error.response;
  }
};

export const getUserListPerPage = async (payload) => {
  try {
    return await axios.post('user/list-page', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateUser = async (id, payload) => {
  try {
    return await axios.put(`/user/update/${id}`, payload);
  } catch (error) {
    return error.response;
  }
};

export const getRolesList = async () => {
  try {
    return await axios.get('/roles/list');
  } catch (error) {
    return error.response;
  }
};
export const addRole = async (payload) => {
  try {
    return await axios.post('/user/add-role', payload);
  } catch (error) {
    return error.response;
  }
};
export const removeRole = async (payload) => {
  try {
    return await axios.delete('/user/remove-role', { data: payload });
  } catch (error) {
    return error.response;
  }
};

// Users and roles apis end here

// House related api start here

export const getHouseList = async () => {
  try {
    return await axios.get('/house/list');
  } catch (error) {
    return error.response;
  }
};
export const createHouse = async (payload) => {
  try {
    return await axios.post('/house/create', payload);
  } catch (error) {
    return error.response;
  }
};
export const addHouse = async (payload) => {
  try {
    return await axios.post('/house/add-user', payload);
  } catch (error) {
    return error.response;
  }
};
export const updateHouse = async (houseId, payload) => {
  try {
    return await axios.put(`/house/update/${houseId}`, payload);
  } catch (error) {
    return error.response;
  }
};

// house related apis end here

export const removeHouse = async (payload) => {
  try {
    return await axios.delete('/user/remove-house', { data: payload });
  } catch (error) {
    return error.response;
  }
};
export const addClient = async (payload) => {
  try {
    return await axios.post('/client/create', payload);
  } catch (error) {
    return error.response;
  }
};
export const updateClient = async (payload) => {
  try {
    return await axios.put(`/client/update/${payload.id}`, payload);
  } catch (error) {
    return error.response;
  }
};
export const getClientList = async () => {
  try {
    return await axios.get('/client/list');
  } catch (error) {
    return error.response;
  }
};
export const getClientListByHouse = async (houseCode) => {
  try {
    return await axios.post('/house/fetch-clients', {houseCode});
  } catch (error) {
    return error.response;
  }
};
export const addClientToHouse = async (payload) => {
  try {
    return await axios.post('/house/add-client', payload);
  } catch (error) {
    return error.response;
  }
};

// Incidents apis start here

export const getReview = async (id) => {
  try {
    return await axios.get(`/incident-review/get/${id}`);
  } catch (error) {
    return error.response;
  }
};
export const getIncidentList = async (payload) => {
  try {
    return await axios.post('/incident/list', payload);
  } catch (error) {
    return error.response;
  }
};
export const addReview = async (payload) => {
  try {
    return await axios.post('/incident-review/create', payload);
  } catch (error) {
    return error.response;
  }
};
export const getIncident = async (id) => {
  try {
    return await axios.get(`/incident/get/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const escalateTo = async (incidentId, userId) => {
  try {
    return await axios.patch(`/incident/escalate/${incidentId}/${userId}`);
  } catch (error) {
    return error.response;
  }
};

export const closeIncident = async (incidentId, closeBy) => {
  try {
    return await axios.patch(`/incident/close/${incidentId}/${closeBy}`);
  } catch (error) {
    return error.response;
  }
};

export const editIncident = async (incidentId, editedIncidentDetails) => {
  try {
    return await axios.put(
      `/incident/update/${incidentId}`,
      editedIncidentDetails
    );
  } catch (error) {
    return error.response;
  }
};

export const editReview = async (reviewId, editedReview) => {
  try {
    return await axios.put(`incident-review/update/${reviewId}`, editedReview);
  } catch (error) {
    return error.response;
  }
};

export const createIncident = async (payload) => {
  try {
    return await axios.post("/incident/create", payload);
  } catch (error) {
    return error.response;
  }
};

// Incidents apis end here

// Lookup apis start here

export const getCategoryList = async () => {
  try {
    return await axios.get('lookup/categories');
  } catch (error) {
    return error.response;
  }
};

export const getTypeList = async () => {
  try {
    return await axios.get('lookup/types');
  } catch (error) {
    return error.response;
  }
};

export const getClassificationList = async () => {
  try {
    return await axios.get('/lookup/classifications');
  } catch (error) {
    return error.response;
  }
};

export const listForms = async () => {
  try {
    return await axios.get('/lookup/reports');
  } catch (error) {
    return error.response;
  }
};

export const listNightlyTasks = async () => {
  try {
    return await axios.get('/lookup/nightly-tasks');
  } catch (error) {
    return error.response;
  }
};

export const listMiscellaneousNoteCategories = async () => {
  try {
    return await axios.get('/lookup/list-note-categories');
  } catch (error) {
    return error.response;
  }
};

// Lookup api end here

// Notice related apis start here

export const getNoticeList = async (payload) => {
  try {
    return await axios.post('/notice/list', payload);
  } catch (error) {
    return error.response;
  }
};

export const createNotice = async (notice) => {
  try {
    return await axios.post('/notice/create', notice);
  } catch (error) {
    return error.response;
  }
};

export const updateNotice = async (id, updatedNotice) => {
  try {
    return await axios.put(`/notice/update/${id}`, updatedNotice);
  } catch (error) {
    return error.response;
  }
};

// Notice related apis end here

// Upload apis start here

export const uploadPlan = async (payload) => {
  try {
    return await axios.post('/upload', payload);
  } catch (error) {
    return error.response;
  }
};

export const uploadHouseImage = async (payload) => {
  try {
    return await axios.post('/upload-image', payload);
  } catch (error) {
    return error.response;
  }
};

// Upload apis end here

// Report apis start here

export const getFormsForClient = async (clientId) => {
  try {
    return await axios.get(`/clientReport/get/${clientId}`);
  } catch (error) {
    return error.response;
  }
};

export const toggleFormsForClient = async (payload) => {
  try {
    return await axios.post('/clientReport/toggle-report', payload);
  } catch (error) {
    return error.response;
  }
};

export const getNightReport = async (clientId) => {
  try {
    return await axios.get(`/night-report/get/${clientId}`);
  } catch (error) {
    return error.response;
  }
};

export const getNightReportParticular = async (payload) => {
  try {
    return await axios.post(`/night-report/get-particular`, payload);
  } catch (error) {
    return error.response;
  }
};

export const createNightReport = async (payload) => {
  try {
    return await axios.post('/night-report/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateNightReport = async (payload) => {
  try {
    return await axios.put(`/night-report/update/${payload.reportId}`, payload);
  } catch (error) {
    return error.response;
  }
};

export const getMeal = async (clientId, mealType) => {
  try {
    return await axios.get(`/food-diary/get/${clientId}/${mealType}`);
  } catch (error) {
    return error.response;
  }
};

export const getMealParticular = async (payload) => {
  try {
    return await axios.post('/food-diary/get-particular', payload);
  } catch (error) {
    return error.response;
  }
};

export const createMeal = async (payload) => {
  try {
    return await axios.post(`/food-diary/create`, payload);
  } catch (error) {
    return error.response;
  }
};

export const updateMeal = async (diaryId, payload) => {
  try {
    return await axios.put(`/food-diary/update/${diaryId}`, payload);
  } catch (error) {
    return error.response;
  }
};

export const getSleepTrackerNote = async (payload) => {
  try {
    return await axios.get(
      `/sleep-notes/get/${payload.clientId}/${payload.date}`
    );
  } catch (error) {
    return error.response;
  }
};

export const createSleepReport = async (payload) => {
  try {
    return await axios.post('/sleep-notes/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateSleepReport = async (payload) => {
  try {
    return await axios.put(`/sleep-notes/update/${payload.id}`, payload);
  } catch (error) {
    return error.response;
  }
};

export const listBowelMovementRecords = async (payload) => {
  try {
    return await axios.post('/bowel-note/list', payload);
  } catch (error) {
    return error.response;
  }
};

export const createBowelMovementRecord = async (payload) => {
  try {
    return await axios.post('/bowel-note/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateBowelMovementRecord = async (recordId, payload) => {
  try {
    return await axios.put(`/bowel-note/update/${recordId}`, payload);
  } catch (error) {
    return error.response;
  }
};

// Report apis end here


// Support documents/folder apis start here
export const getFolderList = async (clientId) => {
  try {
    return await axios.get(`/client/list/folders/${clientId}`);
  } catch (error) {
    return error.response;
  }
};

export const createFolder = async (payload) => {
  try {
    return await axios.post('/client/create-folder', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateFolder = async (payload) => {
  try {
    return await axios.put('/client/update-folder', payload);
  } catch (error) {
    return error.response;
  }
};

export const uploadDocument = async (payload) => {
  try {
    return await axios.post('/upload-document', payload);
  } catch (error) {
    return error.response;
  }
};

export const getDocumentList = async (clientId, folderId) => {
  try {
    return await axios.get(`/list-documents/${clientId}/${folderId}`);
  } catch (error) {
    return error.response;
  }
};

export const deleteDocument = async (documentId) => {
  try {
    return await axios.put(`/delete-document/${documentId}`);
  } catch (error) {
    return error.response;
  }
};

// Support documents/folder apis end here

export const getEmergencyPlanUrl = async (houseCode) => {
  try {
    return await axios.post('url/get', {houseCode});
  } catch (error) {
    return error.response;
  }
};

export const getHouseImageUrl = async (houseCode) => {
  try {
    return await axios.post('/get-image', {houseCode});
  } catch (error) {
    return error.response;
  }
};

// case-notes api's start here

export const createCaseNote = async (payload) => {
  try {
    return await axios.post('/case-note/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const getListCaseNotes = async (payload) => {
  try {
    return await axios.post('/case-note/list', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateCaseNote = async (id, payload) => {
  try {
    return await axios.put(`/case-note/update/${id}`, payload);
  } catch (error) {
    return error.response;
  }
};

// handover apis start here

export const getHandoverList = async (payload) => {
  try {
    return await axios.post('/handover/list', payload);
  } catch (error) {
    return error.response;
  }
};

export const createHandover = async (payload) => {
  try {
    return await axios.post('/handover/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateHandover = async (id, payload) => {
  try {
    return await axios.put(`/handover/update/${id}`, payload);
  } catch (error) {
    return error.response;
  }
};

// handover apis end here

// miscellaneous notes apis start here

export const getMiscellaneousNotes = async (payload) => {
  try {
    return await axios.post('/miscellaneous-note/list', payload);
  } catch (error) {
    return error.response;
  }
};

export const createMiscellaneouNote = async (payload) => {
  try {
    return await axios.post('/miscellaneous-note/create', payload);
  } catch (error) {
    return error.response;
  }
};

export const updateMiscellaneousNote = async (id, payload) => {
  try {
    return await axios.put(`/miscellaneous-note/update/${id}`, payload);
  } catch (error) {
    return error.response;
  }
};

// miscellaneous notes apis end here
