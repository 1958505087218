/** @format */
import styled from 'styled-components';
import {
  Tabs,
  Tab,
  TabList,
  TabPanel
} from 'react-tabs';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: #E86B55;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const Wrapper = styled.div`
    margin: 1rem 0;
    padding-bottom: 1rem;
    border-radius: 14px;
    background-color: #fff;
`;

export const TabComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTabs = styled(Tabs)`
  font-family: Roboto;
  font-size: ${(props) => (props.fs ? props.fs : '12px')};
  width: 100%;
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin-bottom:-4px;
`;
StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  margin-left: -4px;
  margin-right: 28px;
  padding: 10px 20px;
  user-select: none;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: rgba(232, 107, 85, 0.3);
;
  &.is-selected {
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
    color: ${({ theme }) => theme.colors.od_secondary};
  }
`;
StyledTab.tabsRole = 'Tab';

export const StyledTabPanel = styled(TabPanel)`
  display: none;
  min-height: 58vh;
  &.is-selected {
    display: block;
  }
`;
StyledTabPanel.tabsRole = 'TabPanel';
