import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import { isEqual } from 'lodash';
import { Modal, Spinner } from 'react-bootstrap';
import {
  HandoverForm, HandoverWrapper,
  FormTitleHeader, ButtonWrapper
} from './index.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import SelectContainer from '../../components/SelectContainer';
import { shiftList } from '../../lookups/lookups';
import { createHandover, updateHandover } from '../../services/result.services';
import { getUserDetails, checkForSpecificShift } from '../../helpers/utils';

const Handover = ({
  handoverDetails, handleShowHandoverDetails, isDisabled,
  staffList, handleRefetchRequirement, noPlaceHolder
}) => {
  const [handOver, setHandOver] = useState(handoverDetails);
  const [validationMsg, setValidationMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHandOver((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelect = (selected) => {
    const { name, value, id } = selected;
    const [firstName, lastName] = value.split(' ');
    setHandOver((prevState) => ({
      ...prevState,
      [name]: { id, firstName, lastName }
    }));
  };

  const handleSelectShift = (selected) => {
    const { name, value } = selected;
    setHandOver((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectDate = (selectedDate) => {
    setHandOver((prevState) => ({
      ...prevState,
      handoverDate: selectedDate
    }));
  };

  const handleSelectDateTime = (selectedDateTime) => {
    setHandOver((prevState) => ({
      ...prevState,
      handoverTime: selectedDateTime
    }));
  };

  const checkValidation = (handoverObj) => {
    if (!handoverObj.handoverShift) {
      setValidationMsg('Handover shift is not selected');
      return false;
    }
    if (!handoverObj.handoverTo?.id) {
      setValidationMsg('Handover To is not selected');
      return false;
    }
    setValidationMsg('');
    return true;
  };

  const handleCreateHandover = async () => {
    if (!checkValidation(handOver)) return;
    setLoading(true);
    const resp = await createHandover({
      ...handOver,
      handoverDate: moment(handOver.handoverDate).format('YYYY-MM-DD'),
      handoverTime: moment(handOver.handoverTime).format('YYYY-MM-DD HH:mm:ss'),
      handoverToId: handOver.handoverTo?.id,
      houseCode: getUserDetails().houseCode
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Handover created successfully!',
        icon: 'success',
        timer: 3000
      });
      handleShowHandoverDetails();
      handleRefetchRequirement();
    } else {
      setLoading(false);
      swal({
        title: 'Handover creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleUpdateHandover = async () => {
    setLoading(true);
    const resp = await updateHandover(handOver.id, {
      ...handOver,
      handoverTime: moment(handOver.handoverTime).format('YYYY-MM-DD HH:mm:ss'),
      handoverDate: moment(handOver.handoverDate).format('YYYY-MM-DD'),
      handoverToId: handOver.handoverTo?.id,
      houseCode: getUserDetails().houseCode
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Handover updated successfully!',
        icon: 'success',
        timer: 3000
      });
      handleShowHandoverDetails();
      handleRefetchRequirement();
    } else {
      setLoading(false);
      swal({
        title: 'Handover updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <HandoverWrapper>
        <FormTitleHeader>
          <h2>HANDOVER SUMMARY</h2>
          <button type="button" onClick={handleShowHandoverDetails}>Back</button>
        </FormTitleHeader>
        <HandoverForm>
          <div className={`flex ${handOver.handoverBy.firstName ? 'w-50' : 'w-100'}`}>
            <p className="label">House Code/Name </p>
            <p className="house_num">{getUserDetails().houseCode || handOver.houseCode}</p>
          </div>
          {
            handOver.handoverBy.firstName && (
              <div className="w-50 flex">
                <p className="label">Handover By </p>
                <p className="house_num">{ handOver.handoverBy.firstName }</p>
              </div>
            )
          }
          <div className="flex w-25">
            <p className="label">Handover For</p>
            <DatePicker
              timeFormat="HH:mm"
              className="input w-100"
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={new Date(moment().subtract(1, 'days'))}
              placeholderText="Select date"
              selected={handOver.handoverDate}
              onChange={(selectedDate) => handleSelectDate(selectedDate)}
              disabled={isDisabled || handOver.id}
            />
          </div>
          <div className="flex w-25">
            <p className="label">Handover Date and Time</p>
            <DatePicker
              timeFormat="HH:mm"
              showTimeSelect
              maxDate={new Date()}
              minDate={new Date()}
              className="input w-100"
              dateFormat="dd/MM/yyyy, HH:mm:ss"
              placeholderText="Select date"
              selected={handOver.handoverTime}
              onChange={(selectedDateTime) => handleSelectDateTime(selectedDateTime)}
              disabled={isDisabled}
            />
          </div>
          <div className="flex w-25">
            <p className="label">Handover Shift</p>
            <SelectContainer
              options={shiftList}
              width="100%"
              defaultValue={{
                label: checkForSpecificShift(handOver.handoverShift),
                value: handOver.handoverShift
              }}
              onChange={handleSelectShift}
              disabled={isDisabled}
            />
          </div>
          <div className="flex w-25">
            <p className="label">Handover To</p>
            <SelectContainer
              options={staffList}
              width="100%"
              placeholderText="Handover to"
              defaultValue={{
                label: `${handOver.handoverTo?.firstName} ${handOver.handoverTo?.lastName}`,
                value: `${(handOver.handoverTo.firstName && handOver.handoverTo.lastName)
                  ? `${handOver.handoverTo?.firstName} ${handOver.handoverTo?.lastName}` : ''}`,
                id: handOver.handoverTo?.id
              }}
              onChange={handleSelect}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Behaviour Summary</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'behaviour summary'}
              rows="3"
              defaultValue={handOver.behaviourSummary}
              name="behaviourSummary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Sleep Summary</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'sleep summary'}
              rows="3"
              defaultValue={handOver.sleepSummary}
              name="sleepSummary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Food Summary</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'food summary'}
              rows="3"
              defaultValue={handOver.foodSummary}
              name="foodSummary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Toileting Summary</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'toileting summary'}
              rows="3"
              defaultValue={handOver.toiletingSummary}
              name="toiletingSummary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Activities Summary</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'activities summary'}
              rows="3"
              defaultValue={handOver.activitiesSummary}
              name="activitiesSummary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Communications</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'communications'}
              rows="3"
              defaultValue={handOver.communications}
              name="communications"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Top Priorities</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'top priorities'}
              rows="3"
              defaultValue={handOver.topPriorities}
              name="topPriorities"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Comments</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'comments'}
              rows="3"
              defaultValue={handOver.comments}
              name="comments"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">People Attended</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'people attended'}
              rows="3"
              defaultValue={handOver.peopleAttended}
              name="peopleAttended"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Places Visited</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'places visited'}
              rows="3"
              defaultValue={handOver.placesVisited}
              name="placesVisited"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <p className="label">Things For Another Day</p>
            <textarea
              className="input-like"
              placeholder={noPlaceHolder ? '' : 'things for another day'}
              rows="3"
              defaultValue={handOver.thingsLater}
              name="thingsLater"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
          <div className="w-50 flex">
            <h4 className="mx-3 pt-5 text-danger">
              { validationMsg && `${validationMsg}`}
            </h4>
          </div>
        </HandoverForm>
        {
          !isDisabled && !handOver.id && (
            <ButtonWrapper>
              <button
                type="button"
                className="save-btn"
                onClick={handleCreateHandover}
              >
                Save
              </button>
            </ButtonWrapper>
          )
        }
        {
          !isDisabled && handOver.id && (
            <ButtonWrapper>
              <button
                type="button"
                className="save-btn"
                onClick={handleUpdateHandover}
                disabled={isEqual(handOver, handoverDetails)}
              >
                Save
              </button>
            </ButtonWrapper>
          )
        }
      </HandoverWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

Handover.defaultProps = {
  isDisabled: false,
  noPlaceHolder: false,
  handleRefetchRequirement: () => {}
};

Handover.propTypes = {
  handleShowHandoverDetails: PropTypes.func.isRequired,
  handleRefetchRequirement: PropTypes.func,
  handoverDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  noPlaceHolder: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

export default Handover;
