/** @format */

import styled from 'styled-components';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const NoticeWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
    width: 12px !important;
    height: auto;
  }
`;

export const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
`;

export const NoticeContainer = styled.div`
  margin: 2.5rem 2.5rem 1rem;
  min-height: calc(58vh - 120px);
  max-height: calc(65vh - 120px);
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const NoticeBox = styled.div`
  border: 1px solid #dddddd;
  border-radius: 20px;
  width: 250px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 1.5rem 1rem;

  .notice {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.od_extra_color};
    max-height: 250px;
    overflow-y: auto;
    padding: 1rem;
  }

  .date {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
    width: 180px;
    font-size: 14px;
    padding: 0.25rem 0;
    border-radius: 5px;
    text-align: center;
  }
`;

export const NoDataBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
