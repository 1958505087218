import styled from 'styled-components';

export const CaseNoteRecordWrapper = styled.div`
    border: 1px solid #E6E9F4;
    font-size: 16px;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 12px 5px;
    color: ${({ theme }) => theme.colors.od_table_color} !important;
    width: calc(100% - 10px);
    padding:6px;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .contact-header {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 2px solid #D1DEDE;
        margin-bottom:8px;
    
        p {
          font-size: 16px;
          font-weight: 700;
          margin: auto 0;
        }
    
        button {
          border-radius: 5px;
          border: none;
          background-color: ${({ theme }) => theme.colors.od_bg_secondary};
          padding: 5px 9px;
          margin-bottom:8px;
          img {
            margin: 0;
          }
        }
        .delete-btn{
          margin-left: 1rem;
          padding: 0;
          color: ${({ theme }) => theme.colors.od_secondary};
          img{
            width: 28px !important;
          }
        }
      }
`;

export const FirstRow = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:12px;
`;

export const TimingsBox = styled.div`
    display:flex;
    margin: 4px 10px;
    .time {
        font-weight:600;
        width:60px;
        margin:0px 12px;
    }
    .start {
      font-weight:600;
      width:80px;
      margin:0px 12px;
  }
    span {
        font-weight:100 !important;
        display:flex;
        align-items:center;
    }
    pre{
      font-family: inherit;
      color: ${({ theme }) => theme.colors.od_table_color};
      white-space: pre-wrap;
    }
`;

export const SecondRow = styled.div`
    
`;
