/** @format */

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import addressIcon from '../assets/logo/location.svg';
import contactIcon from '../assets/logo/contact.svg';
import ImageContainer from './ImageContainer';

const settings = {
  className: 'slider variable-width',
  slidesToShow: 1,
  infinite: false,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false
};

const HouseSlider = ({ list, changeHouse, sliderRef }) => (
  <Slider {...settings} ref={sliderRef}>
    {list.map((ele) => (
      <div key={ele.id} className="box" onClick={() => changeHouse(ele.houseCode)}>
        <ImageContainer houseCode={ele.houseCode} />
        <div className="lower-part">
          <div className="house-phone-wrap">
            <p classsName="house-code">{ele.houseCode}</p>
            <p className="icon-with-text">
              <img
                className="contact-icon"
                src={contactIcon}
                alt="contact-icon"
              />
              <span>{ele.phone}</span>
            </p>
          </div>
          <p className="icon-with-text">
            <img
              className="address-icon"
              src={addressIcon}
              alt="house-address-icon"
            />
            <span>{`${ele.addrLine1}${ele.addrLine2 && `, ${ele.addrLine2}`}, ${ele.city}, ${ele.state}, ${ele.postCode}`}</span>
          </p>
        </div>
      </div>
    ))}
  </Slider>
);

HouseSlider.defaultProps = {
  list: []
};

HouseSlider.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  changeHouse: PropTypes.func.isRequired,
  sliderRef: PropTypes.objectOf(PropTypes.any).isRequired
};

export default HouseSlider;
