import styled from 'styled-components';

export const BowelMovementHeader = styled.div`
  height: 73px;
  background: ${({ theme }) => theme.colors.od_primary_transparent};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 0.25rem;
`;

export const Title = styled.h4`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.od_primary};
  font-size: 18px;
  margin: 0 35px;
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  min-width: ${(props) => (props.width ? props.width : '77px')};
  font-size: 15px;
  border: none;
  margin: ${(props) => (props.margintb ? props.margintb : '10px')} 0;
  height: ${(props) => (props.height ? props.height : '41px')};
  font-size: 13px;
  img {
    height: 8px;
    width: 11px;
    margin-right: 5px;
    margin-left: -5px;
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

export const BowelMovementChart = styled.div`
  margin: 0 2rem;
  font-weight: 600;
  font-size: 16px;
  .disabled {
    cursor: default !important;
  }
  .container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .container > span:first-child {
    position: relative;
    top: 45px;
    left: 25px;
    width: 135px;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 35%;
    left: 35%;
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    border-radius: 50%;
  }

  /* On mouse-over, add a primary background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .disabled:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }

  /* When the checkbox is checked, add a primary background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const HeaderRow = styled.div`
  height: 95px;
  display: flex;
`;

export const BowelRecordRow = styled(HeaderRow)`
  height: 80px;
  margin-top: 1rem;
`;
export const ColumnOne = styled.div`
  width: 350px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .client_details, .first_three_header{
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .dob_track_from p{
    margin-bottom: 0.25rem;
    border-radius: 5px;
    padding: 4px 10px;
  }
  .dob{
    background-color: ${({ theme }) => theme.colors.od_primary_transparent};
    color: ${({ theme }) => theme.colors.od_extra_color}
  }
  .track_from{
    background-color: ${({ theme }) => theme.colors.od_extra_color};
    color: ${({ theme }) => theme.colors.od_neutral}
  }
  .client_details > p:first-child{
    min-width: 44%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_primary_transparent};
  }
  .first_three_header > p {
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.od_primary};
    padding: 4px 10px;
  }
  .first_three_field{
    height: 70px;
  }
  .first_three_field > p {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .s_num{
    width: 45px;
    padding: 4px 0 !important;
    margin: auto;
    text-align: center;
  }
  .date_time{
    width: 180px;
    margin: 0 auto;
  }
  .size{
    width: 120px;
    margin: 0 auto;
  }
  .s_num_data{
    width: 45px;
    margin-bottom: 0;
  }
  .date_time_data{
    width: 180px;
    margin-bottom: 0;
    input{
      padding: 0.6rem 0.4rem;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
    }
    input:focus{
      outline: none;
    }
  }
  .size_data{
    width: 120px;
    margin-bottom: 0;
    .select-container{
      width: 100%;
    }
  }
`;

export const ColumnTwo = styled.div`
  width: 630px;
  display: flex;
  align-items: center;
`;

export const ColumnThree = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 5px;
  border: 1px solid ${({ theme }) => theme.colors.od_primary};
  .staff_initials{
    margin-bottom: 0;
    font-size: 14px;
  }
`;

export const TypeBox = styled.div`
  display: flex;
  margin: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: ${(props) => (props.height ? props.height : '85px')};
  width: 80px;
  border: 1px solid ${({ theme }) => theme.colors.od_primary}};
  .name_box{
    width: 100%;
    padding: 0 0.35rem;
    margin-top: auto;
    background-color: ${({ theme }) => theme.colors.od_primary_transparent};
  }
  .type_name{
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    margin: auto;
    padding: 5px 0;
  }
  .bowel_img{
    padding: 0 0.35rem;
    width: 80px !important;
    max-height: 80px;
  }
`;

export const AddRecord = styled.button`
  position: absolute;
  top: 235px;
  right: 25px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

export const FormWrapper = styled(BowelMovementChart)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  font-weight: 500;

  .select-type{
    width: 100%;
    font-size: ${({ theme }) => theme.fonts.font_size.small_font};
    margin: 0;
    padding: 0 0.5rem;
  }

  div.input-with-full-width {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 0.5rem;
      margin-bottom: 3px;
    }
    input,
    select,
    textarea {
      width: 100%;
      padding: 0.5rem 0.3rem;
      border-radius: 5px;
      border: none;
    }

    textarea:focus {
      outline:none;
    }
    .type_img{
      border-radius: 10px;
    }
  }

  div.input-with-half-width, div.input-with-one-third-width {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 0.5rem;
      margin-bottom: 3px;
    }
    input,
    select,
    textarea {
      width: 100%;
      padding: 0.4rem;
      border-radius: 5px;
      border: none;
    }

    textarea:focus {
      outline:none;
    }
  }

  div.input-with-one-third-width{
      width: 32%;
  }

  .container > span:first-child {
    position: relative;
    top: 10px;
    left: 10px;
  }
  .container{
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 23.5%;
    height: 50px;
  }
  .checkmark{
    top: 30%;
    left: 78%;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  select:invalid {
    color: gray;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  input[disabled]{
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const NameBox = styled.span`
  display: flex;
  border: 0.2px solid rgba(134, 81, 137, 0.24);
  border-radius: 24px;
  width: 105px;
  padding: 3px;
  font-size: 12px;
  align-items: center;

  .icon-name-wrapper {
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.od_light_extra};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;

    .icon {
      width: 12px;
      height: 12px;
      margin: auto;
    }
  }
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.od_text_color};
  }
`;
