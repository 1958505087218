/** @format */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import Incident from '../IncidentTabPanel/Incident';
import ReviewTabFirst from '../IncidentReviewTabPanels/IncidentReviewFirst';
import ReviewTabSecond from '../IncidentReviewTabPanels/IncidentReviewSecond';
import ReviewTabThird from '../IncidentReviewTabPanels/IncidentReviewThird';
import ClosedIncidentDetails from '../ClosedIncidentDetails';
import { getReview } from '../../../services/result.services';
import { checkIfFirstSecRevTabEmpty } from '../../../helpers/utils';
import { reviewObj } from '../../../lookups/lookups';
import {
  BreadCrumb, IncidentManagementHeader, IncidentsWrapper, StyledTab,
  StyledTabList, StyledTabPanel, StyledTabs
} from './index.style';
import homeIcon from '../../../assets/logo/home.svg';
import caretRightIcon from '../../../assets/logo/caret_right.svg';

const IncidentTabs = ({ isOpen }) => {
  const params = useParams();
  const { id: incidentId, incidentType } = params;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [reviewData, setReviewData] = useState(reviewObj);
  const [editReviewData, setEditReviewData] = useState({});
  const [reviewValidationMsg, setReviewValidationMsg] = useState('');
  const [incident, setIncident] = useState(null);
  const [editReviewGeneral, setEditReviewGeneral] = useState({});

  const checkReviewValidation = (review) => {
    if (!review.riskRating) {
      setReviewValidationMsg('Risk rating is not selected');
      return false;
    }
    if (!review.allocatedTo) {
      setReviewValidationMsg('Allocated to is not selected');
      return false;
    }
    if (!review.correctiveAction) {
      setReviewValidationMsg('Corrective Action is not entered');
      return false;
    }
    if (!review.likelihood) {
      setReviewValidationMsg('Likelihood is not selected');
      return false;
    }
    if (!review.actionsToImplement) {
      setReviewValidationMsg('Action description is not entered');
      return false;
    }
    if (!review.consequences) {
      setReviewValidationMsg('Consequences is not selected');
      return false;
    }
    if (!review.dueDate) {
      setReviewValidationMsg('Due date is not selected');
      return false;
    }
    if (!review.riskRating) {
      setReviewValidationMsg('Risk rating is not selected');
      return false;
    }
    if (checkIfFirstSecRevTabEmpty(review)) {
      setReviewValidationMsg('Select atleast one factor from Review-1 or Review-2 Tabs');
      return false;
    }
    setReviewValidationMsg('');
    return true;
  };

  const getReviewHandler = async () => {
    const resp = await getReview(incidentId);
    if (!resp) return;
    if (resp.status === 200 && resp.data.length > 0) {
      const review = resp.data[0];
      const date = review.dueDate.split('-');
      const modifiedDate = new Date(
        date[0],
        date[1] - 1,
        date[2]
      );
      setEditReviewData({ ...review, dueDate: modifiedDate });
      setEditReviewGeneral({ ...review, dueDate: modifiedDate });
    }
  };
  const handleChange = (e) => {
    const {
      name, type, value, checked
    } = e.target;
    if (type === 'text' || type === 'textarea') {
      setReviewData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
    if (type === 'checkbox') {
      setReviewData((prevState) => ({
        ...prevState,
        [name]: checked
      }));
    }
  };

  const handleChangeEditReview = (e) => {
    const {
      name, type, value, checked
    } = e.target;
    if (type === 'text' || type === 'textarea') {
      setEditReviewData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
    if (type === 'checkbox') {
      setEditReviewData((prevState) => ({
        ...prevState,
        [name]: checked
      }));
    }
  };

  const handleSelectDate = (date) => {
    setReviewData((prevState) => ({
      ...prevState,
      dueDate: date
    }));
  };

  const handleSelectDateEdit = (date) => {
    setEditReviewData((prevState) => ({
      ...prevState,
      dueDate: date
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;
    setReviewData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectChangeEdit = (e) => {
    const { name, value } = e;
    setEditReviewData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    getReviewHandler();
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Incidents </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              {incidentType === 'client' ? 'Client' : 'Staff'}
            </span>
          </li>
        </ul>
      </BreadCrumb>
      <IncidentsWrapper>
        <IncidentManagementHeader>
          <h2>INCIDENT MANAGEMENT</h2>
          <Link to="/admin/incidents">Back</Link>
        </IncidentManagementHeader>
        <>
          { !isOpen
            ? (
              <ClosedIncidentDetails
                incidentId={incidentId}
                incidentType={incidentType}
              />
            ) : (
              <StyledTabs
                selectedTabClassName="is-selected"
                selectedTabPanelClassName="is-selected"
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
              >
                <StyledTabList>
                  <StyledTab>Incident</StyledTab>
                  <span className="green-line"> </span>
                  <StyledTab>
                    Review
                    <span className="review-num">01</span>
                  </StyledTab>
                  <span className="green-line"> </span>
                  <StyledTab>
                    Review
                    <span className="review-num">02</span>
                  </StyledTab>
                  <span className="green-line"> </span>
                  <StyledTab>
                    Review
                    <span className="review-num">03</span>
                  </StyledTab>
                </StyledTabList>
                <StyledTabPanel>
                  <Incident
                    incidentId={incidentId}
                    incidentType={incidentType}
                    setIncident={setIncident}
                    handleSelectedIndex={handleSelectedIndex}
                    editReviewData={editReviewData}
                  />
                </StyledTabPanel>
                <StyledTabPanel>
                  <ReviewTabFirst
                    incidentId={incidentId}
                    handleSelectedIndex={handleSelectedIndex}
                    editReviewData={editReviewData}
                    handleChange={handleChange}
                    reviewData={reviewData}
                    handleChangeEditReview={handleChangeEditReview}
                  />
                </StyledTabPanel>
                <StyledTabPanel>
                  <ReviewTabSecond
                    handleSelectedIndex={handleSelectedIndex}
                    editReviewData={editReviewData}
                    handleChange={handleChange}
                    reviewData={reviewData}
                    handleChangeEditReview={handleChangeEditReview}
                  />
                </StyledTabPanel>
                <StyledTabPanel>
                  <ReviewTabThird
                    incidentId={incidentId}
                    editReviewData={editReviewData}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    reviewData={reviewData}
                    handleSelectDate={handleSelectDate}
                    handleSelectDateEdit={handleSelectDateEdit}
                    reviewValidationMsg={reviewValidationMsg}
                    checkReviewValidation={checkReviewValidation}
                    handleChangeEditReview={handleChangeEditReview}
                    handleSelectChangeEdit={handleSelectChangeEdit}
                    incident={incident}
                    editReviewGeneral={editReviewGeneral}
                  />
                </StyledTabPanel>
              </StyledTabs>
            )}
        </>
      </IncidentsWrapper>
    </>
  );
};

IncidentTabs.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default IncidentTabs;
